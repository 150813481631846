export const login = {
  form: {
    account: 'Account',
    password: 'Password',
    submit: 'Login',
    accountRequired: 'Invalid login account',
    passwordRequired: 'Invalid login password',
  },
  sessionOutTitle: 'Login Status Exception',
  sessionOutDesc: 'Login status exception, please login again',
  reLogin: 'Login Again',
  loginType: 'Other Login Methods',
  softIntegration: {
    enterpriseWechat: {
      title: 'Enterprise WeChat',
      desc: 'Click to login with Enterprise WeChat',
    },
    btnText: (text: string) => `Login with ${text}`,
  },
  sso: {
    title: 'SSO Login',
    desc: 'Click to login with SSO',
    btnText: (text: string) => `Use ${text}`,
  },
  authenticationType: {
    account: 'Account Password',
    enterpriseWechart: 'Enterprise WeChat',
  },
};

export const updatePassword = {
  title: 'Modify Password',
  desc: 'To ensure account security, please change/set your account password',
  oldPassword: {
    must: 'Enter old password',
  },
  success: 'Password changed successfully, please login again',
  password: {
    must: 'Enter password',
  },
  newPassword: {
    title: 'New Password',
    placeholder: 'Enter new password',
    must: 'Enter new password',
    format:
      'Numbers, letters, _, @, #, !, %?, -, (), *, must contain numbers and letters, 6-20 characters',
  },
  newPasswordRepeat: {
    title: 'Confirm Password',
    placeholder: 'Repeat new password',
    must: 'Enter password again',
    sameWithName: 'Password cannot be same as username',
    format: 'New and old passwords do not match',
    notSame: 'Passwords do not match',
  },
  operate: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
};

export const chooseTenant = {
  reLogin: 'Re-Login',
  tip: 'You have not joined any tenant, please contact the tenant administrator to join the tenant.',
  tenantOwner: 'Tenant Owner: ',
};
