import { graphql } from 'msw';
import { getUniqId } from '../../src/common/utils';
import _ from 'lodash';
import { EUserRole } from '../../src/typings/authority';
import { EUserCreationMode } from '../../src/typings';

const tenants = _.times(5, (index) => ({
  id: getUniqId(),
  displayName: `租户${index + 1}`,
}));

const users = _.times(20, (index) => ({
  userId: getUniqId(),
  nickname: `用户${index}`,
  account: `user${index}`,
  photo: '',
  userSource: 'MANUAL',
  authorityResource: {
    authorityRole: _.sample([
      EUserRole.ADMIN,
      EUserRole.ANALYST,
      EUserRole.USAGER,
    ]),
  },
  allAuthorityResource: [],
  groups: [],
  ban: false,
  creationMode: EUserCreationMode.MANUAL,
  disableProperties: [],
  phone: `1380000${index.toString().padStart(4, '0')}`,
  email: `user${index}@example.com`,
  resetPasswordFirstLogin: false,
  isJoinCurrentTenant: true,
}));

export const tenant = [
  // 获取所有租户和对应负责人
  graphql.query('queryAllTenantAndOwner', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAllTenantAndOwner: tenants.map((tenant) => ({
          tenant,
          owner: _.sample(users),
        })),
      }),
    );
  }),

  // 切换租户
  graphql.mutation('switchTenant', (req, res, ctx) => {
    return res(
      ctx.data({
        switchTenant: true,
      }),
    );
  }),

  // 获取当前用户的租户
  graphql.query('queryTenantByCurrentUser', (req, res, ctx) => {
    return res(
      ctx.data({
        queryTenantByCurrentUser: tenants,
      }),
    );
  }),

  // 批量注册用户到当前租户
  graphql.mutation('registerUserToCurrentTenant', (req, res, ctx) => {
    return res(
      ctx.data({
        registerUserToCurrentTenant: true,
      }),
    );
  }),

  // 搜索用户
  graphql.query('searchUser', (req, res, ctx) => {
    const { request } = req.variables;
    const { keyword, pager } = request;

    let filteredUsers = users;
    if (keyword) {
      filteredUsers = users.filter(
        (user) =>
          user.nickname.includes(keyword) ||
          user.account.includes(keyword) ||
          user.phone.includes(keyword) ||
          user.email.includes(keyword),
      );
    }

    const start = (pager.pageNumber - 1) * pager.pageSize;
    const end = start + pager.pageSize;
    const pagedUsers = filteredUsers.slice(start, end);

    return res(
      ctx.data({
        searchUser: {
          data: pagedUsers,
          total: filteredUsers.length,
          pageNumber: pager.pageNumber,
          pageSize: pager.pageSize,
          hasNext: end < filteredUsers.length,
        },
      }),
    );
  }),

  // 解绑用户
  graphql.mutation('unbindUserTenant', (req, res, ctx) => {
    return res(ctx.data({ unbindUserTenant: true }));
  }),

  // 检查用户是否在当前租户
  graphql.mutation('checkUserInCurrentTenant', (req, res, ctx) => {
    return res(ctx.data({ checkUserInCurrentTenant: _.sample([true, false]) }));
  }),
];
