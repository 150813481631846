import { IUser } from '@/typings';
import { User as AldUser } from '@aloudata/aloudata-design';
interface IProps {
  user?: Pick<IUser, 'nickname'> & Partial<Omit<IUser, 'nickname'>>;
  size?: 'small' | 'large';
  className?: string;
}

const User = ({ size, user, className }: IProps) => {
  if (!user) {
    return <>{'-'}</>;
  }
  const src = user.photo;
  const nickName = user.nickname;

  return (
    <AldUser
      src={src || ''}
      nickname={nickName || ''}
      size={size}
      nicknameClassName={className}
    />
  );
};

export default User;
