export const header = {
  title: 'Metric Catalog',
};

export const sidebar = {
  title: 'Filter',
  clear: 'Clear',
  category: 'Metric Category',
  metricType: 'Metric Type',
  owner: {
    default: 'Metric Owner',
    placeholder: 'Add Owner',
  },
  createTime: 'Create Time',
  modifyTime: 'Modify Time',
  metricLevel: 'Metric Level',
  businessOwner: 'Business Owner',
  inputPlaceholder: 'Please input',
  userPlaceholder: 'Please select',
};

export const mainContent = {
  count: ({ count }: { count: string | number }) => `${count} Metrics`,
  empty: {
    desc: 'No metrics match the criteria, please try again',
  },
};

export const filtersConfig = {
  title: 'Filter Settings',
  searchPlaceholder: 'Search',
  opened: 'Opened',
  closed: 'Closed',
  reset: 'Reset',
  apply: 'Apply',
  applySuccess: 'Save Successfully',
};
