import { useQueryDimensionList } from '@/services/dimension';
import { ECodeEditorWrapperType, TResource } from '.';
import { useGetMetricList } from '@/services/metric';
import { useCallback } from 'react';

const QUERY_LENGTH = 100;

export const useSearchResource = (
  type: ECodeEditorWrapperType,
  asyncMode: boolean,
): ((key: string) => Promise<TResource[]>) | undefined => {
  const { run: queryDimensionList } = useQueryDimensionList();
  const { refetch: queryMetricList } = useGetMetricList();

  const searchDimension = useCallback(
    async (keyword: string) => {
      const res = await queryDimensionList({
        request: {
          pager: {
            pageNumber: 1,
            pageSize: QUERY_LENGTH,
          },
          keyword,
        },
      });

      const data = res.queryDimensionList?.data || [];

      return data.map(
        (item): TResource => ({
          data: item,
          type: ECodeEditorWrapperType.DIMENSION,
        }),
      );
    },
    [queryDimensionList],
  );

  const searchMetric = useCallback(
    async (keyword: string) => {
      const res = await queryMetricList({
        keyword,
        pager: {
          pageNumber: 1,
          pageSize: QUERY_LENGTH,
        },
      });

      const data = res || [];

      return data.map(
        (item): TResource => ({
          data: item,
          type: ECodeEditorWrapperType.METRIC,
        }),
      );
    },
    [queryMetricList],
  );

  if (!asyncMode) return;

  if (type === ECodeEditorWrapperType.DIMENSION) {
    return searchDimension;
  } else if (type === ECodeEditorWrapperType.METRIC) {
    return searchMetric;
  } else if (type === ECodeEditorWrapperType.COMPOSE_METRIC) {
    return async (keyword: string) => {
      const [dimension, metric] = await Promise.all([
        searchDimension(keyword),
        searchMetric(keyword),
      ]);
      return [...dimension, ...metric];
    };
  }

  return;
};
