import { IUser } from '.';
export interface IQueryRecord {
  id: number;
  queryId: string;
  totalCost: number;
  costDetail: {
    cost: number;
    displayName: string;
  }[];
  startTime: number;
  endTime: number;
  hitAccList: {
    name: string;
    id: string;
  }[];
  message: string;
  analysisView: string;
  user: IUser;
  status: EQueryRecordStatus;
  queryOriginType: EQueryOriginType;
  queryOriginEntity: string;
  sql: string;
  queryContent: string;
}

export enum EQueryRecordStatus {
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAIL',
}

export enum EQueryOriginType {
  UI = 'UI',
  OPEN_API = 'OPEN_API',
  JDBC = 'JDBC',
}
