export const queryOriginEntity = {
  analysis: (analysisDisplayName: string) => `指标视图/${analysisDisplayName}`,
  workbook: (workbookDisplayName: string, elementDisplayName: string) =>
    `指标看板/${workbookDisplayName}/${elementDisplayName}`,
  metricTree: (metricTreeDisplayName: string, nodeDisplayName: string) =>
    `指标树/${metricTreeDisplayName}/${nodeDisplayName}`,
  favorite: (metricDisplayName: string) => `收藏/${metricDisplayName}`,
  metricDetail: '指标详情',
  preview: (metricDisplayName: string) => `指标详情/预览/${metricDisplayName}`,
  define: (metricDisplayName: string) => `指标详情/定义/${metricDisplayName}`,
};
export const list = {
  costPicker: {
    prefix: '耗时',
    mid: '至',
    suffix: '秒',
    all: '全部',
    granularity: {
      s: '秒',
      ms: '毫秒',
      m: '分钟',
    },
  },
  title: '查询记录',
  total: (total: number) => `${total}条查询记录`,
  filter: {
    user: '用户：',
    startTime: '查询发起时间：',
    status: '状态：',
    type: '类型：',
    analysisView: '指标视图',
    hitAcc: '加速：',
    hit: '命中',
    miss: '未命中',
    queryContent: '查询内容',
  },
  columns: {
    status: '状态',
    queryId: '查询ID',
    type: '类型',
    acc: '加速',
    user: '用户',
    startTime: '开始时间',
    endTime: '结束时间',
    totalCost: '耗时',
  },
  queryOriginType: {
    ui: '产品界面',
    openApi: '查询API',
    jdbc: 'JDBC',
  },
  status: {
    success: '成功',
    failed: '失败',
    running: '运行中',
  },
};

export const detail = {
  totalCost: '总耗时',
  status: '状态',
  basic: '基础属性',
  cost: '耗时',
  empty: '无',
  acceleration: '加速',
  copy: '复制',
  copySuccess: '复制成功',
  origin: '来源：',
  tab: {
    queryContent: '查询内容',
    sql: 'SQL',
    exception: '异常',
  },
  subTitle: {
    type: '类型',
    user: '用户',
    startTime: '开始时间',
    endTime: '结束时间',
    queryContent: '查询内容',
    sql: 'SQL',
    exception: '异常',
  },
};
