import { EExtractType } from '../../../types';
import { Dropdown, MenuItemType, AldSelect } from '@aloudata/aloudata-design';
import { useMemo } from 'react';
import t from '@/locales';
import styles from './index.less';
export const extractOperatorMap = [
  {
    label: t.components.filter.operator.json.operator.equal,
    value: EExtractType.EQUAL,
  },
  {
    label: t.components.filter.operator.json.operator.notEqual,
    value: EExtractType.NOT_EQUAL,
  },
  {
    label: t.components.filter.operator.json.operator.include,
    value: EExtractType.INCLUDE,
  },
  {
    label: t.components.filter.operator.json.operator.notInclude,
    value: EExtractType.NOT_INCLUDE,
  },
  {
    label: t.components.filter.operator.json.operator.startWith,
    value: EExtractType.START_WITH,
  },
  {
    label: t.components.filter.operator.json.operator.notStartWith,
    value: EExtractType.NOT_START_WITH,
  },
  {
    label: t.components.filter.operator.json.operator.endWith,
    value: EExtractType.END_WITH,
  },
  {
    label: t.components.filter.operator.json.operator.notEndWith,
    value: EExtractType.NOT_END_WITH,
  },
  {
    label: t.components.filter.operator.json.operator.isNull,
    value: EExtractType.IS_NULL,
  },
  {
    label: t.components.filter.operator.json.operator.notNull,
    value: EExtractType.NOT_NULL,
  },
];

/**
 * 操作符
 */
export default function Operator(props: IProps) {
  const { value, onChange } = props;

  const items = useMemo(() => {
    const menuItems: MenuItemType[] = [];

    extractOperatorMap.forEach((optItem) => {
      if (optItem.value === EExtractType.IS_NULL) {
        menuItems.push({
          type: 'divider',
        });
      }
      menuItems.push({
        key: optItem.value,
        label: optItem.label,
        onClick: () => onChange?.(optItem.value),
      });
    });

    return menuItems;
  }, [onChange]);

  const innerValue = useMemo(() => {
    return (
      extractOperatorMap.find((item) => item.value === value)?.label || value
    );
  }, [value]);

  return (
    <Dropdown menu={{ items }}>
      <AldSelect
        className={styles.width}
        labelInValue
        value={innerValue}
        open={false}
      />
    </Dropdown>
  );
}

interface IProps {
  value?: EExtractType;
  onChange?: (value: EExtractType) => void;
}
