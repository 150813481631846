import {
  EOrderType,
  EQuickCalcType,
  IDerivateCalc,
} from '@/typings/analysisView';
import { EResourceType } from '@/typings/authority';
import { TFormula } from '@/typings/formula';
import {
  EFilterType,
  IPeriod,
  IPreAgg,
  ISamePeriodOffset,
} from '@/typings/metric';
import { TDefineMetricInQuery } from '../metric/types';

export interface IPostQueryReqParams {
  queryId: string;
  metrics: Record<string, IReqMetric>;
  dimensions: Record<string, IReqDimension>;
  levels: Record<string, ILevel>;
  metricTimeFilters: {
    type: EFilterType;
    formula: TFormula;
  } | null;
  filters: Array<{
    type: EFilterType;
    formula: TFormula;
  }>; // 筛选条件
  orders: Array<{
    column: string;
    type: EOrderType;
  }>;
  offset?: number;
  limit?: number;
  // 是否强制刷新缓存
  forceRefresh?: boolean;
  queryResultType?: EQueryResultType;
  queryType: EQueryType;
  // 查询请求所属的实体，用于权限校验
  meta?: IPostQueryMeta;
}

export interface IPostQueryMeta {
  resourceId: string | number;
  resourceType: EResourceType;
  // 查询来源用于查询记录展示
  queryOriginEntity: string;
}

// 查询指标的来源方式，区分是系统功能性调用还是用户界面调用
export enum EQueryType {
  SYSTEM = 'SYSTEM', // 系统， 系统功能性调用，例如预览
  UI = 'UI', // UI， 用户界面调用，例如分析簿、分析视图
}

export enum EQueryResultType {
  SQL = 'SQL', // 只返回 SQL
  DATA = 'DATA', // 返回数据
  SQL_AND_DATA = 'SQL_AND_DATA', // 返回 SQL 和数据
}

export interface IReqMetric extends IReqDerivativeCalc {
  id: string;
  code?: string;
  defs?: TDefineMetricInQuery;
}

export interface IReqDerivativeCalc
  extends Omit<IDerivateCalc, 'type' | 'granularity'> {
  calculateType?: EQuickCalcType;
  calculateParams?: {
    offset?: ISamePeriodOffset;
    period?: IPeriod;
    preAggs?: IPreAgg[];
    filter?: TFormula[];
  };
}

export interface IReqDimension {
  id: string;
  column: IReqColumn;
  granularity?: string;
}

export interface ILevel {
  id: string;
  metrics: string[];
  dimensions: string[];
}

export interface IReqColumn {
  column: string;
}
