import {
  ECodeEditorPropsType,
  IListExternalInfo,
  IListItem,
  TExternalInfo,
} from '@/common/domain/codeEditor/CodeEditor/type';
import t from '@/locales';
import { checkHasAuth } from '@/services/dataset/helper';
import {
  IDefColumn,
  IDataset,
  TAssociatedDataset,
  ITableColumn,
} from '@/typings/dataset';
/**
 *  列引用 path 规则速览
 *    引用外部数据集字段：
 *        [dataset.name, column.name]
 *    内部: [column.name]
 */
export enum EFindEntityResultType {
  COLUMN = 'COLUMN',
  ERROR = 'ERROR',
}
export interface IFindEntitySuccess {
  type: EFindEntityResultType.COLUMN;
  result: IDefColumn | ITableColumn | IListItem;
  isOne2ManyDatasetColumn?: boolean;
}
export interface IFindEntityError {
  type: EFindEntityResultType.ERROR;
  errorMsg: string;
}
export type TFindEntityResult = IFindEntitySuccess | IFindEntityError;
const PATH_LENGTH_TWO = 2;
export function findEntityInDataset(
  path: string[],
  dataset: IDataset | null,
  one2manyDatasets: TAssociatedDataset,
  many2oneDatasets: TAssociatedDataset,
): TFindEntityResult {
  if (dataset) {
    const { defColumns } = dataset;
    if (path.length === 1) {
      const [tempColumnName] = path;
      const resultColumn = defColumns.find(
        (column) => column.name === tempColumnName,
      );
      if (resultColumn) {
        return {
          type: EFindEntityResultType.COLUMN,
          result: resultColumn,
        };
      }
    }
    if (path.length === PATH_LENGTH_TWO) {
      const [tempDatasetName, tempColumnName] = path;
      if (dataset.sourceInfo.name === tempDatasetName) {
        const resultColumn = dataset.sourceInfo.columns.find(
          (column) => column.name === tempColumnName,
        );
        if (resultColumn) {
          return {
            type: EFindEntityResultType.COLUMN,
            result: resultColumn,
          };
        }
      }
    }
  }
  if (path.length === PATH_LENGTH_TWO) {
    const [tempDatasetName, tempColumnName] = path;
    const one2oneDatasetItem = one2manyDatasets.find(
      (associatedDataset) => associatedDataset.name === tempDatasetName,
    );
    if (one2oneDatasetItem) {
      // 没有权限则抛出没有权限的错误
      if (!checkHasAuth(one2oneDatasetItem.authorityResource)) {
        return {
          type: EFindEntityResultType.ERROR,
          errorMsg: t.formula.findEntity.error.noAuth,
        };
      }
      const { defColumns: tempColumns } = one2oneDatasetItem;
      const resultColumn = tempColumns.find(
        (column) => column.name === tempColumnName,
      );
      if (resultColumn) {
        return {
          type: EFindEntityResultType.COLUMN,
          result: resultColumn,
        };
      }
    }
    const many2oneDatasetItem = many2oneDatasets.find(
      (associatedDataset) => associatedDataset.name === tempDatasetName,
    );
    if (many2oneDatasetItem) {
      // 没有权限则抛出没有权限的错误
      if (!checkHasAuth(many2oneDatasetItem.authorityResource)) {
        return {
          type: EFindEntityResultType.ERROR,
          errorMsg: t.formula.findEntity.error.noAuth,
        };
      }
      const { defColumns: tempColumns } = many2oneDatasetItem;
      const resultColumn = tempColumns.find(
        (column) => column.name === tempColumnName,
      );

      if (resultColumn) {
        return {
          type: EFindEntityResultType.COLUMN,
          result: resultColumn,
          isOne2ManyDatasetColumn: true,
        };
      }
    }
  }
  return {
    type: EFindEntityResultType.ERROR,
    errorMsg: t.formula.parse.error.columnNotFound,
  };
}
export function findEntityInList(
  path: string[],
  list: IListItem[],
): TFindEntityResult {
  if (path.length === 1) {
    const [tempId] = path;
    const resultColumn = list.find((item) => item.id === tempId);
    if (resultColumn) {
      return {
        type: EFindEntityResultType.COLUMN,
        result: resultColumn,
      };
    }
  }
  return {
    type: EFindEntityResultType.ERROR,
    errorMsg: t.formula.parse.error.columnNotFound,
  };
}
export function findEntityAdapter(
  path: string[],
  externalInfo: TExternalInfo,
): TFindEntityResult {
  if (externalInfo.type === ECodeEditorPropsType.DATASET) {
    return findEntityInDataset(
      path,
      externalInfo.dataset,
      externalInfo.one2manyDatasets,
      externalInfo.many2oneDatasets || [],
    );
  } else {
    return findEntityInList(path, (externalInfo as IListExternalInfo).list);
  }
}
