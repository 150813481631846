import { EResourceType, EUserRole } from './authority';
import { IUser } from '@/typings/index';
import { ISelectDataInput } from './property';
import { EMetricType } from '@/typings/metric';

// 审批平台类型
export enum EApprovalPlatformType {
  INTERNAL = 'INTERNAL', // 内部审批
  EXTERNAL = 'EXTERNAL', // 外部审批
}

export enum EApprovalProcessStatus {
  // 待审批
  PENDING = 'PENDING',
  // 拒绝
  REFUSE = 'REFUSE',
  // 通过
  PASS = 'PASS',
  // 撤销
  REVOKE = 'REVOKE',

  END = 'END',
}

export type TApprovalResourceType =
  | EResourceType.METRIC
  | EResourceType.DIMENSION;

export enum EApprovalOperationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  GRANT_AUTH = 'GRANT_AUTH',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum EApprovalStatus {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export enum EApprovalModifyType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

// 审批平台配置
export interface IInternalApprovalPlatformConfig {
  approvalPlatformType: EApprovalPlatformType.INTERNAL; // 审批平台类型
}

export interface IExternalApprovalPlatformConfig {
  approvalPlatformType: EApprovalPlatformType.EXTERNAL; // 审批平台类型
  approvalPlatformDetails: IApprovalPlanfromDetails;
  approvalPlatformAddress: IApprovalPlatformAddress;
}

export type TApprovalPlatformConfig =
  | IInternalApprovalPlatformConfig
  | IExternalApprovalPlatformConfig;

export interface IApprovalPlanfromDetails {
  approvalPlatformUrl?: string; //三方审批的地址
}

export enum ERequestType {
  GET = 'GET',
  POST = 'POST',
}

export enum EApprovalPlatFormAddressParamType {
  SELF_CUSTOM_PARAMS = 'SELF_CUSTOM_PARAMS', // 自定义参数
  SELF_CUSTOM_VALUE = 'SELF_CUSTOM_VALUE', // 自定义值
  SYSTEM_PARAM = 'SYSTEM_PARAM', // 系统参数
}

export interface IApprovalPlatFormAddressParam {
  key: string;
  value: string;
  type: EApprovalPlatFormAddressParamType;
  resourceType: EResourceType.METRIC | EResourceType.DIMENSION;
  publicParams: boolean; // 是否是公共参数
}

export interface IApprovalPlatformAddress {
  requestUrl: string; // 请求地址
  requestType: ERequestType; // 请求方法
  headers: IApprovalPlatFormAddressParam[];
  body: IApprovalPlatFormAddressParam[];
  response: {
    successField: string; // 成功字段
    successValue: string; // 成功值
  };
}

export interface IApprovalSettingItem {
  approvalCatalogId: number;
  approvalOperationType: EApprovalOperationType;
  approvalResourceType: TApprovalResourceType;
  displayName: string;
  description: string;
  status: EApprovalStatus;
}

export interface IInternalApprovalDetail {
  tempId?: string; // 临时id
  approvalId?: string; // 审批流id ，新建出来的没有id
  approvalPlatformType?: EApprovalPlatformType.INTERNAL;
  sort: number; // 审批流优先级
  displayName: string;
  // 如果是指标，且开启了指标等级，这里会返回相关信息
  approvalPropertyConfig?: {
    name: string;
    dataInput: ISelectDataInput;
  }[];
  approvalProcessConfig: IApprovalProcessConfig[];
  approvalUserResourceType: EApprovalUserResourceType;
  approvalUserResourceId: string[]; // 选择的用户资源id，如果是用户类型，则是用户类型枚举，approvalUserResourceType为全部的时候，该字段为[]
  modifyType?: EApprovalModifyType; // 修改的type
}

export interface IExternalApprovalDetail {
  approvalId?: string; // 审批流id ，新建出来的没有id
  status: EApprovalStatus;
  approvalPlatformType?: EApprovalPlatformType.EXTERNAL;
  approvalPlatformAddress: IApprovalPlatformAddress;
}

export type TApprovalDetail = IInternalApprovalDetail | IExternalApprovalDetail;

export enum EApprovalUserResourceType {
  USER = 'USER',
  USER_GROUP = 'USER_GROUP',
  ROLE = 'ROLE',
  ALL = 'ALL',
}

export enum EApprovalProcessType {
  AND = 'AND', // 会签
  OR = 'OR', // 或签
}

// 审批流节点配置
export interface IApprovalProcessConfig {
  tempId?: string; // 临时id
  approvalProcessId?: string; // 审批流节点Id,
  approvalId?: string; // 审批流Id
  sort: number; // 审批流节点优先级
  approvalUserResourceType: EApprovalUserResourceType;
  approvalUserResourceId: string[]; // 选择的用户资源id，如果是用户类型，则是用户类型枚举，approvalUserResourceType为全部的时候，该字段为[]
  approvalProcessType: EApprovalProcessType;
  modifyType?: EApprovalModifyType; // 操作的type
}

// 审批中心列表

export interface IApprovalCenterItem {
  approvalId: string; // 审批流id
  approvalLogId: string; // 审批日志id
  approvalProcessId: string; // 审批流节点id
  approvalSnapshotId: string; // 审批快照id
  resourceId: string; // 资源id
  resourceName: string; // 资源名称
  resourceType: EMetricType | null;
  approvalResourceType: EResourceType.METRIC | EResourceType.DIMENSION; // 审批类型
  approvalOperationType: EApprovalOperationType; // 审批操作类型
  message: string; // 审批消息
  approvalOperator: IUser | null; // 审批人
  applicantOperator: IUser | null; // 审批发起人
  createTime: number; // 审批发起时间
  canProcess: boolean; // 是否可以操作
  approvalResourceTargetResult:
    | EUserRole.ANALYST
    | EUserRole.USAGER
    | EUserRole.OWNER; // 审批目标资源值，例如：权限审批中的权限类型-指标使用者
  approvalFinalProcessStatus: EApprovalProcessStatus; // 审批最终状态
  processNodes: IApprovalProcessNodes[]; // 审批消息日志
}

export interface IApprovalProcessNodes {
  approvalProcessId: string;
  approvalId: string;
  approvalUserResourceId: string;
  approvalUserResourceType: EApprovalUserResourceType;
  approvalProcessType: EApprovalProcessType;
  approvalUserResourceNames: string[];
  users: IUser[];
  processStatus: EApprovalProcessStatus;
  processTime: number;
  approvalMessageLogs: IApprovalMessageLog[] | null;
}

export interface IApprovalMessageLog {
  approvalLogId: string; // 审批日志id
  approvalOperator: IUser | null; // 审批人
  approvalProcessId: string; // 审批流节点id
  approvalUserResourceId: string[]; // 审批人id
  approvalUserResourceType: EApprovalUserResourceType; // 审批人类型
  realApprovalOperator: IUser | null; // 真实审批人
  message: string; // 审批消息
  approvalTime: number; // 审批时间
  approvalProcessStatus: EApprovalProcessStatus; // 审批状态
}

export type IApprovalLogDetail = IApprovalCenterItem;

export interface IApprovalPlatformConfigParam {
  displayName: string;
  name: string;
  childParams: IApprovalPlatformConfigParam[];
}
