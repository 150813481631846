import { EDateGranularityType } from '@/typings';

/**
 * 根据时间粒度，获取 DatePicker 选择器类型
 */
export function getPicker(granularity: string) {
  if (granularity === EDateGranularityType.YEAR) {
    return 'year';
  }
  if (granularity === EDateGranularityType.QUARTER) {
    return 'quarter';
  }
  if (granularity === EDateGranularityType.MONTH) {
    return 'month';
  }
  if (granularity === EDateGranularityType.WEEK) {
    return 'week';
  }
  return undefined;
}
