import { EColumnDataType } from '@/typings';
import { EFilterType, ELooseDataType, IBaseProps } from '../../../types';
import { Dropdown, MenuItemType, AldSelect } from '@aloudata/aloudata-design';
import { looseDataTypeMap } from '../../../constants';
import { useMemo } from 'react';
import t from '@/locales';

export const operatorLooseDataTypeMap = {
  [ELooseDataType.TIME]: [
    {
      label: t.components.filter.operator.date.specific,
      value: EFilterType.DATE_SPECIFY,
    },
    {
      label: t.components.filter.operator.date.range,
      value: EFilterType.DATE_RANGE,
    },
    {
      label: t.components.filter.operator.date.outOfRange,
      value: EFilterType.DATE_OUTSIDE_RANGE,
    },
    {
      label: t.components.filter.operator.date.earlier,
      value: EFilterType.DATE_BEFORE,
    },
    {
      label: t.components.filter.operator.date.later,
      value: EFilterType.DATE_AFTER,
    },
    {
      label: t.components.filter.operator.date.matchMetric,
      value: EFilterType.MATCH_METRIC,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.NUMBER]: [
    {
      label: t.components.filter.operator.number.equal,
      value: EFilterType.EQUAL,
    },
    {
      label: t.components.filter.operator.number.notEqual,
      value: EFilterType.NOT_EQUAL,
    },
    {
      label: t.components.filter.operator.number.greater,
      value: EFilterType.GREATER_THAN,
    },
    {
      label: t.components.filter.operator.number.greaterEqual,
      value: EFilterType.GREATER_THAN_OR_EQUAL,
    },
    {
      label: t.components.filter.operator.number.less,
      value: EFilterType.LESS_THAN,
    },
    {
      label: t.components.filter.operator.number.lessEqual,
      value: EFilterType.LESS_THAN_OR_EQUAL,
    },
    {
      label: t.components.filter.operator.number.between,
      value: EFilterType.NUMBER_RANGE,
    },
    {
      label: t.components.filter.operator.number.outOfRange,
      value: EFilterType.NUMBER_OUTSIDE_RANGE,
    },
    {
      label: t.components.filter.operator.number.matchMetric,
      value: EFilterType.MATCH_METRIC,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.TEXT]: [
    {
      label: t.components.filter.operator.text.equal,
      value: EFilterType.EQUAL,
    },
    {
      label: t.components.filter.operator.text.notEqual,
      value: EFilterType.NOT_EQUAL,
    },
    {
      label: t.components.filter.operator.text.include,
      value: EFilterType.INCLUDE,
    },
    {
      label: t.components.filter.operator.text.exclude,
      value: EFilterType.NOT_INCLUDE,
    },
    {
      label: t.components.filter.operator.text.startWith,
      value: EFilterType.START_WITH,
    },
    {
      label: t.components.filter.operator.text.notStartWith,
      value: EFilterType.NOT_START_WITH,
    },
    {
      label: t.components.filter.operator.text.endWith,
      value: EFilterType.END_WITH,
    },
    {
      label: t.components.filter.operator.text.notEndWith,
      value: EFilterType.NOT_END_WITH,
    },
    {
      label: t.components.filter.operator.text.matchMetric,
      value: EFilterType.MATCH_METRIC,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.BOOLEAN]: [
    {
      label: t.components.filter.operator.boolean.equal,
      value: EFilterType.EQUAL,
    },
    {
      label: t.components.filter.operator.boolean.notEqual,
      value: EFilterType.NOT_EQUAL,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.JSON]: [
    {
      label: t.components.filter.operator.json.extract,
      value: EFilterType.EXTRACT,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
};
export const operatorLooseDataTypeUserAttributeMap = {
  [ELooseDataType.TEXT]: [
    {
      label: t.filter.filter.equalAttribute,
      value: EFilterType.EQUAL_ATTRIBUTE,
    },
    // 不等于用户属性
    {
      label: t.filter.filter.notEqualAttribute,
      value: EFilterType.NOT_EQUAL_ATTRIBUTE,
    },
    // 包含用户属性
    {
      label: t.filter.filter.containAttribute,
      value: EFilterType.CONTAIN_ATTRIBUTE,
    },
    // 不包含用户属性
    {
      label: t.filter.filter.notContainAttribute,
      value: EFilterType.NOT_CONTAIN_ATTRIBUTE,
    },
  ],
  [ELooseDataType.NUMBER]: [
    {
      label: t.filter.filter.equalAttribute,
      value: EFilterType.EQUAL_ATTRIBUTE,
    },
    {
      label: t.filter.filter.notEqualAttribute,
      value: EFilterType.NOT_EQUAL_ATTRIBUTE,
    },
  ],
  [ELooseDataType.BOOLEAN]: [],
  [ELooseDataType.JSON]: [],
  [ELooseDataType.TIME]: [
    {
      label: t.filter.filter.equalAttribute,
      value: EFilterType.EQUAL_ATTRIBUTE,
    },
    // 不等于用户属性
    {
      label: t.filter.filter.notEqualAttribute,
      value: EFilterType.NOT_EQUAL_ATTRIBUTE,
    },
  ],
};
/**
 * 操作符
 */
export default function DimensionOperatorInDerivedMetric(props: IProps) {
  const {
    columnDataType,
    value,
    onChange,
    className,
    hasMatchMetric = true,
    hasUserAttribute = false,
  } = props;

  const looseDataType = looseDataTypeMap[columnDataType];
  const userAttributeOperatorList =
    operatorLooseDataTypeUserAttributeMap[looseDataType];
  const normalOperatorList = operatorLooseDataTypeMap[looseDataType];
  const items = useMemo(() => {
    const menuItems: MenuItemType[] = [];

    let hasDivider = false;
    const operatorList = hasUserAttribute
      ? [...normalOperatorList, ...userAttributeOperatorList]
      : normalOperatorList;
    operatorList.forEach((optItem) => {
      if (optItem.value === EFilterType.MATCH_METRIC) {
        menuItems.push({
          type: 'divider',
        });
        hasDivider = true;
      }
      if (optItem.value === EFilterType.IS_NULL && !hasDivider) {
        menuItems.push({
          type: 'divider',
        });
        hasDivider = true;
      }

      menuItems.push({
        key: optItem.value,
        label: optItem.label,
        onClick: () => onChange?.(optItem.value),
      });
    });

    return menuItems;
  }, [
    onChange,
    hasUserAttribute,
    normalOperatorList,
    userAttributeOperatorList,
  ]);
  const options = useMemo(() => {
    if (hasUserAttribute) {
      return [
        ...operatorLooseDataTypeMap[looseDataType],
        ...operatorLooseDataTypeUserAttributeMap[looseDataType],
      ];
    }
    return operatorLooseDataTypeMap[looseDataType].filter(
      (item) => hasMatchMetric || item.value !== EFilterType.MATCH_METRIC,
    );
  }, [looseDataType, hasMatchMetric, hasUserAttribute]);
  return (
    <Dropdown
      menu={{
        items: items.filter(
          (item) => hasMatchMetric || item.key !== EFilterType.MATCH_METRIC,
        ),
      }}
    >
      <AldSelect
        className={className}
        labelInValue
        width="auto"
        options={options}
        value={value}
        open={false}
      />
    </Dropdown>
  );
}

interface IProps extends IBaseProps {
  value?: EFilterType;
  onChange?: (value: EFilterType) => void;
  columnDataType: EColumnDataType;
  hasMatchMetric?: boolean;
  hasUserAttribute?: boolean;
}
