export const role = {
  admin: '可管理',
  owner: '负责人',
  viewer: '查看者',
  analyst: '数据管理员',
  usager: '可使用',
  revoke: '撤销授权',
};

export const auth = {
  noAuth: '无权限',
  applyAuth: '申请权限',
  add: '添加授权',
  authority: {
    search: '搜索用户、用户组',
    default: '授权',
    success: '授权成功',
  },
  notExisted: {
    default: '抱歉，当前资源不存在',
    des: '当前资源可能已经被删除。',
  },
  noPermission: {
    default: '抱歉，您无权访问此页面',
    des: '请与组织管理员联系，获取访问权限。',
  },
  noDatasetPermission: ({ name }: { name: string | number }) =>
    `无权限：数据负责人${name}没有该数据集的使用权限，该维度不可用`,
  list: {
    name: '授权用户/用户组',
    extendTenant: '继承自上级类目',
    revoke: '撤销',
    columnTitle: {
      user: '用户/用户组',
      owner: '负责人',
      auth: '权限',
    },
    owner: '所有者',
  },
  goBack: '返回',
};

// 集中授权页面
export const centralizedAuth = {
  title: '集中授权',
  authByUser: '按用户授权',
  authByResource: '按资源授权',
  useAuth: '使用权限',
  rowFilterAuth: '行级权限',
  resource: '资源',
  user: '用户',
  userGroup: '用户组',
  addUser: '添加用户',
  resourceType: {
    metric: '指标',
    dimension: '维度',
    dataset: '数据集',
    analysisView: '指标视图',
    datasource: '数据源',
  },
  accountType: {
    user: '用户',
    userGroup: '用户组',
  },
  empty: (typeName: string) =>
    `当前页面没有任何内容，请选择一个${typeName}以查看`,
  exception: '发生了错误，请刷新页面重试',
  operation: '操作',
  remove: '移除',
  ownerWarning: '不能修改负责人的权限',
  selfWarning: '不能修改自己的权限',
  addUserSuccess: '添加成功',
  removeUserSuccess: '移除成功',
};

export const roleName = {
  tenant: {
    owner: {
      name: '超级管理员',
      desc: '',
    },
    admin: {
      name: '平台管理员',
      desc: '',
    },
    analyst: {
      name: '指标定义者',
      desc: '',
    },
    usager: {
      name: '指标使用者',
      desc: '',
    },
  },
  category: {
    owner: {
      name: '负责人',
      auth: '可管理',
      desc: '',
    },
    admin: {
      name: '管理员',
      auth: '可管理',
      desc: '类目下资产可授权/管理/新建/使用',
    },
    creator: {
      name: '创建者',
      auth: '可新建',
      desc: '类目下资产可新建/使用',
    },
    usager: {
      name: '使用者',
      auth: '可使用',
      desc: '类目下资产可使用',
    },
    viewer: {
      name: '查看者',
      auth: '可查看',
      desc: '类目下资产可查看',
    },
  },
  resource: {
    owner: {
      name: '负责人',
      auth: '可管理',
      desc: '',
    },
    admin: {
      name: '管理员',
      auth: '可管理',
      desc: '',
    },
    usager: {
      name: '使用者',
      auth: '可使用',
      desc: '',
    },
    viewer: {
      name: '查看者',
      auth: '可查看',
      desc: '',
    },
  },
};
