export const list = {
  title: '指标监控',
  filter: {
    all: (number: number) => `全部 (${number})`,
    error: (number: number) => `异常 (${number})`,
    normal: (number: number) => `正常 (${number})`,
    onlyMyOwen: '只看我负责的',
  },
  add: '新建监控',
  card: {
    status: {
      error: '异常',
      normal: '正常',
      disabled: '停用',
    },
    execTime: '检测时间',
    owner: '负责人',
    dataError: '数据发生异常',
    emptyDesc: '暂无调度结果',
    errorMsg: (msg: string) => `命中告警条件：${msg}`,
    operate: {
      view: '查看',
      edit: '编辑',
      delete: '删除',
      addSuccess: '新建成功',
      editSuccess: '编辑成功',
      enable: '启用',
      disable: '停用',
    },
  },
};

export const detail = {
  history: '历史记录',
  rules: '告警规则',
  monitorTime: '检测时间',
  metricData: '指标结果',
  errorResult: '异常原因',
  dimensionSplitLimit: '仅展示前300个数据',
};

export const monitorConfigModal = {
  title: {
    create: '新建监控',
    update: '编辑监控',
  },
  monitorLevel: '告警级别',
  displayNamePlaceholder: '请输入告警名称',
  metricSelectNumberType: '只支持数值类型的指标',
  metricConfig: '监控指标设置',
  metricPlaceholder: '选择指标',
  pushConfig: '消息推送设置',
  baseInfo: '基础信息',
  name: '告警名称',
  metric: '告警指标',
  dimensionSplit: {
    title: '维度拆分',
    sentence1: '按',
    sentence2: '分组指标，监控',
    sentence3: '的指标结果。',
    sentence4: '分组指标，监控以下维度值的指标结果：',
    all: '全部',
    custom: '自定义',
  },
  userPropertyKey: {
    before: '以用户属性',
    after: (softText: string) => `作为${softText}userId`,
  },
  detectTime: '检测时间',
  rule: {
    title: '告警规则',
    tip: '满足以下条件',
    metric: '指标',
    metricValue: '值',
  },
  operator: {
    equal: '等于',
    notEqual: '不等于',
    largeThan: '大于',
    largeEqual: '大于等于',
    lessThan: '小于',
    lessEqual: '小于等于',
    between: '介于之间',
    outOfRange: '超出范围',
  },
  footer: {
    nextStep: '下一步',
    cancel: '取消',
    prevStep: '上一步',
    create: '创建',
    update: '确定',
  },
  pushConfigTitle: '推送设置',
  pushConfigTitleTip: '开启后，当达到指标告警阈值时向指定用户推送消息通知',
  pushChannel: '推送渠道',
  channelTab: '渠道',
  channel: '渠道',
  pushChannelPlaceholder: '选择推送渠道',
  channelIndex: (index: number) => `渠道 ${index} 配置`,
  receiveUser: '接收用户',
  userType: {
    user: '指定用户',
    custom: '自定义',
  },
  message: {
    content: '消息内容',
    title: '标题',
    type: '消息类型',
    markdown: 'Markdown',
    plainText: '文本',
    titlePlaceholder: '输入标题',
    desc: '消息描述',
    reminderRange: '提醒范围',
    reset: '恢复至默认',
    reminder: {
      user: '提醒成员 (userid)',
      phone: '提醒成员（手机号）',
      allMember: '提醒所有人（@所有人）',
    },
    defaultContent: (
      metricTime: string,
      metricName: string,
      metricResult: string,
      rule: string,
      owner: string,
      dimensionSplit?: string,
    ) =>
      `<p><span>截止&nbsp;</span>${metricTime}<span>&nbsp;，您关注的指标&nbsp;</span>${
        dimensionSplit ? `${dimensionSplit}<span>&nbsp;&nbsp;</span>` : ''
      }${metricName}<span>&nbsp;的指标结果为&nbsp;</span>${metricResult}<span>&nbsp;，命中告警条件：&nbsp;</span>${rule}<span>&nbsp;</span></p><p><span>请及时关注，指标负责人为&nbsp;</span>${owner}<span>&nbsp;</span></p>`,
  },
  testPush: '推送测试',
  commitPush: '发送测试',
  commitPushSuccess: '已将消息发送至指定渠道',
  validate: {
    displayName: '请输入告警名称',
    metricCode: '请选择告警指标',
    dimensionSplit: {
      dimension: '请选择维度',
      values: '请选择维度值',
      notAvailableDimension: '该维度不是指标的可用维度',
    },
    execCron: '检测时间配置错误',
    channel: '请选择渠道',
    receivers: '请选择接收用户',
    messageTitle: '请输入标题',
    testReceivers: '请选择测试用户',
  },
};

export const pushConfig = {
  message: {
    desc: {
      toolbar: {
        normal: '正文',
        h1: '大标题',
        h2: '小标题',
        insertParams: '插入参数',
        insertReminder: '插入提醒人',
        role: '角色',
        user: '用户',
      },
      placeholder: '请输入消息描述',
    },
    params: {
      monitorLevel: '告警等级',
      monitorName: '告警名称',
      time: '检测时间',
      dimensionValue: '拆分维值',
      metricName: '监控指标名',
      metricResult: '指标结果',
      monitorRule: '命中告警条件',
      metricOwner: '指标负责人',
    },
  },
};
