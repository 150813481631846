import {
  ECodeEditorPropsType,
  ETokenType,
  IListItem,
  IToken,
  TExternalInfo,
} from '@/common/domain/codeEditor/CodeEditor/type';
import { IColumn, IDataset, TAssociatedDataset } from '@/typings/dataset';
import {
  EFormulaType,
  EOriginDataType,
  IFormulaPosition,
  TFormula,
} from '@/typings/formula';
import {
  EFindEntityResultType,
  findEntityInDataset,
  findEntityInList,
} from './findEntity';
import iterate from './iterate';
import { DEFAULT_SEPARATOR } from '@/constants';
import {
  getTokens,
  tokenList2Dsl,
} from '@/common/domain/codeEditor/CodeEditor/helper/formulaHelper';
import _ from 'lodash';

const PATH_LENGTH_TWO = 2;

const firstEmptyToken: IToken = {
  col: 0,
  line: 1,
  linBreaks: 0,
  offset: 0,
  text: '',
  type: ETokenType.WS,
  value: '',
  toString: () => '',
  end: 0,
};

/**
 * 通过公式 AST 生成 DSL
 * @param {TFormula} ast 公式的 AST
 * @returns { string } 公式的 DSL
 */
const genFormula = (
  ast: TFormula,
  dataset: IDataset | null,
  one2manyDatasets: TAssociatedDataset,
  many2oneDatasets: TAssociatedDataset,
): string => {
  const { type } = ast;
  if (type === EFormulaType.PARTIAL) {
    const { text } = ast;
    return text;
  }
  if (type === EFormulaType.EMPTY) {
    return '';
  }
  return iterate(ast, '', (astItem, pre) => {
    const { type: astItemType } = astItem;
    if (astItemType === EFormulaType.BINARY_OPERATOR) {
      const { op } = astItem;
      const [x, y] = pre;
      return `${x} ${op} ${y}`;
    }
    if (astItemType === EFormulaType.FUNCTION) {
      const { op } = astItem;
      return `${op}(${pre.toString()})`;
    }
    if (astItemType === EFormulaType.PARENTHESIS) {
      const [x] = pre;
      return `(${x})`;
    }

    if (astItemType === EFormulaType.COLUMN) {
      const { path } = astItem;
      const entity = findEntityInDataset(
        path,
        dataset,
        one2manyDatasets,
        many2oneDatasets,
      );
      if (entity.type === EFindEntityResultType.ERROR) {
        return `[${path.join(DEFAULT_SEPARATOR)}]`;
      }

      if (path.length === 1) {
        return `[${processingEscapeCharacter(
          (entity.result as IColumn).displayName,
        )}]`;
      }

      if (path.length === PATH_LENGTH_TWO) {
        const [datasetName, columnName] = path;
        if (dataset && datasetName === dataset.sourceInfo.name) {
          const tempColumn = dataset.sourceInfo.columns.find(
            (column) => column.name === columnName,
          );

          if (tempColumn) {
            return `[${processingEscapeCharacter(
              dataset.displayName,
            )}/${processingEscapeCharacter(tempColumn.displayName)}]`;
          }
        }

        const tempDataset = [...one2manyDatasets, ...many2oneDatasets].find(
          (associatedDataset) => associatedDataset.name === datasetName,
        );

        if (tempDataset) {
          const tempColumn = tempDataset.defColumns.find(
            (column) => column.name === columnName,
          );

          if (tempColumn)
            return `[${processingEscapeCharacter(
              tempDataset.displayName,
            )}/${processingEscapeCharacter(tempColumn.displayName)}]`;
        }
      }

      if (path.length === 1) {
        return `[${path[0]}]`;
      }
      return `[${path[0]}/${path[path.length - 1]}]`;
    }
    if (astItemType === EFormulaType.CONSTANT) {
      const { val } = astItem;
      const { type: constantType } = val;
      if (
        constantType === EOriginDataType.DOUBLE ||
        constantType === EOriginDataType.BIGINT
      ) {
        const { val: constantValue } = val;
        return constantValue;
      }
      if (constantType === EOriginDataType.VARCHAR) {
        const { val: constantValue } = val;
        return `"${constantValue.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}"`;
      }
      if (constantType === EOriginDataType.BOOLEAN) {
        const { val: constantValue } = val;
        return constantValue.toString();
      }
      return 'NULL';
    }
    if (astItemType === EFormulaType.UNARY_OPERATOR) {
      const [x] = pre;
      const { op } = astItem;
      return `${op}${x}`;
    }
    return '';
  });
};

/**
 * 通过公式AST转tokenList
 */
export const genFormulaToTokens = (
  ast: TFormula,
  dataset: IDataset,
  one2manyDatasets: TAssociatedDataset,
  many2oneDatasets: TAssociatedDataset,
) => {
  const dsl = genFormula(ast, dataset, one2manyDatasets, many2oneDatasets);

  return getTokens(dsl);
};

export const constructToken = (tokenInfo: {
  col: number;
  line: number;
  text: string;
  type: ETokenType;
  value: string[] | string;
  isComplete?: boolean;
  idPath?: string[];
  columnInfo?: {
    columnId: string;
    sheetId: string;
  };
  errorMsg?: string;
}): IToken => {
  const {
    col,
    text,
    type,
    line,
    value,
    isComplete,
    idPath,
    columnInfo,
    errorMsg,
  } = tokenInfo;
  return {
    offset: col,
    line,
    linBreaks: 0,
    col,
    text,
    type,
    value,
    end: col + text.length,
    isComplete,
    idPath,
    columnInfo,
    toString: () => text,
    error: errorMsg ? { errorMsg } : undefined,
  };
};
export const defaultPosition: IFormulaPosition = {
  start: 1,
  line: 0,
  text: '',
};

export const getTokenListTextLength = (tokenList: IToken[]) => {
  return tokenList.reduce((acc, token) => acc + token.text.length, 0);
};

export const genTokensInDataset = (
  ast: TFormula,
  dataset: IDataset | null,
  one2manyDatasets: TAssociatedDataset,
  many2oneDatasets: TAssociatedDataset,
): IToken[] => {
  const { type } = ast;
  if (type === EFormulaType.PARTIAL) {
    const { text, errorMsg } = ast;

    return getTokens(text)
      .map((token) => {
        if (token.type === ETokenType.COLUMN) {
          let result: string = token.text;
          try {
            // 如果无法解析这个path则代表之前存储的是一个错误的path
            result = genFormula(
              {
                type: EFormulaType.COLUMN,
                path: token.value as string[],
                position: [],
              },
              dataset,
              one2manyDatasets,
              many2oneDatasets,
            );
          } catch {}
          return {
            ...token,
            text: result,
          };
        }
        return token;
      })
      .map((token) => {
        return {
          ...token,
          error: errorMsg
            ? {
                errorMsg,
              }
            : undefined,
        };
      });
  }

  if (type === EFormulaType.EMPTY) {
    return [];
  }

  const tempToken = iterate(ast, [], (astItem, pre: Array<IToken[]>) => {
    const { type: astItemType } = astItem;
    if (astItemType === EFormulaType.BINARY_OPERATOR) {
      const { op, errorMsg } = astItem;

      const position = astItem.position || [defaultPosition];

      const [x, y] = pre;

      const opToken = constructToken({
        col: position[0].start - 1,
        line: position[0].line,
        text: op,
        type: (op as string).toLowerCase() as unknown as ETokenType,
        value: [op],
        errorMsg,
      });

      return [
        ...x,
        ...getWsTokens(_.last(x)!, opToken),
        opToken,
        ...getWsTokens(opToken, y[0]),
        ...y,
      ];
    }
    if (astItemType === EFormulaType.FUNCTION) {
      const { op, errorMsg, position } = astItem;

      const [funcPos, leftBracketsPos, rightBracketsPos] = position || [
        defaultPosition,
        defaultPosition,
        defaultPosition,
      ];

      const funcToken = constructToken({
        col: funcPos.start - 1,
        line: funcPos.line,
        text: op,
        type: ETokenType.FUNCTION_ID,
        value: [op],
        errorMsg,
      });

      const leftToken = constructToken({
        col: leftBracketsPos.start - 1,
        line: leftBracketsPos.line,
        text: '(',
        type: ETokenType.LEFT_BRACKETS,
        value: '(',
      });

      const rightToken = constructToken({
        col: rightBracketsPos.start - 1,
        line: rightBracketsPos.line,
        text: ')',
        type: ETokenType.RIGHT_BRACKETS,
        value: ')',
      });

      return [
        funcToken,
        leftToken,
        ...getWsTokens(leftToken, pre[0]?.[0]),
        ...(pre
          .map((tokenList, index) => {
            const commaToken = constructToken({
              col:
                _.last(tokenList)!.col +
                getTokenTextLength(_.last(tokenList)!) +
                1,
              text: ',',
              type: ETokenType.COMMA,
              value: ',',
              line: _.last(tokenList)!?.line,
            });

            return index !== pre.length - 1
              ? [
                  ...tokenList,
                  commaToken,
                  ...getWsTokens(commaToken, pre[index + 1]?.[0]),
                ]
              : tokenList.map((token) => ({
                  ...token,
                }));
          })
          .flat(Infinity) as IToken[]),
        ...getWsTokens(_.last(_.last(pre))!, rightToken),
        rightToken,
      ];
    }
    if (astItemType === EFormulaType.PARENTHESIS) {
      const [x] = pre;
      const [leftPos, rightPos] = astItem.position || [
        defaultPosition,
        defaultPosition,
      ];

      const leftToken = constructToken({
        col: leftPos.start - 1,
        line: leftPos.line,
        text: '(',
        type: ETokenType.LEFT_BRACKETS,
        value: '(',
      });
      const rightToken = constructToken({
        col: rightPos.start - 1,
        line: rightPos.line,
        text: ')',
        type: ETokenType.RIGHT_BRACKETS,
        value: ')',
      });
      return [
        leftToken,
        ...getWsTokens(leftToken, x[0]),
        ...x,
        ...getWsTokens(_.last(x)!, rightToken),
        rightToken,
      ];
    }
    if (astItem.type === EFormulaType.COLUMN) {
      const [x] = pre;
      const { path, errorMsg } = astItem;
      const position = astItem.position || [defaultPosition];
      const entity = findEntityInDataset(
        path,
        dataset,
        one2manyDatasets,
        many2oneDatasets,
      );

      if (entity.type === EFindEntityResultType.ERROR) {
        if (path.length === 1) {
          return [
            constructToken({
              col: position[0].start - 1,
              line: position[0].line,
              text: `[${path[0]}]`,
              type: ETokenType.COLUMN,
              value: [path[0]],
              idPath: path,
              errorMsg,
            }),
          ];
        }
        return [
          constructToken({
            col: position[0].start - 1,
            line: position[0].line,
            text: `[${path[0]}/${path[path.length - 1]}]`,
            type: ETokenType.COLUMN,
            value: [path[0], path[path.length - 1]],
            idPath: path,
            errorMsg,
          }),
        ];
      }

      if (path.length === 1) {
        const tempColumnToken = constructToken({
          col: position[0].start - 1,
          line: position[0].line,
          text: `[${processingEscapeCharacter(
            (entity.result as IColumn).displayName,
          )}]`,
          type: ETokenType.COLUMN,
          value: [(entity.result as IColumn).name],
          idPath: path,
          errorMsg,
        });
        return [tempColumnToken, ...getWsTokens(tempColumnToken, x[0]), ...x];
      }

      if (path.length === PATH_LENGTH_TWO) {
        if (path.length === PATH_LENGTH_TWO) {
          const [datasetName, columnName] = path;

          const tempDataset = [...one2manyDatasets, ...many2oneDatasets].find(
            (associatedDataset) => associatedDataset.name === datasetName,
          );

          if (tempDataset) {
            const tempColumn = tempDataset.defColumns.find(
              (column) => column.name === columnName,
            );

            if (tempColumn) {
              const tempColumnToken = constructToken({
                col: position[0].start - 1,
                line: position[0].line,
                text: `[${processingEscapeCharacter(
                  tempDataset.displayName,
                )}/${processingEscapeCharacter(tempColumn.displayName)}]`,
                type: ETokenType.COLUMN,
                value: [
                  tempDataset.displayName,
                  (entity.result as IColumn).name,
                ],
                idPath: path,
                errorMsg,
              });
              return [
                tempColumnToken,
                ...getWsTokens(tempColumnToken, x[0]),
                ...x,
              ];
            }
          }
        }
      }

      const tempColumnToken = constructToken({
        col: position[0].start - 1,
        line: position[0].line,
        text: `[${path[0]}/${path[path.length - 1]}]`,
        type: ETokenType.COLUMN,
        value: [path[0], path[path.length - 1]],
        idPath: path,
        errorMsg,
      });

      return [tempColumnToken, ...getWsTokens(tempColumnToken, x[0]), ...x];
    }
    if (astItemType === EFormulaType.CONSTANT) {
      const { val } = astItem;
      const position = astItem.position || [defaultPosition];

      const { type: constantType } = val;

      const tempPosToken = {
        col: position[0].start - 1,
        line: position[0].line,
      } as IToken;

      if (
        constantType === EOriginDataType.DOUBLE ||
        constantType === EOriginDataType.BIGINT
      ) {
        const { val: constantValue } = val;
        const tempConToken = constructToken({
          ...tempPosToken,
          text: constantValue,
          type: ETokenType.NUMBER,
          value: constantValue,
        });
        return [
          tempConToken,
          ...getWsTokens(tempConToken, pre[0][0]),
          ...pre[0],
        ];
      }
      if (constantType === EOriginDataType.VARCHAR) {
        const { val: constantValue } = val;
        const tempConToken = constructToken({
          ...tempPosToken,
          text: `"${processingConstantEscapeCharacter(constantValue)}"`,
          type: ETokenType.STRING,
          value: constantValue,
        });
        return [
          tempConToken,
          ...getWsTokens(tempConToken, pre[0][0]),
          ...pre[0],
        ];
      }
      if (constantType === EOriginDataType.BOOLEAN) {
        const { val: constantValue } = val;
        const tempConToken = constructToken({
          ...tempPosToken,
          text: constantValue.toString(),
          type: ETokenType.TRUE,
          value: constantValue.toString(),
        });
        return [
          tempConToken,
          ...getWsTokens(tempConToken, pre[0][0]),
          ...pre[0],
        ];
      }

      const tempConToken = constructToken({
        ...tempPosToken,
        text: 'NULL',
        type: ETokenType.DEFAULT,
        value: 'NULL',
      });
      return [tempConToken, ...getWsTokens(tempConToken, pre[0][0]), ...pre[0]];
    }
    if (astItemType === EFormulaType.UNARY_OPERATOR) {
      const [x] = pre;
      const { op } = astItem;
      const position = astItem.position || [defaultPosition];
      const token = constructToken({
        line: position[0].line,
        col: position[0].start - 1,
        text: op,
        type: ETokenType.SUBTRACTION,
        value: op,
      });

      return [token, ...getWsTokens(token, x[0]), ...x];
    }
    return [];
  });

  // 处理第一个token的位置

  return [...getWsTokens(firstEmptyToken, tempToken[0]), ...tempToken];
};

export const genTokensInList = (ast: TFormula, list: IListItem[]): IToken[] => {
  const { type } = ast;

  if (type === EFormulaType.PARTIAL) {
    const { text, errorMsg } = ast;

    return getTokens(text)
      .map((token) => {
        if (token.type === ETokenType.COLUMN) {
          let result: string = token.text;
          // 对于解析出来的字段，先查找一下是否是id的，如果是id的则替换为展示名
          const item = list.find((_item) => _item.id === token.value[0]);
          if (item) {
            result = item.displayName;

            return {
              ...token,
              text: `[${result}]`,
            };
          }
        }
        return token;
      })
      .map((token, index, arr) => {
        const preTokenListTextLength = getTokenListTextLength(
          arr.slice(0, index),
        );
        return {
          ...token,
          col: preTokenListTextLength,
          offset: preTokenListTextLength,
          end: preTokenListTextLength + token.text.length,
          error: errorMsg
            ? {
                errorMsg,
              }
            : undefined,
        };
      });
  }

  if (type === EFormulaType.EMPTY) {
    return [];
  }

  const tempToken = iterate(ast, [], (astItem, pre: Array<IToken[]>) => {
    const { type: astItemType } = astItem;
    if (astItemType === EFormulaType.BINARY_OPERATOR) {
      const { op, errorMsg } = astItem;
      const position = astItem.position || [defaultPosition];
      const [x, y] = pre;

      const opToken = constructToken({
        col: position[0].start - 1,
        line: position[0].line,
        text: op,
        type: (op as string).toLowerCase() as unknown as ETokenType,
        value: [op],
        errorMsg,
      });

      return [
        ...x,
        ...getWsTokens(_.last(x)!, opToken),
        opToken,
        ...getWsTokens(opToken, y[0]),
        ...y,
      ];
    }
    if (astItemType === EFormulaType.FUNCTION) {
      const { op, errorMsg, position } = astItem;

      const [funcPos, leftBracketsPos, rightBracketsPos] = position || [
        defaultPosition,
        defaultPosition,
        defaultPosition,
      ];

      const funcToken = constructToken({
        col: funcPos.start - 1,
        line: funcPos.line,
        text: op,
        type: ETokenType.FUNCTION_ID,
        value: [op],
        errorMsg,
      });

      const leftToken = constructToken({
        col: leftBracketsPos.start - 1,
        line: leftBracketsPos.line,
        text: '(',
        type: ETokenType.LEFT_BRACKETS,
        value: '(',
      });

      const rightToken = constructToken({
        col: rightBracketsPos.start - 1,
        line: rightBracketsPos.line,
        text: ')',
        type: ETokenType.RIGHT_BRACKETS,
        value: ')',
      });

      return [
        funcToken,
        leftToken,
        ...getWsTokens(leftToken, pre[0]?.[0]),
        ...(pre
          .map((tokenList, index) => {
            const commaToken = constructToken({
              col:
                _.last(tokenList)!.col +
                getTokenTextLength(_.last(tokenList)!) +
                1,
              text: ',',
              type: ETokenType.COMMA,
              value: ',',
              line: _.last(tokenList)!?.line,
            });

            return index !== pre.length - 1
              ? [
                  ...tokenList,
                  commaToken,
                  ...getWsTokens(commaToken, pre[index + 1]?.[0]),
                ]
              : tokenList.map((token) => ({
                  ...token,
                }));
          })
          .flat(Infinity) as IToken[]),
        ...getWsTokens(_.last(_.last(pre))!, rightToken),
        rightToken,
      ];
    }
    if (astItemType === EFormulaType.PARENTHESIS) {
      const [x] = pre;
      const [leftPos, rightPos] = astItem.position || [
        defaultPosition,
        defaultPosition,
      ];

      const leftToken = constructToken({
        col: leftPos.start - 1,
        line: leftPos.line,
        text: '(',
        type: ETokenType.LEFT_BRACKETS,
        value: '(',
      });
      const rightToken = constructToken({
        col: rightPos.start - 1,
        line: rightPos.line,
        text: ')',
        type: ETokenType.RIGHT_BRACKETS,
        value: ')',
      });
      return [
        leftToken,
        ...getWsTokens(leftToken, x[0]),
        ...x,
        ...getWsTokens(_.last(x)!, rightToken),
        rightToken,
      ];
    }
    if (astItem.type === EFormulaType.COLUMN) {
      const [x] = pre;
      const { path, errorMsg } = astItem;
      const position = astItem.position || [defaultPosition];
      const entity = findEntityInList(path, list);
      const tempPosToken = {
        col: position[0].start - 1,
        line: position[0].line,
      } as IToken;
      if (entity.type === EFindEntityResultType.ERROR) {
        if (path.length === 1) {
          return [
            constructToken({
              ...tempPosToken,
              text: `[${path[0]}]`,
              type: ETokenType.COLUMN,
              value: [path[0]],
              idPath: path,
              errorMsg,
            }),
          ];
        }
        return [
          constructToken({
            ...tempPosToken,
            text: `[${path[0]}/${path[path.length - 1]}]`,
            type: ETokenType.COLUMN,
            value: [path[0], path[path.length - 1]],
            idPath: path,
            errorMsg,
          }),
        ];
      }

      if (path.length === 1) {
        const tempColumnToken = constructToken({
          ...tempPosToken,
          text: `[${processingEscapeCharacter(
            (entity.result as IListItem).displayName,
          )}]`,
          type: ETokenType.COLUMN,
          value: [entity.result.displayName],
          idPath: path,
          errorMsg,
        });
        return [tempColumnToken, ...getWsTokens(tempPosToken, x[0]), ...x];
      }

      return [
        constructToken({
          ...tempPosToken,
          text: `[${path[path.length - 1]}]`,
          type: ETokenType.COLUMN,
          value: [path[0], path[path.length - 1]],
          idPath: path,
          errorMsg,
        }),
        ...getWsTokens(tempPosToken, x[0]),
        ...x,
      ];
    }
    if (astItemType === EFormulaType.CONSTANT) {
      const { val } = astItem;
      const position = astItem.position || [defaultPosition];
      const tempPosToken = {
        col: position[0].start - 1,
        line: position[0].line,
      } as IToken;

      const { type: constantType } = val;
      if (
        constantType === EOriginDataType.DOUBLE ||
        constantType === EOriginDataType.BIGINT
      ) {
        const { val: constantValue } = val;
        return [
          constructToken({
            ...tempPosToken,
            text: constantValue,
            type: ETokenType.NUMBER,
            value: constantValue,
          }),
          ...getWsTokens(tempPosToken, pre[0][0]),
          ...pre[0],
        ];
      }
      if (constantType === EOriginDataType.VARCHAR) {
        const { val: constantValue } = val;
        return [
          constructToken({
            ...tempPosToken,
            text: `"${processingConstantEscapeCharacter(constantValue)}"`,
            type: ETokenType.STRING,
            value: constantValue,
          }),
          ...getWsTokens(tempPosToken, pre[0][0]),
          ...pre[0],
        ];
      }
      if (constantType === EOriginDataType.BOOLEAN) {
        const { val: constantValue } = val;
        return [
          constructToken({
            ...tempPosToken,
            text: constantValue.toString(),
            type: ETokenType.TRUE,
            value: constantValue.toString(),
          }),
          ...getWsTokens(tempPosToken, pre[0][0]),
          ...pre[0],
        ];
      }
      return [
        constructToken({
          ...tempPosToken,
          text: 'NULL',
          type: ETokenType.DEFAULT,
          value: 'NULL',
        }),
        ...getWsTokens(tempPosToken, pre[0][0]),
        ...pre[0],
      ];
    }
    if (astItemType === EFormulaType.UNARY_OPERATOR) {
      const [x] = pre;
      const { op } = astItem;
      const position = astItem.position || [defaultPosition];
      const token = constructToken({
        line: position[0].line,
        col: position[0].start - 1,
        text: op,
        type: ETokenType.SUBTRACTION,
        value: op,
      });

      return [token, ...getWsTokens(token, x[0]), ...x];
    }
    return [];
  });

  return [...getWsTokens(firstEmptyToken, tempToken[0]), ...tempToken];
};

export default genFormula;

export function genFormulaAdapter(
  ast: TFormula | null,
  externalInfo: TExternalInfo,
): IToken[] {
  if (!ast) return [];
  if (externalInfo.type === ECodeEditorPropsType.DATASET) {
    return genTokensInDataset(
      ast,
      externalInfo.dataset,
      externalInfo.one2manyDatasets,
      externalInfo.many2oneDatasets || [],
    );
  } else if (externalInfo.type === ECodeEditorPropsType.COMPOSE_METRIC) {
    return genTokensInList(ast, [
      ...externalInfo.list,
      ...externalInfo.dimensionList,
    ]);
  } else {
    return genTokensInList(ast, externalInfo.list);
  }
}

export function genFormula2Dsl(
  ast: TFormula | null,
  externalInfo: TExternalInfo,
): string {
  return tokenList2Dsl(genFormulaAdapter(ast, externalInfo));
}

export function processingEscapeCharacter(str: string): string {
  return str
    .replace(/\\/g, '\\\\')
    .replace(/\]/g, '\\]')
    .replace(/\[/g, '\\[')
    .replace(/\//g, '\\/')
    .replace(/\"/g, '\\"');
}

export function processingConstantEscapeCharacter(str: string): string {
  return str.replace(/\\/g, '\\\\').replace(/\"/g, '\\"');
}

function getArray<T>(length: number, callback: (index: number) => T): T[] {
  return Array.from({ length })
    .fill(0)
    .map((v, index) => callback(index));
}

const getTokenTextLength = (token: IToken) => {
  const tempToken = _.cloneDeep(token);

  if (tempToken.type === ETokenType.COLUMN) {
    tempToken.text = `[${tempToken.idPath?.join('/') || tempToken.text}]`;
  }

  return tempToken.text.length;
};

const getWsTokens = (firstToken?: IToken, secondToken?: IToken) => {
  if (!firstToken || !secondToken) return [];

  const tempFirstToken = _.cloneDeep(firstToken);

  if (tempFirstToken.type === ETokenType.COLUMN) {
    tempFirstToken.text = `[${
      tempFirstToken.idPath?.join('/') || tempFirstToken.text
    }]`;
  }

  const wsTokenList: IToken[] = [];

  if (
    tempFirstToken.line === secondToken.line &&
    tempFirstToken.col + tempFirstToken.text.length < secondToken.col
  ) {
    wsTokenList.push(
      ...getArray(
        secondToken.col - tempFirstToken.col - tempFirstToken.text.length,
        (index) =>
          constructToken({
            col: tempFirstToken.col + index + 1,
            text: ' ',
            type: ETokenType.WS,
            value: ' ',
            line: tempFirstToken.line,
          }),
      ),
    );
  } else if (secondToken.line > tempFirstToken.line) {
    wsTokenList.push(
      ...getArray(secondToken.line - tempFirstToken.line, (index) =>
        constructToken({
          col:
            index === 0
              ? tempFirstToken.col + tempFirstToken.text.length + index + 1
              : 0,
          text: '\n',
          type: ETokenType.WS,
          value: '\n',
          line: tempFirstToken.line + index,
        }),
      ),
      ...getArray(secondToken.col, (index) =>
        constructToken({
          col: index + 1,
          text: ' ',
          type: ETokenType.WS,
          value: ' ',
          line: secondToken.line,
        }),
      ),
    );
  }

  // 如果是二元操作符并且没有position信息的时候，默认填充一个空格
  if (
    (isOperateToken(firstToken.type) || isOperateToken(secondToken.type)) &&
    firstToken.col === 0 &&
    secondToken.col === 0 &&
    wsTokenList.length === 0
  ) {
    wsTokenList.push(
      constructToken({
        col: firstToken.col + firstToken.text.length,
        text: ' ',
        type: ETokenType.WS,
        value: ' ',
        line: firstToken.line,
      }),
    );
  }

  return wsTokenList;
};

export function isOperateToken(type: ETokenType) {
  return [
    ETokenType.ADDITION,
    ETokenType.SUBTRACTION,
    ETokenType.MULTIPLICATION,
    ETokenType.DIVISION,
    ETokenType.GREATER_THAN,
    ETokenType.LESS_THAN,
    ETokenType.AND,
    ETokenType.OR,
    ETokenType.WITH,
    ETokenType.EQUAL,
    ETokenType.OR_KEYWORD,
  ].includes(type);
}
