export interface IMetricCatalogFiltersConfig {
  // 开启筛选的属性名称列表
  propertyNames: Array<{
    type: EMetricCatalogFiltersConfigType;
    name: string;
  }>;
}

export enum EMetricCatalogFiltersConfigType {
  // 系统属性，如指标类型
  SYSTEM = 'SYSTEM',
  // 指标属性
  PROPERTY = 'PROPERTY',
}
