import { graphql } from 'msw';
import { getMockQueryRecordDetail, getMockQueryRecordList } from './mockData';

export const queryRecord = [
  graphql.query('queryRecordList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRecordList: getMockQueryRecordList(),
      }),
      ctx.delay(500),
    );
  }),
  graphql.query('queryRecordDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRecordDetail: getMockQueryRecordDetail(req.variables.queryId),
      }),
      ctx.delay(500),
    );
  }),
];
