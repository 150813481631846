export const ownerFilter = {
  owner: 'Owner',
  myOwner: 'My Ownership',
  placeholder: 'Select',
};

export const metricPicker = {
  favFolder: 'My Favorites',
  allFolder: 'All Metrics',
  add: 'Add',
  metric: 'Metric',
  notFoundException: 'This metric is no longer available',
};

export const processedDimensionCard = {
  referenceDimension: 'Source Dimension:',
  code: 'Dimension Code:',
  dimName: 'Dimension Name:',
  description: 'Dimension Description:',
  showAll: 'Show All',
  hideAll: 'Collapse',
};

export const processedMetricCard = {
  referenceMetric: 'Source Metric:',
  code: 'Metric Code:',
  metricName: 'Metric Name:',
  description: 'Metric Business Caliber:',
  dateLimit: 'Time Constraint:',
  samePeriod: 'Quick Derivation:',
  filter: 'Business Filter:',
  showAll: 'Show All',
  hideAll: 'Collapse',
};

export const selectWithSearch = {
  selectColumn: 'Select Field',
  selectMetric: 'Select Metric',
  selectDate: 'Select Date Identifier',
};

export const dimensionPicker = {
  dimension: 'Dimension',
  allFolder: 'All Dimensions',
  add: 'Add',
  placeholder: 'Select',
};

export const datasetPicker = {
  dataset: 'Dataset',
  allFolder: 'All Datasets',
  placeholder: 'Select',
  datasetName: 'Dataset Name:',
  des: 'Description:',
};

export const analysisViewPicker = {
  allFolder: 'All Views',
  analysisViewName: 'View Name:',
  des: 'Description:',
};

export const sourceTablePicker = {
  placeholder: 'Select',
};

export const codeEditorWrapper = {
  column: 'Fields',
  metric: 'Metric',
  dimension: 'Dimension',
  function: 'Function',
};

export const deleteResourceModal = {
  title: ({ type }: { type: string | number }) => `Delete ${type}`,
  delete: 'Delete',
  cancel: 'Cancel',
  type: {
    metric: 'Metric',
    dataset: 'Dataset',
    dimension: 'Dimension',
    analysisView: 'View',
    physicTable: 'Physical Table',
    file: 'File',
  },
  desc: ({ type }: { type: string | number }) =>
    `This ${type} is referenced by the following assets. Deletion will affect their usage. Confirm deletion?`,
  desc1: ({ type }: { type: string | number }) =>
    `Deleting this ${type} cannot be undone. Confirm deletion?`,
  showMore: 'Expand All',
  putAway: 'Collapse',
};

export const traceInfo = {
  copy: 'Copy Error Message',
  copySuccess: 'Error Message Copied',
};

export const metricTreeEditor = {
  placeholder: 'Enter [ to insert metric',
};

export const formatMenuItem = {
  auto: 'Auto',
  quickOperation: {
    int: 'Integer',
    oneDecimalPlace: '1 Decimal Place',
    twoDecimalPlace: '2 Decimal Places',
    percentage: {
      default: 'Percentage',
      oneDecimalPlace: 'Percentage 1 Decimal',
      twoDecimalPlace: 'Percentage 2 Decimals',
      custom: 'Custom',
    },
  },
};

export const customFormatModal = {
  title: 'Custom Format Settings',
  type: {
    default: 'Type',
    auto: 'Auto',
    number: 'Number',
    percentage: 'Percentage',
    currency: 'Currency',
  },
  decimal: {
    default: 'Decimal Places',
    none: 'None (0 places)',
    decimal: ({ number }: { number: string | number }) => `${number} places`,
  },
  unit: {
    none: 'None',
    default: 'Data Scale',
  },
  negative: 'Negative',
  thousand: {
    default: 'Thousand Separator',
    open: 'Enable',
  },
  currency: {
    default: 'Currency Symbol',
    necessary: 'Currency symbol cannot be empty',
    rmb: 'CNY',
    dollar: 'USD',
    pound: 'GBP',
    custom: 'Custom',
  },
  format: {
    default: 'Display Format',
    auto: {
      default: 'Adaptation Mode',
      ch: 'Auto Adapt (Chinese)',
      en: 'Auto Adapt (English)',
    },
  },
};

export const versionTag = {
  unPublished: 'Unpublished',
  published: 'Published',
  draft: 'Saved Draft',
  offLine: 'Offline',
  publishing: 'Publishing',
  offLining: 'Taking Offline',
  toBeDeleted: 'To Be Deleted',
};

export const listTable = {
  checkedColumns: 'Show Fields',
  unCheckedColumns: 'Hidden Fields',
  inputPlaceholder: 'Search',
};

export const cronEditor = {
  custom: 'Custom Expression',
  minute: 'Minute',
  hour: 'Hour',
  select: 'Select',
  week: '',
  day: 'Day',
  hourly: 'Hourly',
  minute_1: 'Every Minute',
  minute_5: 'Every 5 Minutes',
  minute_10: 'Every 10 Minutes',
  minute_15: 'Every 15 Minutes',
  minute_30: 'Every 30 Minutes',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  minuteDisplay: 'Update Every Minute',
  minute_5Display: 'Update Every 5 Minutes',
  minute_10Display: 'Update Every 10 Minutes',
  minute_15Display: 'Update Every 15 Minutes',
  minute_30Display: 'Update Every 30 Minutes',
  getHourDisplay: (hour: string) => `Update at Minute ${hour} Every Hour`,
  getDailyDisplay: (hour: string, minute: string) =>
    `Update at ${hour}:${minute} Every Day`,
  getWeeklyDisplay: (dayOfWeek: string, hour: string, minute: string) =>
    `Update at ${hour}:${minute} Every ${dayOfWeek}`,
  getMonthlyDisplay: (day: string, hour: string, minute: string) =>
    `Update at ${hour}:${minute} on Day ${day} Every Month`,
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

export const logic = {
  btn: {
    addCondition: 'Add Condition',
    addRelation: 'Add Relation',
  },
  select: {
    and: 'AND',
    or: 'OR',
  },
};

export const filter = {
  operator: {
    common: {
      isNull: 'Is Null',
      notNull: 'Not Null',
      rank: {
        parent: 'Rank Value',
        rank: 'Loose Rank',
        denseRank: 'Dense Rank',
        rankRowNumber: 'Row Number',
      },
    },
    date: {
      specific: 'Specific Time',
      range: 'Time Range',
      outOfRange: 'Out of Range',
      earlier: 'Earlier Than',
      later: 'Later Than',
      matchMetric: 'Metric Value Matches Filter',
    },
    text: {
      equal: 'Equals',
      notEqual: 'Not Equal',
      include: 'Contains',
      exclude: 'Not Contain',
      startWith: 'Start With',
      notStartWith: 'Not Start With',
      endWith: 'End With',
      notEndWith: 'Not End With',
      matchMetric: 'Metric Value Matches Filter',
    },
    number: {
      equal: 'Equals',
      notEqual: 'Not Equal',
      greater: 'Greater Than',
      greaterEqual: 'Greater Than or Equal',
      less: 'Less Than',
      lessEqual: 'Less Than or Equal',
      between: 'Between',
      outOfRange: 'Out of Range',
      matchMetric: 'Metric Value Matches Filter',
    },
    boolean: {
      equal: 'Equals',
      exclude: 'Exclude',
      notEqual: 'Not Equal',
    },
    json: {
      extract: 'Extract',
      keyPlaceholder: 'Enter key',
      keyInfo: 'key path, use . to represent multiple layers',
      valuePlaceholder: 'Enter value',
      operator: {
        equal: 'Equal',
        notEqual: 'Not Equal',
        include: 'Include',
        notInclude: 'Not Include',
        startWith: 'Start With',
        notStartWith: 'Not Start With',
        endWith: 'End With',
        notEndWith: 'Not End With',
        isNull: 'Is Null',
        notNull: 'Not Null',
      },
    },
  },
  condition: {
    textPlaceholder: 'Enter',
    pickerPlaceholder: 'Select',
  },
  customTimePicker: {
    title: 'Custom',
    calendarDay: 'Calendar Day',
    recent: 'Recent...',
    td: 'From... to Now',
    beginEnd: 'Period Start/End',
    singleDate: 'Single Date',
    range: 'Date Range',
    beforeAfter: 'Before/After',
    granularity: 'Time Granularity',
    fromTo: (from: string, to: string) => `${from} to ${to}`,
    singleDatePicker: {
      date: 'Date',
      relative: 'Relative Time',
      fixed: 'Exact Time',
      current: (dateTime: string, granularity: string) =>
        `Current ${granularity} of ${dateTime}`,
    },
    rangeConfig: {
      start: 'Start',
      end: 'End',
    },
    recentConfig: {
      before: 'Past',
      after: 'Future',
      toCurrent: (granularity: string) => `to Current ${granularity}`,
    },
    beginEndConfig: {
      current: 'Current',
      decrease: 'Decrease',
      firstDate: (granularity: string) => `First ${granularity}`,
      lastDate: (granularity: string) => `Last ${granularity}`,
      first: 'First',
      last: 'Last',
      offsetValue: (unit: string, direction: string, dateTag: string) =>
        `${direction} ${dateTag} in ${unit}`,
    },
    mtd: {
      current: 'Current',
      decrease: 'Decrease',
      postfix: (unit: string, granularity: string) =>
        `${unit} Start to Current ${granularity}`,
    },
    validate: {
      maxLessThanMin: 'End time cannot be earlier than start time',
      dateTagNotFound:
        'Selected date identifier does not exist, please reselect',
    },
  },
  reservationPicker: {
    text: 'Keep Only',
  },
  metricTimePicker: {
    options: {
      today: 'Today',
      yesterday: 'Yesterday',
      last30Days: 'Last 30 Days',
      last180Days: 'Last 180 Days',
      last365Days: 'Last 365 Days',
      mtd: 'Month to Date',
      qtd: 'Quarter to Date',
      ytd: 'Year to Date',
      thisWeek: 'This Week',
      thisMonth: 'This Month',
      thisQuarter: 'This Quarter',
      thisYear: 'This Year',
      last3Years: 'Last 3 Years',
      custom: 'Custom',
    },
  },
  baseTimePicker: {
    custom: 'Custom',
    title: 'Base Date',
    checkbox: 'Base Date is',
    previous: 'Last',
    previousDay: 'Yester',
    empty: 'None',
  },
  modalCondition: {
    title: 'Filter',
    selectedDimension: 'Selected Dimension',
    granularity: 'Time Granularity',
    filterCondition: 'Filter Condition',
  },
};

export const downloadData = {
  download: 'Download',
  tip: 'Download first 100,000 rows of data results',
};

export const nav = {
  home: 'Home',
  metricCatalog: 'Catalog',
  queryRecord: 'Query Record',
  metricApplication: {
    title: 'Application',
    nav: {
      metricBoard: 'Dashboards',
      metricView: 'Views',
      metricMonitor: 'Monitors',
      APIIntegrated: 'API',
      metricTree: 'Metric Tree',
    },
  },
  metricDefine: {
    title: 'Definition',
    nav: {
      dataset: 'Datasets',
      dimension: 'Dimensions',
      metric: 'Metrics',
      datasource: 'Sources',
      timeLimit: 'Time Constraint',
    },
  },
  accelerate: {
    title: 'Acceleration',
    nav: {
      plan: 'ACC Plan',
      mtTable: 'ACC Table',
      manualPlan: 'ACC Task',
    },
  },
  managementSetUp: {
    title: 'Setting',
    nav: {
      userRole: {
        title: 'User Roles',
        nav: {
          user: 'Users',
          userGroup: 'User Groups',
          userAttribute: 'User Attributes',
        },
      },
      metricStandardize: {
        title: 'Metric Standards',
        nav: {
          metricAttribute: 'Metric Attributes',
          dimensionAttribute: 'Dimension Attributes',
          metricCategory: 'Metric Categories',
          dimensionCategory: 'Dimension Categories',
          datasetCategory: 'Dataset Categories',
          analysisViewCategory: 'View Categories',
          resultPlanCategory: 'Cache Plan Categories',
          customCalendar: 'Custom Calendar',
        },
      },
      enterpriseSecurity: {
        title: 'Security',
        nav: {
          centralizedAuthority: 'Centralized Authorization',
          dataRowPermission: 'Row/Column Permissions',
          authRule: 'Permission Rules',
          approvalManagement: 'Approval Management',
          authorizationManagement: 'Authorization Management',
        },
      },
      platformSettings: 'Platform Settings',
    },
  },
  approval: 'Approval Center',
};

export const resourceType = {
  metric: 'Metrics',
  dataset: 'Datasets',
  dimension: 'Dimensions',
  analysisView: 'Views',
  datasource: 'Data Sources',
  workbook: 'Dashboards',
  tenant: 'Tenant',
  view: 'View',
  file: 'File',
  userGroup: 'User Group',
  userProperty: 'User Attributes',
  category: 'Category',
  rpTask: 'ACC Plan',
  categoryMetric: 'Category Metric',
  categoryDimension: 'Category Dimension',
  dimensionProperty: 'Dimension Attributes',
  categoryDataset: 'Category Dataset',
  categoryResultPlan: 'Category Cache Plan',
  categoryAnalysisView: 'Category Metrics View',
  metricProperty: 'Metric Attributes',
  metricMonitor: 'Monitor',
  metricAccelerate: 'Metric Acceleration',
  resultPlan: 'Cache Plan',
  metricTree: 'Metric Tree',
};

export const transferModal = {
  success: 'Transfer Successful',
  title: (resource: string, isMultiple = false) => {
    return `${isMultiple ? 'Batch ' : ''}Transfer ${resource}`;
  },
  label: {
    transferResource: (resource: string) => `Transfer ${resource}`,
    currentOwner: 'Current Owner',
    transferTo: 'Transfer To',
  },
  okText: 'Transfer',
};

export const categoryHandlerBtn = {
  create: (resource: string) => `Create ${resource}`,
  rename: 'Rename',
  adjustCategory: 'Adjust Category',
  authManage: 'Permission Management',
  delete: 'Delete',
};

export const grantRoleModal = {
  visibleRange: {
    resource: {
      dataset: 'Dataset',
      metric: 'Metric',
      resultPlan: 'Cache Acc',
    },
    title: 'Visible Range',
    visibleToAll: 'Visible to All',
    visibleToSpecifiedUsers: 'Visible to Specified Users',
    selectVisibleRange: 'Visible Range',
    visibleToAllDescription: (type: string, isCategory: boolean) => {
      return isCategory ? `All users can see` : `All users can see`;
    },
    selectUsers: 'Select Users',
    totalNum: ({ num }: { num: number }) => `Total ${num}`,
    visibleToSpecifiedUsersDescription: 'Only specified users can see ',
    memberPickerPlaceholder: 'Select Users/User Groups',
  },
  grantRoleButton: {
    title: 'Select Members',
    text: 'Grant Permission',
    auth: 'Permission:',
    cancel: 'Cancel',
    ok: 'Confirm',
  },
};

export const moveCategoryModal = {
  title: 'Move Category',
  tip: 'Moving categories may result in changes to related permissions, please be aware.',
};

export const authorityList = {
  owner: 'Owner',
  authority: 'Permission',
};

export const categoryTree = {
  noAuthority: 'No Permission',
};

export const dataRowPermission = {
  refresh: 'Refresh',
  title: 'Row Permission',
  columnTitle: 'Column Permission',
  addRule: 'Add Rule',
  ruleNum: ({ num }: { num: number }) => `${num} Rules in Total`,
  ruleName: 'Rule Name',
  ruleDescription: 'Rule Description',
  creator: 'Creator',
  operation: 'Actions',
  noData: 'No permission rules currently, please add new rules.',
  edit: 'Edit',
  delete: 'Delete',
  deleteSuccess: 'Successfully Deleted',
  modal: {
    saveSuccess: 'Successfully Saved',
    editTitle: 'Edit Row Rule Permission',
    title: 'New Row Rule Permission',
    columnTitle: 'New Column Rule Permission',
    columnEditTitle: 'Edit Column Rule Permission',
    cancel: 'Cancel',
    confirm: 'Save',
    form: {
      baseInfo: 'Basic Information',
      ruleName: 'Name',
      ruleDescription: 'Description',
      tip: 'Only applicable members can use specified dimensions for analysis, other users cannot use this dimension.',
      availableDimensions: 'Available Dimensions',
      availableRange: 'Range',
      effectiveMetric: {
        title: 'Effective Metrics',
        add: 'Add Effective Metrics',
        scope: 'Effective Scope',
        specify: 'Specify Metrics',
        all: 'All Metrics',
        showCode: 'Show Metric Code',
        type: 'Type',
        byCategories: 'By Categories',
        byMetricName: 'By Metric Name',
      },
      applicableMember: 'Users/Groups',
      applicableScope: 'Scope',
      specifyMember: 'Users/Groups',
      allMember: 'All Users',
      conditionRule: 'Rules',
      matchRule: 'Rules',
      fixedValue: 'Fixed Value/User Attributes',
      permissionTable: 'Permission Table',
      userIdentifier: 'User Identifier (Account)',
      necessary: (name: string) => `${name} cannot be empty`,
      ruleConfig: {
        defaultRuleName: 'Unnamed Rule',
        ruleConfigEmpty: 'Please configure rules',
        ruleConfigEmptyGroup: 'Please configure condition group',
        handler: {
          copy: 'Copy',
          delete: 'Delete',
        },
        ruleConfigType: {
          tip: {
            textAddon: 'Multiple conditions are',
            and: 'and',
            or: 'or',
            textSuffix: 'relationship, cumulative effect',
            searchPlaceholder: 'Please enter rule name',
          },
          label: {
            type: 'Type',
            matchRule: 'Match Rule',
          },
        },
        ruleGroupType: {
          and: 'satisfy all conditions below',
          or: 'satisfy any condition below',
        },
        addRuleGroup: 'Add Rule',
        addCondition: 'Add Condition Group',
      },
    },
  },
};

export const customCalendar = {
  total: 'Custom Calendars',
  title: 'Custom Calendar',
  columns: {
    displayName: 'displayName',
    name: 'name',
    granularity: 'Granularity',
  },
  modal: {
    title: 'Custom Calendar',
    baseInfo: 'Basic Information',
    rule: 'Calendar Rule',
    format: 'Format',
    subTitle: {
      weekMonthCycle: 'Week-Month Cycle',
      displayName: 'Display Name:',
      name: 'Name:',
      template: 'Template:',
      startDayOfWeek: 'Start Week:',
      startMonthOfYear: 'Start Month:',
      baseDate: 'Base Date:',
      extraWeek: 'Extra Week:',
      monthCycle: 'Month Cycle:',
      week: (name: string) => `${name} Week:`,
      month: (name: string) => `${name} Month:`,
      quarter: (name: string) => `${name} Quarter:`,
      year: (name: string) => `${name} Year:`,
    },
    baseDateValue: (month: string, lastDay: string) =>
      `Ending on the ${lastDay} of ${month}`,
    extraWeekBelongMonth: (month: string) =>
      `Overflowing weeks are divided into ${month}`,
    monthCycleValue: (month: string, dayOrder: string, dayIndex: string) =>
      `Starting with the ${dayOrder} of ${month} as the ${dayIndex} day of the month`,
    startMonthOfYearValue: (month: string) =>
      `Starting with ${month} as the first month`,
    map: {
      oneDayOfWeek: {
        lastSunday: 'Last Sunday',
        endMonthRecentSunday: 'End Month Recent Sunday',
      },
      monthCycle: {
        current: 'Current Month',
        last: 'Last Month',
      },
      order: {
        asc: 'Ascending',
        desc: 'Descending',
      },
      templateType: {
        wmqy: 'Week-Month-Quarter-Year',
        mqy: 'Month-Quarter-Year',
      },
      month: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
      },
      week: {
        one: 'Monday',
        two: 'Tuesday',
        three: 'Wednesday',
        four: 'Thursday',
        five: 'Friday',
        six: 'Saturday',
        seven: 'Sunday',
      },
      number: {
        one: 'one',
        two: 'two',
        three: 'three',
        four: 'four',
        five: 'five',
        six: 'six',
        seven: 'seven',
        eight: 'eight',
        nine: 'nine',
        ten: 'ten',
        eleven: 'eleven',
        twelve: 'twelve',
      },
    },
  },
};
export const resourceImpactModal = {
  title: 'Downstream Impact',
  deleteDesc:
    'Deleting these assets will affect downstream assets. Please evaluate carefully before proceeding. You can download the report for details.',
  editDesc:
    'Modifying these assets will affect downstream assets. Please evaluate carefully before proceeding. You can download the report for details.',
  okBtnText: 'Confirm Impact and Continue',
  deleteBtnText: 'Confirm Impact and Continue',
  filter: {
    error: (sum: number) => `Error Assets(${sum})`,
    change: (sum: number) => `Logic Changed Assets(${sum})`,
    all: 'All',
    metric: (sum: number) => `Metrics(${sum})`,
    dimension: (sum: number) => `Dimensions(${sum})`,
    monitor: (sum: number) => `Monitors(${sum})`,
    accelerate: (sum: number) => `Accelerations(${sum})`,
    view: (sum: number) => `Metrics Views(${sum})`,
  },
  download: {
    btn: 'Download Report',
    downloadMsg: 'Download Success',
    table: {
      type: {
        title: 'Impact Type',
        error: 'Asset Error',
        change: 'Asset Logic Change',
      },
      resourceType: 'Asset Type',
      displayName: 'Display Name',
      resourceId: 'Asset Name',
      owner: 'Owner',
      errorMsg: 'Error Message',
    },
  },
  deleteModal: {
    title: 'Delete',
    content: 'This action cannot be undone. Are you sure you want to delete?',
    okText: 'Delete',
  },
  tableColumn: {
    type: 'Type',
    name: 'Asset Name',
    owner: 'Owner',
    errorMsg: 'Error Message',
  },
};

export const globalFilter = {
  error: {
    configUncompleted: (displayName: string) =>
      `Filter ${displayName} is not configured`,
  },
  title: 'Filter Settings',
  left: {
    title: 'Filter',
    dropdown: {
      delete: 'Delete',
      rename: 'Rename',
      addDate: 'Date Filter',
      addSelect: 'Select Filter',
    },
  },
  right: {
    date: {
      label: {
        granularity: 'Time Granularity',
        dimension: 'Filter Dimension',
        method: 'Filter Method',
        defaultValue: 'Default Value',
      },
      method: {
        range: 'Range',
        specify: 'Specify',
      },
      metricTimeDisplayName: 'Metric Date',
    },
    select: {
      label: {
        dimension: 'Filter Dimension:',
      },
      method: {
        single: 'Single',
        multiple: 'Multiple',
      },
    },
  },
  emptyFilterDisplayName: 'Unnamed Filter',
  footer: {
    ok: 'Save',
  },
};

export const memberPickerModal = {
  title: 'Select Members',
  footer: {
    ok: 'Save',
    cancel: 'Cancel',
  },
};
