import { ESign, IIncrementRefreshScope } from '@/typings/dataSource';
export const materializedTable = {
  name: 'Name',
  materializedTable: 'ACC Table',
  latestData: 'Newest Data',
  lastUpdate: 'Last Updated',
  storage: 'Storage',
  materializedSolution: 'ACC Plan',
  lastInstanceStatus: 'Last Updated Status',
  operation: 'Actions',
  dataset: 'Dataset',
  reRun: 'Rerun',
  refresh: 'Refresh',
  setSuccess: 'Mark as Success',
  lengthOfMaterializedTable: (num: number) => `${num} Materialized Tables`,
  noMaterializedTable: 'No ACC table found',
  list: {
    owner: 'Owner',
    updateRange: 'Update Range',
  },
  detail: {
    code: 'Code',
    to: 'to',
    updateData: 'Refresh Data',
    summary: 'Overview',
    partition: 'Partitions',
    updateRecord: 'Jobs',
    updateDAG: 'DAG',
    updateTime: 'Last Updated',
    field: 'Fields',
    metricDimension: 'Metric/Dimension',
    datasetField: 'Dataset Field',
    dataRange: 'Data Range',
    instanceId: 'Instance ID',
    status: 'Status',
    updatePartition: 'Update Partitions',
    startTime: 'Start Time',
    endTime: 'End Time',
    duration: 'Duration',
    resourceWaitTime: 'Wait Time',
    dataUpdate: 'Trigger Refresh',
    manualRefresh: 'BackFill Refresh',
    detail: 'Details',
    terminate: 'Terminate',
    more: 'More',
    success: 'Success',
    fail: 'Failed',
    processing: 'Processing',
    init: 'Initializing',
    pending: 'Pending',
    terminated: 'Terminated',
    latestPartition: 'Newest Partition',
    basicProperty: 'Basic Properties',
    name: 'Name',
    type: 'Type',
    owner: 'Owner',
    dataUpdateTime: 'Last Updated',
    viewDetail: 'View Details',
    overview: 'Overview',
    operateSuccess: 'Operation Successfully',
  },
  updateModal: {
    desc: 'The system will incrementally update the ACC table data to the latest based on the data source table updates.',
    desc2: 'The system will fully update this acc table data',
    updateType: 'Method',
    onlyUpdateCurrentMaterializedTable: 'Refresh Current Table',
    updateCurrentAndDownstreamMaterializedTable:
      'Refresh Current and Downstream Tables',
    refreshContent: 'Tables',
    updateSuccess: 'Update Successfully',
  },
  dag: {
    detail: {
      showDetail: 'View Details',
      overview: 'Overview',
      setting: 'Settings',
    },
    current: 'Current Data',
    latestData: 'Newest Data',
  },
};
export const detail = {
  overview: {
    title: 'Overview',
    content: 'Acceleration Content',
    baseInfo: 'Basic Properties',
  },
  mt: {
    title: 'Materialized Table',
  },
  authority: {
    title: 'Authority',
  },
};

export const taskModal = {
  instanceDetail: 'Instance Details',
  preview: 'Overview',
  executeSql: 'Task SQL',
  taskLog: 'Task Log',
  basicProperty: 'Basic Properties',
  name: 'Instance ID',
  type: 'Type',
  copySuccess: 'Copy Successfully',
  copy: 'Copy',
};
export const editModal = {
  layout: {
    previous: 'Previous',
    next: 'Next',
    editPlan: 'Configure Plan',
    previewPlan: 'Preview Plan',
    cancel: 'Cancel',
    save: 'Save',
  },
  step1: {
    displayName: 'Display Name',
    name: 'Name',
    status: {
      new: 'New',
      unModified: 'Unmodified',
      modified: 'Modified',
    },
    displayNamePlaceholder: 'Enter display name',
    namePlaceholder: 'Enter name',
    dimension: 'Dimension',
    metric: 'Metric',
    field: 'Fields',
    metricPlan: 'Metric Acc',
    fieldPlan: 'Detail Acc',
    dataGranularity: (dimListStr: string) => `Data Granularity: ${dimListStr}`,
    nameTip:
      'This name will be used to create the ACC table and cannot be modified after creation.',
    comma: ', ',
    copy: 'Copy',
    remove: 'Remove',
    errors: {
      columnIsRequired: 'Select fields',
      currDsColumnIsRequired: 'Select at least one field from current dataset',
      partitionColumnIsRequired: (
        datasetDisplayName: string,
        columnDisplayNames: string[],
      ) =>
        `Select all required fields from current dataset [
${datasetDisplayName}]: ${columnDisplayNames.join(', ')}`,
      metricIsRequired: 'Select metrics',
      dimensionNotFound: 'Some dimensions are unavailable',
      metricNotFound: 'Some metrics are unavailable',
      columnNotFound: 'Some fields are unavailable',
      nameIsRequired: 'Enter name',
      nameFormatError:
        'Name can only contain letters, numbers, and underscores, and must start with a letter',
      duplicatedName: 'Name cannot be duplicated with other plans',
      displayNameIsRequired: 'Enter display name',
      duplicatedDisplayName:
        'Display name cannot be duplicated with other plans',
      aggPlansConflict:
        ' Plans have the same data granularity, please modify the plans.',
      rawPlansConflict:
        ' Plans have the same field selection, please modify the plans.',
    },
  },
  step2: {
    more: 'More',
    plan: 'Plan',
    recommendTab: 'Optimization',
    recommend: 'Recommendations',
    accepted: 'Accepted',
    denied: 'Denied',
    unHandled: 'Unhandled',
    accept: 'Accept',
    noRecommend: 'No optimization recommendations available',
    deny: 'Deny',
    unhandledError: 'Please handle optimization recommendations',
    waitForPreviewData: 'Preview data is still loading',
    renameModalTitle: 'Edit',
    fullRefresh: 'Full Refresh',
    incrementalRefresh: 'Incremental Refresh',
  },
};
export const plan = {
  batch: {
    transfer: (value: string) =>
      `Cache ACC ${value} has no transfer permission`,
    adjustCategory: (value: string) =>
      `Cache ACC ${value} has no adjust category permission`,
  },
  editCategory: 'Edit Category',
  transfer: 'Transfer',
  multidimensional: 'Metric/Detail ACC',
  deleteSuccess: 'Delete Successfully',
  deleteModal: {
    title: 'Delete Plan',
    desc: 'Are you sure you want to delete this plan? This action cannot be undone!',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  placeholder: 'Select',
  application: 'Cache ACC',
  title: 'ACC Plan',
  status: 'Status',
  create: 'New ACC Plan',
  datasetPickModal: {
    desc: 'Select a dataset to create ACC plans for its metrics.',
  },
  count: (count: number, isMultiDimension: boolean = true) =>
    `${count} ${isMultiDimension ? 'Metric/Detail Acc' : 'Cache Acc'}`,
  copy: 'Copy',
  disabledCopy: 'Editing is not supported for view results',
  makeUpData: 'Backfill',
  edit: 'Edit',
  delete: 'Delete',
  showMaterializedTable: (count: number) => `View ACC Tables(${count})`,
  type: {
    all: 'All',
    raw: 'Detail ACC',
    agg: 'Metric ACC',
  },
  lastRefreshTime: 'Newest Data ',
  refreshPlanModal: {
    title: 'Backfill Data',
    metric: 'Metric',
    range: 'Range',
    plan: 'Plan',
    type: {
      title: 'Method',
      upStream: 'Upstream Dependencies',
      directFromSourceTable: 'Directly from Source Table',
    },
    warning: (metricNames: string[]) =>
      `${metricNames
        .map((name) => `[${name}]`)
        .join(' ')} metrics will be fully refreshed`,
    rangeEmptyMsg: 'Backfill range cannot be empty',
    metricsEmptyMsg: 'Metrics cannot be empty',
    success: 'Submit Successfully',
    successDesc:
      'The backfill task has been submitted successfully. You can go to the backfill task details to view specific content, or directly close this dialog.',
    okText: 'View Backfill Task',
    cancelText: 'Got it',
    content: {
      title: 'Task',
      desc: 'The system will update the corresponding data content of the following ACC tables',
      fullRefresh: 'Full Refresh',
      tableColumns: {
        mtTable: 'ACC Tables',
      },
    },
  },
  owner: (nickname: string) => 'Owner: ' + nickname,
  refreshContent: (
    lowerIncrementRefreshScope: IIncrementRefreshScope,
    upperIncrementRefreshScope: IIncrementRefreshScope,
    granularityText: string,
  ) =>
    `Update data from ${
      lowerIncrementRefreshScope.interval === 0
        ? `current ${granularityText}`
        : `${lowerIncrementRefreshScope.interval}${granularityText}${
            lowerIncrementRefreshScope.sign === ESign.NEGATIVE
              ? ' ago'
              : ' later'
          }`
    } to ${
      upperIncrementRefreshScope.interval === 0
        ? `current ${granularityText}`
        : `${upperIncrementRefreshScope.interval}${granularityText}${
            upperIncrementRefreshScope.sign === ESign.NEGATIVE
              ? ' ago'
              : ' later'
          }`
    }`,
  refreshContentPrefix: 'Refresh Content: ',
  refreshContent1: (granularityText: string) =>
    ` Update data for current ${granularityText}`,
  refreshContent2: 'Full Refresh',
  refreshCyclePrefix: 'Refresh Trigger: ',
  refreshCycle: (isScheduleUpdate: boolean, cronString: string) =>
    `${isScheduleUpdate ? `${cronString}` : 'Dependency Triggered Update'}`,
  dataset: (displayname: string) => 'Dataset: ' + displayname,
  createTime: (time: string) => `Created At: ${time}`,
  updateTime: (time: string) => `Last Modified: ${time}`,
  tableColumns: {
    displayName: 'Display Name',
    name: 'Name',
    category: 'Category',
    createTime: 'Created At',
    updateTime: 'Last Modified',
    owner: 'Owner',
    actions: 'Actions',
  },
};
export const manualRefreshTask = {
  title: 'Backfill Task',
  count: (count: number) => `${count} Backfill Plans`,
  refresh: 'Refresh',
  tableColumns: {
    manualRefreshTask: 'Backfill Task',
    status: 'Status',
    statusText: {
      init: 'Initializing',
      fail: 'Backfill Exception',
      success: 'Success',
      running: 'Backfilling',
      notConfig: 'Not Configured',
      pending: 'Pending',
    },
    timeRange: 'Backfill Range',
    fullRefresh: 'Full Refresh',
    incrementalRefresh: 'Incremental Refresh',
    mtTask: 'ACC Plan',
    metric: 'Metric',
    emptyMetric: 'None',
    dataset: 'Dataset',
    operate: {
      title: 'Actions',
      detail: 'Details',
    },
  },
};
export const accelerateResult = {
  search: 'Acceleration Display Name, Name',
  errorTags: 'An error occurred',
  filter: {
    showDisplayCode: 'Show Metric/Dimension Code',
    showName: 'Show Metric/Dimension Name',
    metric: 'metric',
    dimension: 'dimension',
  },
  add: 'New Cache ACC',
  batchAdd: 'Batch New Cache ACC',
  editModal: {
    title: (isEdit: boolean) =>
      !isEdit ? 'New Result ACC' : 'Edit Result ACC',
    cancelBtn: 'Cancel',
    viewContentDesc: (displayName: string) =>
      `The accelerated content references metric view '${displayName}', editing is not supported`,
    saveBtn: 'Save',
    form: {
      emptyDimension:
        'No dimensions have been added to the current view. Please go to the view editor page to add dimensions.',
      emptyMetric:
        'No metrics have been added to the current view. Please go to the view editor page to add metrics.',
      jsonEditor: {
        template: ` "metrics":  /* Metrics to accelerate, remove this comment if configuring acceleration */
[ 
"IN00001044__period__dt15min",
"c_order_count_per_user2"
],
"dimensions":  /* Dimensions to accelerate, remove this comment if configuring acceleration */
[ 
"DM00000061",
"metric_time__15minute"
],
"metricDefinitions":  /* Temporary metric definitions, add defined metrics to metrics array, remove this comment if configuring acceleration */
{  
"c_order_count_per_user2": {
"refMetric": "dd_oc_nonadd_only_filters",
"filters": ["IN(['province'],\"Zhejiang\")",],
"indirections":["sameperiod__mom__growthvalue"]
}
},
"filters": /* Data filters for acceleration, remove this comment if configuring acceleration */ 
[   
"['DM00000061'] = \"81\""
],
"timeConstraint": /* Metric date filter for acceleration, generally not configured, remove this comment if configuring acceleration */ 
"[metric_time__day] = dateTrunc(now(),\"DAY\") and [metric_time__minute] < dateTrunc(now(),\"MINUTE\")"`,
        placeholder: `{
"metrics": [
....
],
"dimensions": [
....
]
}`,
      },
      title: {
        baseInfo: 'Basic Information',
        accelerateContent: 'Acceleration Content',
        refreshConfig: 'Refresh Settings',
        notify: 'Notification',
      },
      derived: {
        samePeriod: 'Quick Derivation',
        dateLimit: 'Time Constraint',
        filter: 'Business Filter',
      },
      saveSuccess: 'Save Successfully',
      label: {
        displayname: 'Display Name',
        format: 'Format',
        codeContentDesc:
          'Fill in metrics, metricDefinitions, dimensions, filters, timeConstraint from the metric query request here.',
        viewTemplate: 'View Example',
        viewTemplateModalTitle: 'Example',
        type: {
          normal: 'Normal Mode',
          code: 'Code Mode',
        },
        name: 'Name',
        category: 'Category',
        accelerateGroup: 'Acceleration Content',
        accelerateMetrics: 'Metrics',
        accelerateDimension: 'Dimensions',
        dimensionFilter: 'Filter',
        metricTimeFilter: 'Metric Time',
        refreshContent: ' Method',
        refreshCycle: 'Trigger',
        advancedSetting: 'Advanced',
        notifySuccess: 'Successful',
        notifyError: 'Failed',
        notifyDelay: 'Delayed',
        showCode: 'Show Metric/Dimension Code',
        showName: 'Show Metric/Dimension Name',
      },
      refreshContentText: {
        prefix: (text: string) => `Update T (${text})`,
        to: (text: string) => `${text} to T (${text})`,
        suffix: (text: string) => `data content of ${text}`,
        schedule: 'Scheduled',
        dependency: 'Dependency Triggered',
        desc: 'Schedule data acceleration refresh',
        fullRefresh: 'Full Refresh',
        incrementalRefresh: 'Incremental Refresh',
        incrementalDisabledDesc:
          'Only incremental refresh is supported when the metric date dimension is selected and the metric date filter is not enabled',
      },
      advancedSettingText: (show: boolean) => (show ? 'Collapse' : 'Expand'),
      advancedSetting: {
        failTactics: {
          label: 'Failure Strategy',
          retry: 'Retry',
          terminate: 'Terminate',
        },
        retryCount: 'Retry Count',
        interval: 'Retry Interval',
        intervalSuffix: 'minutes',
      },
      refreshDesc:
        'Metric cache acceleration automatically refresh data based on source table updates',
      pushChannel: {
        add: 'Add Channel',
        title: (index: number) => `Channel ${index}`,
      },
      filterDesc: 'Meet the following conditions',
      filterSwitch: {
        open: 'Open',
        close: 'Close',
      },
      notify: {
        finishTime: 'Planned Completion Time',
      },
      getPlaceholder: (text: string) => `Enter ${text}`,
      selectPlaceholder: 'Select',
      validate: {
        metricRepeat: (text: string) =>
          `Metric ${text} definition is duplicated, please check`,
        metricNotEmpty: 'Metrics cannot be empty',
        dimensionNotEmpty: 'Dimensions cannot be empty',
        pushChannelNotEmpty: 'Select a channel',
        refreshContentNotEmpty: 'Refresh content cannot be empty',
        refreshContentInterval: 'Left time should be less than right time',
        codeContent: 'Enter valid JSON',
        refreshContentCron: 'Invalid cron expression',
        filterDesc: 'Invalid formula',
      },
    },
  },
  refreshData: {
    title: 'Backfill Data',
    range: 'Backfill Range',
    successMsg: 'Backfill request initiated',
    tableColumn: {
      plan: 'Plan',
      range: 'Range',
      dateIntervalOfLot: 'Backfill Time Interval',
      rangeDesc: (
        startTime: string,
        endTime: string,
        granularityText: string,
      ) => `${granularityText} granularity ${startTime} to ${endTime}`,
      dateIntervalDesc1: 'Use',
      dateIntervalDesc2: (granularityText: string) =>
        `${granularityText} as an interval`,
      changeDateIntervalOfLot: 'Modify Interval',
      dateIntervalOfLotPlaceholder: 'Please enter interval',
    },
  },
  categoryRouter: {
    more: 'More',
    refresh: 'Refresh',
    createMore: 'Create',
    rename: 'Rename',
    delete: 'Delete',
    moveCategory: 'Move Category',
    authManage: 'Permission Management',
    all: (types: string) => `All ${types}`,
    search: 'Dataset Display Name, Name',
    category: 'Category',
    acceleratePlan: 'Cache Plan',
    createAcceleratePlan: 'Create Cache Plan',
    createSubCategory: 'Create Category',
  },
  batchEditModal: {
    operate: {
      copy: 'Copy',
      delete: 'Delete',
    },
    success: 'Create Successfully',
    title: (type: 'create' | 'edit') =>
      type === 'create' ? 'New Cache ACC' : 'Edit Cache ACC',
    cancel: 'Cancel',
    confirm: 'Confirm',
    selectedDesc1: 'Selected',
    selectedDesc2: 'items',
    cancelSelected: 'Cancel',
    delete: 'Delete',
  },
};
