export const request = {
  message: '请求失败',
};

export const component = {
  searchPlaceholder: '搜索',
};
export const document = {
  title: '指标平台',
};
export const dataTypeName = {
  int: '整数',
  double: '小数',
  decimal: '高精度小数',
  dateTime: '日期时间',
  date: '日期',
  text: '文本',
  boolean: '布尔',
  json: '结构化数据',
};

export const page = {
  backHome: '返回首页',
  noContent: '未找到相关内容...',
  noContentDesc:
    '抱歉，此文件不存在或您没有权限查看它。请检查链接地址是否正确，或联系内容所有者更新权限。',
  severError: '抱歉，出了一些问题',
  copySuccess: '复制成功',
  copyError: '复制错误信息',
  severErrorDesc: '请稍候尝试。',
};

export const list = {
  searchEmpty: '未找到内容',
  searchMetric: '搜索指标、业务口径',
  searchDimension: '搜索维度',
  searchCategory: '搜索类目名称',
};

export const granularity = {
  auto: '自动',
  year: '年',
  quarter: '季',
  month: '月',
  week: '周',
  day: '日',
  hour: '小时',
  minute: '分钟',
  second: '秒',
};

export const column = {
  metricTime: '指标日期',
};

export const button = {
  save: '保存',
  close: '关闭',
};

export const input = {
  placeholder: '请输入',
};

export const select = {
  all: '全部',
  placeholder: '请选择',
};

export const zhUnit = {
  tenThousand: '万',
  hundredMillion: '亿',
  million: '百万',
  tenMillion: '千万',
};

export const modal = {
  confirm: '确定',
  save: '确定',
};

export const metricPicker = {
  search: (enableCode?: boolean) =>
    `指标${enableCode ? '编码、' : ''}展示名、名称`,
  unAvailableTip: '自动隐藏不可分析内容',
  empty: {
    title: '未找到相关内容',
    desc: '没有符合条件的结果',
  },
  apply: '应用',
  collapseAll: '折叠全部',
  close: '关闭',
  pickMetric: '选择指标',
};

export const dimensionPicker = {
  apply: '应用',
  collapseAll: '折叠全部',
  close: '关闭',
  pickDimension: '选择维度',
};

export const platform = {
  enterpriseWechat: '企业微信',
  lark: '飞书',
  other: '其他',
};
