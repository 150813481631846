import { useAtom } from 'jotai';
import { derivedMetricDependencyAtom } from '../../atom';
import { TMetric } from '@/typings/metric';
import _ from 'lodash';
import { TDimension } from '@/typings/dimension';

export const useDerivedMetricDependency = () => {
  const [derivedMetricDependency, setDerivedMetricDependency] = useAtom(
    derivedMetricDependencyAtom,
  );

  const addMetricDependency = (metrics: TMetric[]) => {
    setDerivedMetricDependency((prev) => ({
      ...prev,
      metrics: _.uniqBy([...prev.metrics, ...metrics], 'code'),
    }));
  };

  const addDimensionDependency = (dimensions: TDimension[]) => {
    setDerivedMetricDependency((prev) => ({
      ...prev,
      dimensions: _.uniqBy([...prev.dimensions, ...dimensions], 'name'),
    }));
  };

  const setMetricDependency = (metrics: TMetric[]) => {
    setDerivedMetricDependency((prev) => ({
      ...prev,
      metrics,
    }));
  };

  const setDimensionDependency = (dimensions: TDimension[]) => {
    setDerivedMetricDependency((prev) => ({
      ...prev,
      dimensions,
    }));
  };

  return {
    derivedMetricDependency,
    addMetricDependency,
    addDimensionDependency,
    setMetricDependency,
    setDimensionDependency,
  };
};
