export const metricTreeDetailHeader = {
  globalConfiguration: '全局配置',
  preview: '预览',
  save: '保存草稿',
  back: '退出',
  publish: '发布',
  edit: '编辑',
  reset: '重置草稿为线上版本',
  saveSuccess: '保存成功',
  renameSuccess: '重命名成功',
  configuration: {
    node: '节点配置',
    global: '全局配置',
    filter: '筛选配置',
  },
  title: {
    unnamed: '未命名指标树',
  },
  btn: {
    share: '分享',
  },
  shareModal: {
    title: '分享',
    success: '分享成功',
  },
};
export const calculatingRelationship = {
  exit: '退出',
  save: '保存',
  attributionAnalysis: '归因分析设置',
  title: '指标树',
  desc: '点击指标树节点，配置该节点与子节点的计算关系与分析维度',
  detail: {
    title: '节点计算关系',
  },
  node: {
    unnamed: '未命名节点',
  },
};
export const globalConfiguration = {
  nodeList: {
    tab: '节点列表',
    update: '保存',
    reset: '重置',
    attributionConfigInvalidContent:
      '位置调整影响了现有的归因关系，是否继续保存？',
  },
  style: {
    tab: '样式',
    direction: '展开方向',
    vertical: '纵向',
    horizontal: '横向',
    defaultExpandLevel: '默认展开层级数',
    level: ({ level }: { level: number }) =>
      level < 0 ? '全部' : `${level}层`,
    color: '染色',
    redRiseGreenFall: '红涨绿跌',
    greenRiseRedFall: '绿涨红跌',
  },
  analysis: {
    tab: '分析',
    comparisonGranularity: (time: string) => `同比${time}`,
    to: '至',
    comparativeData: {
      title: '对比数据',
      description:
        '选择默认展示在指标卡上的对比数据，并基于对比数据进行归因计算。',
      switch: '显示对比按钮',
    },
    attributionAnalysis: {
      title: '归因分析',
      calculatingRelationshipConfiguration: '计算关系配置',
      description:
        '设置指标树中多个指标之间的计算关系，通过归因分析获得每个指标对主指标的贡献度。且需配置对比数据，归因才会生效。',
      tip: '需配置对比数据，归因才会生效。',
      btnText: '配置对比',
    },
  },
};
export const nodeConfiguration = {
  metric: '指标',
  analysisDimension: '分析维度',
  noMetric: '选择指标',
  noDimension: '选择维度用于多维分析',
  delete: '删除',
  rename: '重命名',
  targetManagement: '目标管理',
  targetPlaceholder: '请输入目标值',
};
export const metricTreeNode = {
  unnamed: '未命名节点',
  noMetric: '点击配置节点内容',
  noMetricQuery: '指标未查询',
  metricDisassembly: '指标拆解',
  dimensionDisassembly: '维度拆解',
  error: '指标查询异常',
  contribution: {
    title: '贡献度',
    metricChange: '指标变化',
    overall: (overall: string) => `整体贡献度：${overall}`,
    relative: (relative: string) => `相对贡献度：${relative}`,
    metricComparisonValue: (value: string) => `对比值：${value}`,
    metricCurrentValue: (value: string) => `当前值：${value}`,
  },
  delete: '删除',
  deleteConfirm: ({ name, num }: { name: string; num: number }) =>
    `您将删除「${name}」节点，此操作将一并删除其下的${num}个子节点， 删除后不可恢复。请确认是否继续删除？`,
  growthValue: '增长值',
  growth: '增长率',
  value: '值',
  custom: '自定义日期',
  targetManagement: {
    target: '目标',
    schedule: '进度',
  },
  attributionTitle: '归因分析',
};
export const metricTree = {
  copy: {
    success: '复制成功',
    error: '复制失败',
  },
  list: {
    batch: {
      transfer: (value: string) => {
        return `指标树 ${value} 无转交权限`;
      },
    },
    menu: {
      transfer: '转交',
      view: '查看',
      share: '分享',
      editBaseInfo: '修改基础信息',
      edit: '编辑',
      copy: '复制',
      delete: '删除',
      deleteConfirm: '确定',
      deleteConfirmContent: ({ name }: { name: string | number }) =>
        `是否删除指标树[${name}]`,
      deleteConfirmOk: '确定',
      deleteConfirmCancel: '取消',
    },
    deleteSuccess: '删除成功',
    name: '名称',
    unPublished: '未发布',
    published: '已发布',
    creator: '负责人',
    status: '状态',
    handle: '操作',
    lastModified: '最近修改时间',
    createTime: '创建时间',
    title: '指标树',
    create: '新建指标树',
    total: ({ total }: { total: string | number }) => `${total}个指标树`,
  },
  detail: {
    title: {
      unnamed: '未命名指标树',
    },
  },
  basicInfo: {
    title: '修改基础信息',
    name: '名称',
    description: '描述',
    saveSuccess: '保存成功',
    confirm: '保存',
    cancel: '取消',
    error: {
      name: '请输入名称',
    },
  },
  shareModal: {
    title: '分享',
    success: '分享成功',
  },
};

export const attribution = {
  error: {
    expr: (displayName: string) =>
      `指标 [${displayName}] 的公式不是合法的四则运算`,
    parentChain: (displayName: string) =>
      `指标 [${displayName}] 的祖先节点上必须都配置了公式`,
    metric: (displayName: string) => `节点 [${displayName}] 未配置指标`,
    onlyOneNodeInRelation: '请为根节点添加子节点',
    noExpr: '请配置公式',
    noComparisonType: '请选择对比方式',
    notInChildren: (currDisplayName: string, subDisplayName: string) =>
      `[${currDisplayName}] 公式中用到的 [${subDisplayName}] 不在其子节点中`,
  },
};

export const menuBar = {
  btns: {
    comparison: '对比',
    query: '查询',
  },
};

export const comparisonConfigModal = {
  title: '对比',
  subTitle: '对比日期',
  dod: '日环比',
  wow: '周同比',
  mom: '月同比',
  qoq: '季同比',
  yoy: '年同比',
  custom: '自定义日期',
  singleDate: '单日期',
  dateRange: '日期范围',
};

export const addNodesModal = {
  metric: '指标',
  dimension: '维度',
  dimensionValue: '维度值',
  dimensionRequired: '请选择维度',
  dimensionValueRequired: '请选择维度值',
  dataTypeNotSupport: '不支持该数据类型的维度进行维度拆解',
};

export const attributionSummary = {
  title: '归因摘要',
  topPositives: 'Top 正向影响',
  topNegatives: 'Top 负向影响',
  collapse: '收起',
  expand: '展开',
  startToEnd: (start: string, end: string) => `${start} 至 ${end}`,
  from: ' 由 ',
  to: ' 至 ',
  growthValue: '，增长值为 ',
  growthRate: '，增长率为 ',
  contributionRate: '，贡献度为 ',
  itemSuffix: '；',
  mainDescSuffix1: '。以下指标的变化对 ',
  mainDescSuffix2: ' 的变化贡献最明显：',
  grow: ' 增长',
  reduce: ' 降低',
  previewError: '归因配置异常，导致归因摘要无法显示，请联系负责人。',
  editError: '归因配置存在异常，导致归因摘要无法显示，请检查以下问题：',
};
