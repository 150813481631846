export const tab = {
  label: {
    systemIntegration: {
      title: '系统集成',
      softIntegration: '办公软件',
      messagePush: '消息推送',
    },
    loginAuthentication: '登录认证',
  },
};

export const user = {
  title: '用户',
  addUser: '新建用户',
  appendUser: '添加用户',
  addUserToTenant: '将用户到加到租户',
  addSuccess: '新建成功',
  editSuccess: '编辑成功',
  table: {
    account: '用户名',
    nickName: '昵称',
    total: ({ total }: { total: string | number }) => `${total}个用户`,
    role: '角色',
    userType: '用户类型',
    status: {
      title: '状态',
      placeholder: '选择',
      text: (ban: boolean) => (!ban ? '已启用' : '已停用'),
    },
    createMode: {
      manual: '手动创建',
      sync: '用户同步',
    },

    operate: {
      title: '操作',
      statusBtn: (ban: boolean) => (ban ? '启用' : '停用'),
      accountBind: '账号绑定',
      resetPwd: '重置密码',
      more: '更多',
    },
  },
  executeModal: {
    title: '最近同步',
    des: '最近一次同步时间: ',
    cancel: '取消',
    success: '已发起同步任务，请稍后刷新页面查看',
    trigger: '立即同步',
  },
  resetPwdModal: {
    title: '重置密码',
    desc: (nickname: string) => `重置 ${nickname} 的密码`,
    getPwd: '点击获取新密码',
    pwdDesc: '用户密码已重置，以下为新密码:',
    copy: '复制',
    copySuccess: '已复制到粘贴板',
  },
  edit: '编辑',
  delete: {
    default: '删除',
    confirm: '移除',
  },
  deleteModal: {
    title: '删除用户',
    desc: ({ username }: { username: string | number }) =>
      `从系统中删除用户${username}。被删除用户所产生的工作内容将移交给平台管理员。`,
  },
  searchEmptyDesc: '没有符合搜索条件的用户，请重新尝试搜索',

  userSync: {
    open: '同步用户: 已开启',
    syncImmediately: '立即同步',
    syncConfig: '同步配置',
  },
  useSyncModal: {
    filter: '筛选',
    title: '同步用户',
    labels: {
      open: '开启',
      userSource: {
        title: '用户来源',
        options: {
          dataSource: '数据源',
          officeSoftware: '办公软件',
        },
      },
      extendedAttr: {
        title: '扩展属性',
        placeholder: '输入扩展属性 name',
      },
      syncUser: {
        title: '同步用户',
        fields: {
          dataSource: '数据源表',
          softIntegration: '办公软件',
          userName: '用户名',
          userNickname: '用户昵称',
          password: {
            title: '密码',
            options: {
              defaultPassword: '默认密码',
              bindingField: '绑定字段',
            },
            hint: (text: string) => '指标平台默认密码：' + text,
            forcePasswordUpdate: '首次登录后强制更新密码',
          },
          phoneNumber: '手机号',
          email: '邮箱',
        },
        placeholders: {
          dataSource: '选择数据源表',
          softIntegration: '请选择办公软件',
          userName: '选择用户名',
          userNickname: '选择用户昵称',
          phoneNumber: '选择手机号',
          email: '选择邮箱',
          pwd: '选择密码',
        },
      },
      syncSettings: {
        title: '同步设置',
        syncMethod: {
          title: '同步方式',
          options: {
            manualSync: '手动同步',
            scheduledSync: '定时同步',
          },
        },
        unmatchedUser: {
          title: '未匹配用户',
          options: {
            maintainOriginalState: '保持原状态',
            setToInactive: '置为停用状态',
          },
        },
        newUserRole: '新用户角色',
      },
    },
    success: '保存成功',
  },
  softwareBind: {
    bind: '办公软件绑定',
    modal: {
      title: '办公软件绑定',
      emptyDesc: '未配置办公软件集成',
      add: '配置办公软件集成',
      saveSuccess: '保存成功',
      form: {
        selectPlaceholder: '请选择',
        mode: '匹配方式',
        auto: '自动匹配',
        manual: '手动指定',
        manualDesc: '请在 [成员管理] 中，为每个用户单独绑定 [软件名] 账号',
        userProperty: '用户属性',
        marry: '匹配',
        enterpriseWechatProperty: '企业微信属性',
        updateSetting: '更新设置',
        cycle: '周期更新',
        manualUpdate: '手动更新',
        updateDesc: '更新一次',
        updateNow: '立即更新',
        updateSuccess: '更新成功',
      },
    },
    userBindModal: {
      title: '账号绑定',
      save: '保存',
      search: '搜索',
      selectPlaceholder: '请选择',
      saveSuccess: '保存成功',
      binUser: '绑定企业微信用户',
      notEmpty: '改属性不能为空',
      mappingIdExist: '该账号已被绑定',
      matchStatus: {
        success: '匹配成功',
        fail: '匹配失败',
        not: '未绑定',
      },
    },
  },
  addUserToTenantModal: {
    title: '添加用户到租户',
    tenantName: '租户: ',
    search: '搜索',
    searchPlaceholder: '搜索用户',
    addUser: '添加用户',
    success: '添加成功',
    tenantUserList: {
      user: '用户',
      delete: '删除',
      unbind: '删除',
    },
    empty: '暂无用户',
  },
};

export const role = {
  owner: '超级管理员',
  admin: '管理员',
  analyst: '指标定义者',
  usage: '指标使用者',
  viewer: '查看者',
};

export const userInfoModal = {
  create: {
    title: '新建用户',
    confirm: '创建',
  },
  moreInfo: '更多信息',
  edit: {
    title: '编辑用户',
    confirm: '保存',
  },
  label: {
    account: '用户名',
    nickname: '昵称',
    password: '密码',
    role: '角色',
    userGroup: '用户组',
    email: '邮箱',
    resetPasswordFirstLogin: '首次登录后强制更新密码',
    phone: '手机号',
  },
  input: {
    placeholder: '请输入',
  },
  password: {
    placeholder: '6 至 20 位密码',
  },
  error: {
    phone: '手机号格式不正确',
    email: '邮箱格式不正确',
  },
};

export const userList = {
  deleteConfirm: '确认删除该用户？',
};

export const userInfo = {
  form: {
    required: '该项不能为空',
  },
  accountExist: '该用户名已存在,但未被添加至当前租户。',
  addToTenant: '添加至当前租户',
  accountExistInTenant: '该用户已存在，并已被添加至当前租户。',
  phoneExist: '该手机号已存在',
  emailExist: '该邮箱已存在',
  account: {
    format: '仅支持英文、数字以及标点符号和特殊符号，不超过50个字符',
  },
};

export const userGroup = {
  title: '用户组',
  input: {
    userGroup: {
      placeholder: '搜索',
    },
  },
  addBtn: {
    text: '新建用户组',
    syncGroup: '同步用户组',
    syncGroupOpen: '同步用户组: 已开启',
  },
  treeNode: {
    moreAction: {
      addGroup: '添加子组',
      rename: '重命名',
      delete: '删除',
    },
  },
  duplicateName: '该用户组名称已存在',
  userList: {
    empty: {
      title: '暂无用户',
    },
  },
  userGroup: {
    sync: '用户组同步',
    manual: '手动创建',
    search: {
      empty: '没有符合搜索条件的用户组，请重新尝试搜索',
    },
  },
  user: {
    search: {
      empty: '没有符合搜索条件的用户，请重新尝试搜索',
    },
  },
  emptyText: '暂无用户组',
  deleteGroup: {
    title: '删除用户组',
    desc: '删除用户组会一并删除该组下的其他用户组',
    confirm: '删除',
    cancel: '取消',
  },
  deleteUser: {
    confirm: '删除',
    cancel: '取消',
  },
  emptyGroup: {
    title: '新建用户组',
  },
  userCount: ({ count }: { count: string | number }) => `${count}个用户`,
  userGroupCount: ({ count }: { count: string | number }) => `${count}个用户组`,
  searchPlaceholder: '搜索',
  groupSyncModal: {
    organization: '用户组结构同步',
    groupId: '用户组ID',
    displayName: '用户组名称',
    parentGroupId: '父用户组ID',
    userBind: '用户所属用户组同步',
    account: '用户名',
    inputPlaceHolder: (text: string) => `选择${text}`,
  },
};

export const useGroup = {
  addUserToGroup: '添加用户',
};

export const userProperty = {
  default: '用户属性',
  count: ({ count }: { count: string | number }) => `${count}个用户属性`,
  add: '添加用户属性',
  property: {
    name: '名称',
    displayName: '展示名',
    displayNameRequired: '展示名不能为空',
    displayNameRepeat: '该展示名已存在',
    nameRequired: '名称不能为空',
    nameFormat: '仅支持英文、数字以及下划线，不支持连续下划线',
    nameRepeat: '该名称已存在',
    edit: {
      default: '编辑',
      success: '编辑成功',
    },
    modal: {
      edit: '编辑用户属性',
    },
    add: {
      success: '添加成功',
    },
    delete: {
      default: '删除',
      title: '删除用户属性',
      desc: ({
        displayName,
        name,
      }: {
        displayName: string | number;
        name: string | number;
      }) => `确认删除 [${displayName}/${name}] 用户属性吗？`,
      confirm: '删除',
      cancel: '取消',
    },
    defManage: '属性管理',
    valueMange: '用户属性值管理',
  },
  value: {
    count: ({ count }: { count: string | number }) => `${count}个用户`,
    addInBatches: '批量导入用户属性值',
    edit: '编辑用户属性值',
    batchModal: {
      title: '批量导入属性值',
      step1: '1.上传属性内容',
      step2: '2.更新属性',
      downloadFile: '当前属性文件',
      fileOverMax: '文件大小超出限制',
      fileUploadDesc:
        '建议下载当前属性文件，为用户属性批量导入值。仅支持上传 xsl 格式文件，且单个文件大小不可超过 20 MB。 ',
      fileUploadTitle: '上传属性内容',
      uploadFile: '点击上传',
      uploadAreaDesc: ' 或 拖放文件到此处',
      upLoading: '正在提取文件数据...',
      uploading: {
        error: '文件上传失败',
        error2: '文件解析失败',
        resetBtn: '重新上传',
      },
      search: {
        empty: '没有符合搜索条件的内容，请重新尝试搜索。',
      },
      fileNotice:
        '用户属性上传模板，请注意以下事项： 1. 不要修改前三行的内容 2. 修改文件中用户对应的属性值',
      selectUpdateList: {
        title: '选择要更新的用户值',
        selected: ({
          count,
          number,
        }: {
          count: string | number;
          number: string | number;
        }) => `已选${count}/${number}`,
      },
      preview: {
        title: '属性值预览',
        emptyTitle: '暂无预览内容',
        emptyDesc1: '请上传属性文件，并勾选要更新的属性值。',
        emptyDesc2: '请勾选要更新的属性值。',
      },
    },
  },
};

export const category = {
  deleteSuccess: '删除成功',
  metric: {
    title: '指标类目',
  },
  dimension: {
    title: '维度类目',
  },
  dataset: {
    title: '数据集类目',
  },
  analysisView: {
    title: '指标视图类目',
  },
  resultPlan: {
    title: '结果加速类目',
  },
  search: {
    empty: '未查到类目',
  },
  addNew: '新建类目',
  newCategory: '新建类目',
  delete: '删除',
  deleteConfirm: '删除类目',
  deleteConfirmContent: '删除该类目可能会影响该类目下的资源，确认删除吗？',
  rename: '重命名',
  unCategory: '未分类',
};

export const authRule = {
  title: '权限规则',
  metric: {
    name: '指标',
    subTitle: '根据指标属性自动授权',
    properties: '指标属性',
    authRole: '授权指标角色',
  },
  dimension: {
    name: '维度',
    subTitle: '根据维度属性自动授权',
    properties: '维度属性',
    authRole: '授权维度角色',
  },
  noDefaultRole: `无`,
  resourceRoleName: (resourceName: string, roleName: string) =>
    `${resourceName}${roleName}`,
  save: '保存',
  saveSuccess: '保存成功',
};

export const approveSetting = {
  title: '审批管理',
  metricApprove: '指标审批',
  dimensionApprove: '维度审批',
  metric: '指标',
  dimension: '维度',
  table: {
    node: '审批节点',
    desc: '节点说明',
    open: '开启审批',
    operate: {
      title: '操作',
      edit: '编辑',
    },
  },
  platform: {
    editSuccess: '保存成功',
    title: '审批渠道',
    edit: '编辑',
    type: '渠道类型',
    node: '审批节点',
    internal: '指标平台内置审批流',
    external: '外部审批流',
    externalConfig: {
      address: {
        title: '审批地址配置',
        params: '请求参数',
        address: '请求地址',
        valueType: {
          custom: '自定义值',
          system: '系统值',
        },
        addParams: '添加参数',
        delete: '删除',
        bodyDesc:
          '在 Body 中设置必填的公共参数，支持在单个审批节点中添加自定义参数。',
        successResultParse: {
          title: '调用成功返回结果解析',
          desc: '调用接口后，通过该参数以及参数值判断本次调用是否成功，若调用未成功，则会提示用户重新发起审批。',
          successParams: '调用成功参数',
          successParamsPlaceholder: '输入响应参数路径',
          successValue: '调用成功值',
          successValuePlaceholder: '输入响应码值',
        },
        paramsSelect: {
          title: '参数选择',
          selfCustomize: '自定义参数',
          systemParam: '系统参数',
          showExample: '查看参数示例',
        },
      },
      rules: {
        necessary: '该字段不能为空',
      },
      detail: {
        title: '审批详情跳转配置',
        open: (isOpen: boolean) => (isOpen ? '已开启' : '未开启'),
      },
    },
  },
  nodeType: {
    add: '发布',
    edit: '编辑',
    delete: '删除',
    onLine: '上线',
    offLine: '下线',
  },
  editModal: {
    title: (text: string) => `${text}审批流`,
    add: '添加审批流',
    success: '编辑成功',
    card: {
      user: '用户',
      allUser: '全部用户',
      metricLevel: '指标等级',
      node: '审批节点',
      nodeTitle: (index: number) => '审批流 ' + index,
      empty: '内容不能为空',
      listEmpty: ' 列表不能为空',
      addNode: '添加审批节点',
      title: {
        index: '序号',
        approveOwner: {
          title: '审批人',
          user: '用户',
          userRole: '用户角色',
          userGroup: '用户组',
          userPlaceholder: '选择用户',
          userPlaceholderGroup: '选择用户组',
          rolePlaceholder: '选择角色',
        },
        type: {
          title: '审批方式',
          and: '会签',
          or: '或签',
        },
        delete: '删除',
      },
    },
  },
};

export const platformSettings = {
  title: '平台设置',
  code: {
    title: '启用指标/维度编码',
    disableWarning: '停用指标/维度编码',
    disableDesc:
      '停用指标/维度编码后，系统会删除已生成的指标/维度编码。并且 API 查询中，将切换为 指标/维度名称模式 进行查询，请谨慎操作。',
    desc: '启用指标/维度编码，在定义指标和维度时，需要填写指标和维度的唯一编码值。',
    apiCode: 'API 接口指标/维度标识',
    metricTimeCode: '指标日期维度编码',
    metricTimeLabel: '指标日期（metric_time）维度编码',
    metricTimeTips:
      '指标日期 (metric_time) 为指标平台内置的时间维度，使用该维度可以查看指定日期的指标结果。',
    metricTimePlaceholder: '输入指标日期维度编码',
    code: '指标/维度编码',
    name: '指标/维度名称',
    settingChangedTitle: '变更指标/维度编码设置',
    settingChangedWarning: (contents: string) =>
      `变更 ${contents}，可能会导致已集成的 API 接口查询失效，请谨慎操作。`,
    metricTimeError: '指标日期维度编码的格式错误',
    metricTimeRequired: '请输入指标日期维度编码',
  },
  save: '保存',
  saveSuccess: '保存成功',
  continue: '继续',
  cancel: '取消',
};

export const softIntegration = {
  title: '办公软件集成',
  testModal: '测试连通性',
  testSuccess: '测试成功',
  testError: '测试失败',
  desc: '集成后，可用于用户信息同步、登录认证、监控消息推送。',
  statusText: (isOpen: boolean) => (isOpen ? '已开启' : '已关闭'),
  open: '开启',
  saveSuccess: '保存成功',
  operate: {
    save: '保存',
    test: '测试',
  },
};

export const loginAuthentication = {
  title: '登录认证',
  defaultLoginCode: '默认登录方式',
  itemTitle: {
    account: '平台账号密码认证',
    http: 'HTTP认证',
    enterpriseWechat: '企业微信认证',
    sso: 'SSO认证',
  },

  formLabel: {
    open: '开启',
    userAuthentication: {
      title: '认证用户',
      owner: '超管账号',
      manual: '手动创建账号',
      sync: '用户同步账号',
    },
  },

  http: {
    deleteConfirm: '确认删除？',
    add: '添加认证',
    edit: '编辑',
    delete: '删除',
    argsCount: (count: number) => `${count}条参数`,

    modal: {
      title: (isEdit: boolean) => (isEdit ? '编辑HTTP认证' : '添加HTTP认证'),
      displayName: '登录选项显示',
      displayNameSuffix: '名称',
      address: '认证地址',
      paramsSetting: {
        title: '参数设置',
        loginParams: {
          title: '登录参数',
          name: '参数名',
          displayName: '展示名',
          isUserEdit: {
            title: '由用户填写',
            yes: '是',
            no: '否',
          },
          defaultValue: '默认值',
          isPassword: '密文显示',
          addBtn: '自定义参数',
        },
        result: {
          title: '认证结果',
          codePath: {
            label: '登录结果解析路径',
            placeholder: '输入',
          },
          successValue: {
            label: '登录结果成功标识',
            placeholder: '输入登录结果解析路径内的成功标识码',
          },
          accountPath: {
            label: '登录账号解析路径',
            placeholder: '输入登录账号解析路径',
          },
        },
      },
      inputPlaceholder: '请输入',
      notEmpty: '该项不能为空',
    },
  },

  enterpriseWechat: {
    emptyTitle: '未配置办公软件集成',
    setSoftIntegration: '配置办公软件集成',
  },
};

export const messagePush = {
  title: '消息推送',
  addBtnText: '添加消息推送',
  form: {
    displayname: '名称',
    enName: '英文名',
    pushUrl: '推送地址',
    remarks: '备注',
    secretKey: '签名检验秘钥',
    type: {
      title: '类型',
      enterpriseWechat: '企业微信机器人',
      custom: '自定义',
      lark: '飞书机器人',
    },
    placeholder: '请输入',
    notEmpty: '该项不能为空',
  },
  deleteSuccess: '删除成功',
  deleteDesc: '确认删除？',
  createModal: {
    title: (isEdit: boolean) => (isEdit ? '编辑消息推送' : '添加消息推送'),
    createSuccess: '创建成功',
    updateSuccess: '更新成功',
    operate: {
      cancel: '取消',
      test: '测试',
      save: '保存',
    },
    test: {
      successTitle: '连接成功',
      successDesc: '消息推送连通测试成功',
      okBtnText: '知道了',
      errorTitle: '连接失败',
      errorDesc: '消息推送连通测试失败，请检查配置信息',
    },
  },
};

export const authorizationManagement = {
  title: '授权管理',
  total: ({ total }: { total: string | number }) => `${total}条授权`,
  search: '查询',
  export: '导出',
  noDataToExport: '暂无数据可导出',
  add: '添加授权',
  showUser: '显示用户组内用户',
  showResources: '显示类目中资产',
  placeholder: '请选择',
  memberPickerPrefix: '用户/用户组:',
  table: {
    columns: {
      assetType: '资产类型',
      resourceFrom: '资产来源类目',
      userFrom: '用户来源用户组',
      assetName: '资产名称',
      user: '用户',
      permissionPoint: '权限点',
      grantTime: '授予时间',
      operate: '操作',
    },
    revoke: '撤销',
    sourceCategory: (name: string, categoryType: string) =>
      `来自${categoryType}类目: ${name}`,
    sourceUserGroup: (name: string) => `来自用户组: ${name}`,
    emptyTip: '请选择指定的资产或用户后，查询授权记录',
  },
  category: {
    placeholder: '搜索',
    apply: '应用',
  },
  modal: {
    selectResource: '请先选择资产',
    selectUser: '请先选择授予对象',
    title: '添加授权',
    assets: '资产',
    name: '授权对象',
    searchPlaceholder: '搜索用户/用户组',
    success: '授权成功',
    empty: '暂无授权对象',
  },
  tip: '请先选择资产或者用户',
};
