import { authority } from './../authority/index';
import { rest } from 'msw';
import { EColumnDataType, EOriginDataType } from '../../src/typings';
import { EColumnType, IColumn } from '../../src/typings/dataset';
import { getRestfulResponse } from '../utils';
import { getMockSimpleMetricInfo } from '../utils/getSimpleMetricList';
import { graphql } from 'msw';
import _ from 'lodash';
import mockViewConfig from './mockConfig';
import { markdownApiDoc, markdownBiDoc } from './mockDocStr';
import { mockCategoryList } from '../category/mockData';

export const mockAnalysisColumn1 = {
  name: 'column1', // 物理表的字段名
  dataType: EColumnDataType.TEXT,
  originDataType: EOriginDataType.VARCHAR,
  description: 'column1',
  type: EColumnType.DIMENSION,
  isPartition: false,
};

export const mockAnalysisColumn2 = {
  name: 'column2', // 物理表的字段名
  dataType: EColumnDataType.INT,
  originDataType: EOriginDataType.INTEGER,
  description: 'column2',
  type: EColumnType.DIMENSION,
  isPartition: false,
};

export const mockAnalysisColumn3 = {
  name: 'id', // 物理表的字段名
  dataType: EColumnDataType.TEXT,
  originDataType: EOriginDataType.VARCHAR,
  description: 'id',
  type: EColumnType.DIMENSION,
  isPartition: false,
};

export const mockAnalysisColumn4 = {
  name: 'column4', // 物理表的字段名
  dataType: EColumnDataType.DATE,
  originDataType: EOriginDataType.DATE,
  description: 'column3',
  type: EColumnType.DIMENSION,
  isPartition: false,
};

export const getAnalysisMockColumns = () => {
  return [
    getMockSimpleMetricInfo('metric_1', 0),
    getMockSimpleMetricInfo('metric_2', 1),
    getMockSimpleMetricInfo('metric_3', 2),
    getMockSimpleMetricInfo('metric_4', 3),
    getMockSimpleMetricInfo('metric_5', 4),
    mockAnalysisColumn1,
    mockAnalysisColumn2,
    mockAnalysisColumn3,
    mockAnalysisColumn4,
  ] as IColumn[];
};

// mock 接口
export const analysis = [
  //根据 id 获取查询视图配置
  graphql.query('getAnalysisViewById', (req, res, ctx) => {
    return res(
      ctx.data({ getAnalysisViewById: mockViewConfig }),
      ctx.delay(2000),
    );
  }),

  graphql.query('searchAnalysisView', (req, res, ctx) => {
    return res(
      ctx.data({
        searchAnalysisView: {
          data: _.map(_.range(20), (index) => ({
            id: index,
            displayName: 'analysisView_displayName_' + index,
            viewName: 'test_' + index,
            description: 'test desc_' + index,
            owner: {
              id: `user${index}`,
              nickname: `useName${index}`,
              account: `account${index}`,
              photo: '',
            },
            gmtModify: new Date('2023-07-06 19:39:00').getTime(),
            gmtCreate: new Date('2023-07-06 09:30:00').getTime(),
            authority: {
              canEdit: true,
              canDelete: true,
              canUsage: true,
              canAuth: true,
            },
            resultMv: _.sample([
              {
                status: 'NOT_CONFIG',
              },
              {
                status: 'SUCCESS',
                planDisplayName: 'test_acc_display_name',
                planName: 'test_acc_name',
              },
            ]),
            category: [_.sample(mockCategoryList)!],
          })),

          hasNext: 2,
          total: 100,
          pageNumber: 1,
          pageSize: 20,
        },
      }),
      ctx.delay(1000),
    );
  }),

  // 保存视图配置
  graphql.mutation('saveAnalysisView', (req, res, ctx) => {
    return res(
      ctx.data({
        saveAnalysisView: true,
      }),
      ctx.delay(1000),
    );
  }),

  // 检查本次视图的修改是否需要重新回补任务
  graphql.query('checkMtChange', (req, res, ctx) => {
    return res(ctx.data({ checkMtChange: true }), ctx.delay(1000));
  }),

  // 保存视图基础信息
  graphql.mutation('updateAnalysisViewBaseInfo', (req, res, ctx) => {
    return res(
      ctx.data({
        updateAnalysisViewBaseInfo: true,
      }),
      ctx.delay(1000),
    );
  }),

  // 删除某个视图
  graphql.mutation('deleteAnalysisView', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteAnalysisView: true,
      }),
      ctx.delay(1000),
    );
  }),

  rest.get('/doc/interface/info', async (req, res, ctx) => {
    const typeParam = req.url.searchParams.get('type');

    const data = typeParam === 'API' ? markdownApiDoc : markdownBiDoc;
    return res(ctx.json(getRestfulResponse<string>(data)));
  }),
  rest.get('/doc/interface/api', async (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          url: 'http://192.168.64.1:8085/semantic/api/v1.1/metrics/query',
          tenantId: 'tn_4023572',
          userId: '451467772623323136',
        }),
      ),
    );
  }),
];
