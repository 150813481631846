import FilterDependencyValuesSelect from '@/common/domain/filter/FilterDependencyValuesSelect';
import { Condition } from '../../common/Condition';
import { EDependencyType, TFilter } from '../../types';
import ColumnPicker from '../ColumnPicker';
import { EFilterType as EFilterComponentType } from '@/common/domain/filter/Filter/types';
import { transformValues } from '@/common/domain/filter/Filter/helper';
import { memo, useMemo } from 'react';
import { IDataset } from '@/typings/dataset';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import Operator from '../../common/Operator/CommonOperator';
import { EColumnDataType } from '@/typings';

function ColumnCondition(props: IProps) {
  const { value, onChange, datasets, supportTimeGranularity = true } = props;
  const columnDependency = value.dependency as IColumnDep;

  const dataset = useMemo(() => {
    const { dependency } = value;
    const { datasetName } = dependency as IColumnDep;
    const datasetItem = datasets.find((ds) => ds.name === datasetName);
    return datasetItem;
  }, [datasets, value]);

  const column = useMemo(() => {
    const { dependency } = value;
    const { columnName } = dependency as IColumnDep;
    if (!dataset) return undefined;
    return dataset.columns.find((col) => col.name === columnName);
  }, [dataset, value]);

  const leftData = useMemo(() => {
    if (!column) return undefined;
    return {
      id: column.name,
      originDataType: column.originDataType,
      displayName: column.displayName,
    };
  }, [column]);

  return (
    <Condition
      value={value}
      onChange={onChange}
      leftData={leftData}
      renderOperator={(operatorProps) => <Operator {...operatorProps} />}
      renderLeft={({ value: colDepValue, onChange: onChangeColDep }) => {
        const colDepData = colDepValue.dependency as IColumnDep;
        return (
          <ColumnPicker
            datasets={datasets}
            value={{ ...colDepData, granularity: value.granularity }}
            supportGranularity
            supportTimeGranularity={supportTimeGranularity}
            onChange={(newCol) => {
              const { columnName, datasetName, granularity } = newCol;
              const newDataset = datasets.find((ds) => ds.name === datasetName);
              const newColumn = newDataset?.columns.find(
                (col) => col.name === columnName,
              );
              onChangeColDep({
                value: {
                  dependency: {
                    type: EDependencyType.COLUMN,
                    columnName,
                    datasetName,
                  },
                  granularity,
                },
                entity: newColumn,
                originDataType: newColumn?.originDataType,
              });
            }}
          />
        );
      }}
      renderValuesPicker={({
        value: colValues,
        onChange: onChangeColumnValues,
      }) => {
        return (
          <FilterDependencyValuesSelect
            dependency={{
              dataset: {
                name: columnDependency.datasetName,
                columns:
                  dataset?.columns.map((col) => ({
                    name: col.name,
                    id: col.name,
                    originDataType: col.originDataType,
                    displayName: col.displayName,
                    dataType: originDataType2DataTypeMap[col.originDataType],
                  })) || [],
              },
              columnName: columnDependency.columnName,
              type: EFilterComponentType.FILTER,
            }}
            dataType={
              column
                ? originDataType2DataTypeMap[column.originDataType]
                : EColumnDataType.TEXT
            }
            value={transformValues(colValues)}
            onSubmit={(newColValues) => {
              onChangeColumnValues(newColValues);
            }}
          />
        );
      }}
    />
  );
}

interface IProps {
  value: TFilter;
  onChange: (value: TFilter) => void;
  datasets: IDataset[];
  supportTimeGranularity?: boolean;
}

interface IColumnDep {
  type: EDependencyType.COLUMN;
  datasetName: string;
  columnName: string;
}

export default memo(ColumnCondition);
