export const metricTreeDetailHeader = {
  globalConfiguration: 'Global Configuration',
  preview: 'Preview',
  save: 'Save Draft',
  back: 'Exit',
  edit: 'Edit',
  publish: 'Publish',
  reset: 'Reset Draft to Online Version',
  configuration: {
    node: 'Node Configuration',
    global: 'Global Configuration',
    filter: 'Filter Configuration',
  },
  title: {
    unnamed: 'Untitled Metric Tree',
  },
  renameSuccess: 'rename success',
  saveSuccess: 'Save Successful',
  btn: {
    share: 'Share',
  },
  shareModal: {
    title: 'Share',
    success: 'Share Successful',
  },
};
export const calculatingRelationship = {
  exit: 'Exit',
  save: 'Save',
  attributionAnalysis: 'Attribution Analysis Setting',
  title: 'Metric Tree',
  desc: 'Click the metric tree node to configure the calculation relationship and analysis dimension between the node and its sub-nodes',
  detail: {
    title: 'Node Calculation Relationship',
  },
  node: {
    unnamed: 'Unnamed Node',
  },
};
export const globalConfiguration = {
  nodeList: {
    tab: 'Node List',
    update: 'Save',
    reset: 'Reset',
    attributionConfigInvalidContent:
      'Position adjustment affects the existing attribution relationship. Do you want to continue saving?',
  },
  style: {
    tab: 'Style',
    direction: 'Expand Direction',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    defaultExpandLevel: 'Default Expand Level',
    level: ({ level }: { level: number }) =>
      level < 0 ? 'All' : `${level} Levels`,
    color: 'Coloring',
    redRiseGreenFall: 'Red Rise Green Fall',
    greenRiseRedFall: 'Green Rise Red Fall',
  },
  analysis: {
    tab: 'Analysis',
    comparisonGranularity: (time: string) => `Year-over-Year ${time}`,
    to: 'to',
    comparativeData: {
      title: 'Comparative Data',
      description:
        'Select the comparative data to be displayed on the metric card by default, and perform attribution calculation based on the comparative data.',
      switch: 'Show Comparison Button',
    },
    attributionAnalysis: {
      title: 'Attribution Analysis',
      calculatingRelationshipConfiguration:
        'Calculation Relationship Configuration',
      description:
        'Set the calculation relationship between multiple metrics in the metric tree to obtain the contribution of each metric to the main metric through attribution analysis. Comparative data needs to be configured for attribution to take effect.',
      tip: 'Comparative data needs to be configured for attribution to take effect.',
      btnText: 'Configure Comparison',
    },
  },
};
export const nodeConfiguration = {
  metric: 'Metric',
  analysisDimension: 'Analysis Dimension',
  noMetric: 'Select Metric',
  noDimension: 'Select Dimension for Multi-Dimensional Analysis',
  delete: 'Delete',
  rename: 'Rename',
  targetManagement: 'Target Management',
  targetPlaceholder: 'Please enter target value',
};
export const metricTreeNode = {
  unnamed: 'Unnamed Node',
  noMetric: 'Click to Configure Node Content',
  metricDisassembly: 'Metric Drill Down',
  noMetricQuery: 'Metric Not Queried',
  dimensionDisassembly: 'Dimension Drill Down',
  error: 'Metric Query Error',
  contribution: {
    title: 'Contribution',
    metricChange: 'Metric Change',
    overall: (overall: string) => `Overall Contribution: ${overall}`,
    relative: (relative: string) => `Relative Contribution: ${relative}`,
    metricComparisonValue: (value: string) => `Comparison Value: ${value}`,
    metricCurrentValue: (value: string) => `Current Value: ${value}`,
  },
  delete: 'Delete',
  deleteConfirm: ({ name, num }: { name: string; num: number }) =>
    `You will delete the "${name}" node, and this operation will delete ${num} sub-nodes under it. The operation cannot be restored after deletion. Please confirm whether to continue deleting.`,
  value: ' Value',
  growthValue: ' Growth Value',
  growth: ' Growth Rate',
  custom: 'Custom Date',
  targetManagement: {
    target: 'Target',
    schedule: 'Schedule',
  },
  attributionTitle: 'Attribution',
};
export const metricTree = {
  copy: {
    success: 'Copy Successful',
    error: 'Copy Failed',
  },
  list: {
    batch: {
      transfer: (value: string) => {
        return `No Transfer Permission for Metric Tree ${value}`;
      },
    },
    menu: {
      transfer: 'Transfer',
      view: 'View',
      share: 'Share',
      editBaseInfo: 'Edit Basic Info',
      edit: 'Edit',
      copy: 'Copy',
      delete: 'Delete',
      deleteConfirm: 'Confirm',
      deleteConfirmContent: ({ name }: { name: string | number }) =>
        `Confirm deletion of metric tree [${name}]`,
      deleteConfirmOk: 'Confirm',
      deleteConfirmCancel: 'Cancel',
    },
    deleteSuccess: 'Deletion Successful',
    name: 'Name',
    unPublished: 'Unpublished',
    published: 'Published',
    creator: 'Owner',
    status: 'Status',
    handle: 'Action',
    lastModified: 'Last Modified Time',
    createTime: 'Creation Time',
    title: 'Metric Tree',
    create: 'Create Metric Tree',
    total: ({ total }: { total: string | number }) => `${total} Metric Trees`,
  },
  detail: {
    title: {
      unnamed: 'Unnamed Metric Tree',
    },
  },
  basicInfo: {
    title: 'Edit Basic Info',
    name: 'Name',
    description: 'Description',
    saveSuccess: 'Save Successful',
    confirm: 'Save',
    cancel: 'Cancel',
    error: {
      name: 'Please enter a name',
    },
  },
  shareModal: {
    title: 'Share',
    success: 'Share Successful',
  },
};

export const attribution = {
  error: {
    expr: (displayName: string) =>
      `The formula of metric [${displayName}] is not a valid arithmetic operation`,
    parentChain: (displayName: string) =>
      `All ancestor nodes of metric [${displayName}] must have formulas configured`,
    metric: (displayName: string) =>
      `Node [${displayName}] is not configured with a metric`,
    onlyOneNodeInRelation: 'Please add some child nodes to the root node',
    noExpr: 'Please configure the formula',
    noComparisonType: 'Please select comparison type',
    notInChildren: (currDisplayName: string, subDisplayName: string) =>
      `The metric [${subDisplayName}] used in the formula of metric [${currDisplayName}] is not a child node of the current node`,
  },
};

export const menuBar = {
  btns: {
    comparison: 'Compare',
    query: 'Query',
  },
};

export const comparisonConfigModal = {
  title: 'Comparison',
  subTitle: 'Comparison Date',
  dod: 'Day-over-Day',
  wow: 'Week-over-Week',
  mom: 'Month-over-Month',
  qoq: 'Quarter-over-Quarter',
  yoy: 'Year-over-Year',
  custom: 'Custom Date',
  singleDate: 'Single Date',
  dateRange: 'Date Range',
};

export const addNodesModal = {
  metric: 'Metric',
  dimension: 'Dimension',
  dimensionValue: 'Dimension Value',
  dimensionRequired: 'Please select dimension',
  dimensionValueRequired: 'Please select dimension value',
  dataTypeNotSupport:
    'Dimension of this data type is not supported for dimension drill down',
};

export const attributionSummary = {
  title: 'Attribution Summary',
  topPositives: 'Top Positive Impact',
  topNegatives: 'Top Negative Impact',
  collapse: 'Collapse',
  expand: 'Expand',
  startToEnd: (start: string, end: string) => `${start} to ${end}`,
  from: ' from ',
  to: ' to ',
  growthValue: ', growth value is',
  growthRate: ', growth rate is',
  contributionRate: ', contribution rate is',
  itemSuffix: ';',
  mainDescSuffix1:
    '. The following metrics have the most significant impact on ',
  mainDescSuffix2: ' changes:',
  grow: ' growth',
  reduce: ' reduce',
  previewError:
    'Attribution configuration error, causing attribution summary to fail to display, please contact the owner.',
  editError:
    'Attribution configuration error, please check the following issues:',
};
