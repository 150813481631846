import { EDateGranularityType } from '@/typings';
import { IBaseProps } from '../../types';
import { AldSelect, Dropdown } from '@aloudata/aloudata-design';
import 'dayjs/locale/zh-cn';
import { useCustomCalendarDataContext } from '@/layouts/context/customCalendar/useCustomCalendarDataContext';
import {
  getCustomCalendarByGranularityName,
  getRealGranularity,
} from '../../../../granularity/helper';
import { useCallback, useMemo } from 'react';
import { ICalendarData } from '@/typings/customCalendar';

/**
 * 精确单个日期选择器
 */
export default function SpecificCustomCalendarPicker(props: IProps) {
  const {
    granularity,
    value,
    onChange: propsOnChange,
    disabled,
    className,
  } = props;
  const { customCalendarData } = useCustomCalendarDataContext();
  const currentCustomCalendar = getCustomCalendarByGranularityName(granularity);

  const onChange = useCallback(
    (val: string) => {
      const YEAR_INDEX = 0;
      const QUARTER_INDEX = 1;
      const MONTH_INDEX = 2;
      const WEEK_INDEX = 3;
      const splitValue = val.split('-');
      const year = splitValue[YEAR_INDEX];
      const quarter = splitValue[QUARTER_INDEX];
      const month = splitValue[MONTH_INDEX];
      const week = splitValue[WEEK_INDEX];
      const currentCustomCalendarId = currentCustomCalendar?.id;
      if (!currentCustomCalendarId) return;
      const currentCustomCalendarData =
        customCalendarData[currentCustomCalendarId];
      if (!currentCustomCalendarData) return;
      const realGranularity = getRealGranularity(granularity);
      let data = currentCustomCalendarData.find(
        (item) => item.year === Number(year),
      )?.date;
      switch (realGranularity) {
        case EDateGranularityType.YEAR:
          break;
        case EDateGranularityType.QUARTER:
          data = currentCustomCalendarData.find(
            (item) =>
              item.year === Number(year) &&
              item.quarterOfYear === Number(quarter),
          )?.date;
          break;
        case EDateGranularityType.MONTH:
          data = currentCustomCalendarData.find(
            (item) =>
              item.year === Number(year) &&
              item.quarterOfYear === Number(quarter) &&
              item.monthOfQuarter === Number(month),
          )?.date;
          break;
        case EDateGranularityType.WEEK:
          data = currentCustomCalendarData.find(
            (item) =>
              item.year === Number(year) &&
              item.quarterOfYear === Number(quarter) &&
              item.monthOfQuarter === Number(month) &&
              item.weekOfMonth === Number(week),
          )?.date;
          break;
        default:
          break;
      }

      if (data) {
        propsOnChange?.(data);
      }
    },
    [currentCustomCalendar?.id, customCalendarData, granularity, propsOnChange],
  );

  const { displayLabel, selectedKey } = useMemo(() => {
    if (!value) return { displayLabel: '', selectedKey: '' };
    const currentCustomCalendarId = currentCustomCalendar?.id;
    if (!currentCustomCalendarId) return { displayLabel: '', selectedKey: '' };
    const currentCustomCalendarData =
      customCalendarData[currentCustomCalendarId];
    if (!currentCustomCalendarData)
      return { displayLabel: '', selectedKey: '' };
    const data = currentCustomCalendarData.find((item) => item.date === value);
    if (!data) return { displayLabel: '', selectedKey: '' };
    const { year, quarterOfYear, monthOfQuarter, weekOfMonth } = data;
    const realGranularity = getRealGranularity(granularity);
    switch (realGranularity) {
      case EDateGranularityType.YEAR:
        return { displayLabel: `FY${year}`, selectedKey: `${year}` };
      case EDateGranularityType.QUARTER:
        return {
          displayLabel: `FY${year}-Q${quarterOfYear}`,
          selectedKey: `${year}-${quarterOfYear}`,
        };
      case EDateGranularityType.MONTH:
        return {
          displayLabel: `FY${year}-Q${quarterOfYear}-M${monthOfQuarter}`,
          selectedKey: `${year}-${quarterOfYear}-${monthOfQuarter}`,
        };
      case EDateGranularityType.WEEK:
        return {
          displayLabel: `FY${year}-Q${quarterOfYear}-M${monthOfQuarter}-W${weekOfMonth}`,
          selectedKey: `${year}-${quarterOfYear}-${monthOfQuarter}-${weekOfMonth}`,
        };
      default:
        return { displayLabel: '', selectedKey: '' };
    }
  }, [currentCustomCalendar?.id, customCalendarData, granularity, value]);

  const originMenuItems = useMemo(() => {
    const currentCustomCalendarId = currentCustomCalendar?.id;
    if (!currentCustomCalendarId) return [];
    const currentCustomCalendarData =
      customCalendarData[currentCustomCalendarId];
    if (!currentCustomCalendarData) return [];
    const tree = convertToTree(currentCustomCalendarData);

    const realGranularity = getRealGranularity(granularity);

    switch (realGranularity) {
      case EDateGranularityType.YEAR:
        return Object.keys(tree).map((year) => ({
          label: `${year}`,
          key: year,
          onClick: () => onChange(`${year}`),
        }));
      case EDateGranularityType.QUARTER:
        return Object.keys(tree).map((year) => ({
          label: `${year}`,
          key: year,
          children: Object.keys(tree[year].children).map((quarter) => ({
            label: `Q${quarter}`,
            key: `${year}-${quarter}`,
            onClick: () => onChange(`${year}-${quarter}`),
          })),
        }));
      case EDateGranularityType.MONTH:
        return Object.keys(tree).map((year) => ({
          label: `${year}`,
          key: year,
          children: Object.keys(tree[year].children).map((quarter) => ({
            label: `Q${quarter}`,
            key: `${year}-${quarter}`,
            children: Object.keys(tree[year].children[quarter].children).map(
              (month) => ({
                label: `M${month}`,
                key: `${year}-${quarter}-${month}`,
                onClick: () => onChange(`${year}-${quarter}-${month}`),
              }),
            ),
          })),
        }));
      case EDateGranularityType.WEEK:
        return Object.keys(tree).map((year) => ({
          label: `${year}`,
          key: year,
          children: Object.keys(tree[year].children).map((quarter) => ({
            label: `Q${quarter}`,
            key: `${year}-${quarter}`,
            children: Object.keys(tree[year].children[quarter].children).map(
              (month) => ({
                label: `M${month}`,
                key: `${year}-${quarter}-${month}`,
                children: Object.keys(
                  tree[year].children[quarter].children[month].children,
                ).map((week) => ({
                  label: `W${week}`,
                  key: `${year}-${quarter}-${month}-${week}`,
                  onClick: () =>
                    onChange(`${year}-${quarter}-${month}-${week}`),
                })),
              }),
            ),
          })),
        }));
      default:
        return [];
    }
  }, [currentCustomCalendar?.id, customCalendarData, granularity, onChange]);

  return (
    <Dropdown
      menu={{
        items: originMenuItems,
        selectedKeys: [selectedKey],
      }}
    >
      <AldSelect
        className={className}
        open={false}
        disabled={disabled}
        value={{ label: displayLabel, value: null }}
      />
    </Dropdown>
  );
}

interface IProps extends IBaseProps {
  value?: number;
  onChange?: (time: number) => void;
  granularity: string;
}

export function getShowTime(granularity: string) {
  let format = '';
  if (granularity === EDateGranularityType.HOUR) {
    format = 'HH';
  } else if (granularity === EDateGranularityType.MINUTE) {
    format = 'HH:mm';
  } else if (granularity === EDateGranularityType.SECOND) {
    format = 'HH:mm:ss';
  }
  return format
    ? {
        format,
      }
    : undefined;
}

interface ICustomCalendarTree {
  [key: string]: {
    key: number;
    children: ICustomCalendarTree;
  };
}

function convertToTree(data: ICalendarData[]) {
  const tree: ICustomCalendarTree = {};

  data.forEach((item) => {
    const { year, quarterOfYear, monthOfQuarter, weekOfMonth, date } = item;

    // 创建年份层级
    if (!tree[year]) {
      tree[year] = { key: year, children: {} };
    }

    // 创建季度层级
    if (!tree[year].children[quarterOfYear]) {
      tree[year].children[quarterOfYear] = {
        key: quarterOfYear,
        children: {},
      };
    }

    // 创建月份层级
    if (!tree[year].children[quarterOfYear].children[monthOfQuarter]) {
      tree[year].children[quarterOfYear].children[monthOfQuarter] = {
        key: monthOfQuarter,
        children: {},
      };
    }

    // 创建周层级
    if (
      !tree[year].children[quarterOfYear].children[monthOfQuarter].children[
        weekOfMonth
      ]
    ) {
      tree[year].children[quarterOfYear].children[monthOfQuarter].children[
        weekOfMonth
      ] = {
        key: weekOfMonth,
        children: {},
      };
    }

    // 添加具体日期
    tree[year].children[quarterOfYear].children[monthOfQuarter].children[
      weekOfMonth
    ].children[date] = {
      key: date,
      children: {},
    };
  });

  return tree;
}
