import { userInfo } from '@/common/globalInfo/appConfig';
import { IUser } from '@/typings';
import { IDataset } from '@/typings/dataset';
import {
  ISimpleDimension,
  TMetricCodeAvailableDimensionsMap,
} from '@/typings/dimension';
import { IMetricDependency, TMetric, TMetricDraft } from '@/typings/metric';
import { atom } from 'jotai';
import { IFactoryDimensionWithActiveMetrics } from '../Modal/types';

export const metricCreatorAtom = atom<IUser>(userInfo);
// 当前编辑指标的数据
export const editMetricInfoAtom = atom<TMetric | TMetricDraft | null>(null);

// 当前拷贝指标的数据
export const copyMetricInfoAtom = atom<TMetric | TMetricDraft | null>(null);

// 定义基础指标需要的数据集列表
export const atomicMetricDatasetListAtom = atom<IDataset[]>([]);
// 定义基础指标需要的数据集列表loading状态
export const atomicMetricDatasetListLoadingAtom = atom<boolean>(true);
// 定义基础指标当前选中数据集的信息
export const atomicMetricDatasetAtom = atom<IDataset | null>(null);
// 定义基础指标当前选中数据集的一端数据集
export const atomicMetricOneEndDatasetAtom = atom<IDataset[]>([]);

// 定义基础指标需要的维度列表
export const atomicMetricDimensionListAtom = atom<ISimpleDimension[]>([]);

// 定义派生指标所需的依赖
export const derivedMetricDependencyAtom = atom<IMetricDependency>({
  metrics: [],
  dimensions: [],
});

// 定义复合指标所需的依赖
export const compositeMetricDependencyAtom = atom<IMetricDependency>({
  metrics: [],
  dimensions: [],
});

export const derivedMetricCodeAvailableDimensionsMapAtom =
  atom<TMetricCodeAvailableDimensionsMap>({});

// 定义复合指标需要的维度列表
export const compositeMetricDimensionListAtom = atom<{
  publicDimensions: ISimpleDimension[];
  factoryDimensionWithActiveMetrics: IFactoryDimensionWithActiveMetrics[];
}>({
  publicDimensions: [],
  factoryDimensionWithActiveMetrics: [],
});
