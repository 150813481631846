import { EColumnDataType } from '@/typings';
import { IDataset, TAssociatedDataset } from '@/typings/dataset';
import { EOriginDataType, EQueryEngineType } from '@/typings/formula';
import { originDataType2DataTypeMap } from '../constant';
import functionConfig from '../functionConfig/functionConfig';
import { processingEscapeCharacter } from './genFormula';
import { getEngineType, getSuggests } from './tool';
import _ from 'lodash';
import {
  ECodeEditorPropsType,
  IListItem,
  TExternalInfo,
} from '@/common/domain/codeEditor/CodeEditor/type';
import { ECompleteItemType, IFilterFunctionsList } from '../type';
import { IDependencies } from '@/typings/analysisView';
import { TResource } from '../../codeEditor/CodeEditorWrapper';
import { transform2ListItem } from '../../codeEditor/CodeEditorWrapper/helper';

export enum EAutoCompleteType {
  COMPLETE = 'COMPLETE',
  PROMPT = 'PROMPT',
}

export interface IComplete {
  type: EAutoCompleteType.COMPLETE;
  item: TAutoCompleteItem[];
}

interface ICommonCompleteItem {
  label: string;
  type:
    | ECompleteItemType.FUNCTION
    | ECompleteItemType.FUNCTION_PARAM
    | ECompleteItemType.TABLE;
  complete: string;
  partial: string;
  value: string;
}

export interface IColumnCompleteItem {
  label: string;
  type: ECompleteItemType.COLUMN;
  columnName: string; // 列名称
  complete: string; // 编辑器将要填充的字段
  partial: string; // 编辑器要替换的字段
  originDataType: EOriginDataType;
  dataType: EColumnDataType;
  value: string; // 进行匹配的字段
  idPath?: string[];
  dependencies?: IDependencies;
}
export type TAutoCompleteItem = ICommonCompleteItem | IColumnCompleteItem;
export interface IPrompt {
  type: EAutoCompleteType.PROMPT;
  functionName: string;
  functionDesc: string;
  functionGrammar: string;
  functionParamsDesc: {
    name: string;
    desc: string;
  }[];
  index: number;
}

export const MAX_LENGTH = 100;

export function autoCompleteInDataset(
  formula: string,
  dataset: IDataset | null,
  one2manyDatasets: TAssociatedDataset,
  many2oneDatasets: TAssociatedDataset,
  filterFunctionList: IFilterFunctionsList,
): Promise<IComplete | IPrompt> | undefined {
  const queryEngineType = getEngineType();

  if (queryEngineType) {
    const suggests = getSuggests(formula);

    if (suggests.suggestFunctionDesc) {
      const { functionName, index } = suggests.suggestFunctionDesc;
      const upperCaseFunctionName = functionName.toUpperCase();
      if (functionConfig[queryEngineType].functions[upperCaseFunctionName]) {
        const functionInfo =
          functionConfig[queryEngineType].functions[upperCaseFunctionName];

        const functionDesc = functionInfo.descZh || {};

        return Promise.resolve({
          type: EAutoCompleteType.PROMPT,
          functionName: functionInfo.name,
          functionDesc: functionDesc.desc,
          functionGrammar: functionDesc.formula,
          functionParamsDesc:
            functionDesc.paramArr?.map((param) => ({
              name: param.name,
              desc: param.desc,
            })) || [],
          index,
        });
      }
    }
    let completeItem: IComplete['item'] = [];

    if (suggests.suggestFunction) {
      const { partialText } = suggests.suggestFunction;

      const { functions } = functionConfig[queryEngineType];
      const completeFunction = _.keys(functions)
        .filter((functionName) => {
          return isMatchValue(functionName, partialText);
        })
        .filter((functionName) => {
          const functionInfo = functions[functionName];
          return filterFunctionList(functionInfo);
        })
        .map<ICommonCompleteItem>((functionName) => {
          return {
            label: functions[functionName].name,
            type: ECompleteItemType.FUNCTION,
            complete: `${functions[functionName].name}(`,
            partial: partialText,
            value: partialText,
          };
        });
      completeItem = _.concat(completeItem, completeFunction);
    }
    if (suggests.suggestFunctionParam) {
      const { partialText, functionName, index } =
        suggests.suggestFunctionParam;
      const functionItem =
        functionConfig[queryEngineType].functions[functionName.toUpperCase()];
      if (functionItem) {
        if (functionName.toUpperCase() === 'CAST') {
          const completeFunctionParam = [
            'Text',
            'Int',
            'Double',
            'Decimal',
            'Date',
            'DateTime',
            'Json',
            'Bool',
          ]
            .filter((param) => {
              return param
                .toLocaleLowerCase()
                .includes(partialText.replace('"', '').toLocaleLowerCase());
            })
            .map<ICommonCompleteItem>((param) => {
              return {
                label: `"${param}"`,
                type: ECompleteItemType.FUNCTION_PARAM,
                complete: `"${param}"`,
                partial: partialText,
                value: partialText,
              };
            });

          completeItem = _.concat(completeItem, completeFunctionParam);
        }

        if (
          functionItem.signatures[0].args[index] &&
          functionItem.signatures[0].args[index].split(',').length > 1
        ) {
          const completeFunctionParam = functionItem.signatures[0].args[index]
            .split(',')
            .map((param) => param.trim())
            .filter((param) => {
              return param
                .toLocaleLowerCase()
                .includes(partialText.replace('"', '').toLocaleLowerCase());
            })
            .map<ICommonCompleteItem>((param) => {
              return {
                label: `"${param}"`,
                type: ECompleteItemType.FUNCTION_PARAM,
                complete: `"${param}"`,
                partial: partialText,
                value: partialText,
              };
            });

          completeItem = _.concat(completeItem, completeFunctionParam);
        }
      }
    }
    if (suggests.suggestTable) {
      const { value, partialText } = suggests.suggestTable;

      const completeTables: ICommonCompleteItem[] = [
        ...one2manyDatasets,
        ...many2oneDatasets,
      ]
        .filter((tempDataset) => isMatchValue(tempDataset.displayName, value))
        .map((tempDataset) => ({
          label: tempDataset.displayName,
          type: ECompleteItemType.TABLE,
          complete: `[${processingEscapeCharacter(tempDataset.displayName)}/`,
          partial: partialText,
          value,
        }));
      completeItem = _.concat(completeItem, completeTables);
    }
    if (suggests.suggestColumn) {
      const { table, value = '', partialText } = suggests.suggestColumn;
      if (table) {
        const tempDataset = [...one2manyDatasets, ...many2oneDatasets].find(
          (associatedDataset) => associatedDataset.displayName === table,
        );

        if (tempDataset) {
          const completeColumnItems = tempDataset.defColumns
            .filter((column) => isMatchValue(column.displayName, value))
            .map((column) => ({
              label: `${tempDataset.displayName}/${column.displayName}`,
              type: ECompleteItemType.COLUMN,
              complete: `[${processingEscapeCharacter(
                tempDataset.displayName,
              )}/${processingEscapeCharacter(column.displayName)}]`,
              partial: partialText,
              originDataType: column.originDataType,
              dataType: originDataType2DataTypeMap[column.originDataType],
              value,
              columnName: column.name,
              idPath: [tempDataset.name, column.name],
            }));
          completeItem = _.concat(completeItem, completeColumnItems);
        }
      } else {
        if (dataset) {
          const completeColumnItems = dataset.defColumns
            .filter((column) => isMatchValue(column.displayName, value))
            .map((column) => {
              return {
                label: `${column.displayName}`,
                type: ECompleteItemType.COLUMN,
                complete: `[${processingEscapeCharacter(
                  column.displayName || '',
                )}]`,
                partial: partialText,
                originDataType: column.originDataType,
                dataType: originDataType2DataTypeMap[column.originDataType],
                value,
                columnName: column.name,
                idPath: [column.name],
              };
            });
          completeItem = _.concat(completeItem, completeColumnItems);
        }
        if (one2manyDatasets.length !== 0 || many2oneDatasets.length !== 0) {
          const completeColumnItems = [...one2manyDatasets, ...many2oneDatasets]
            .map((tempDataset) => {
              return tempDataset.columns
                .filter((column) => isMatchValue(column.displayName, value))
                .map((column): TAutoCompleteItem => {
                  return {
                    label: `${tempDataset.displayName}/${column.displayName}`,
                    type: ECompleteItemType.COLUMN,
                    complete: `[${
                      tempDataset.displayName
                    }/${processingEscapeCharacter(column.displayName || '')}]`,
                    partial: partialText,
                    originDataType: column.originDataType,
                    dataType: originDataType2DataTypeMap[column.originDataType],
                    value,
                    columnName: column.name,
                    idPath: [tempDataset.name, column.name],
                  };
                });
            })
            .flat(1);
          completeItem = _.concat(completeItem, completeColumnItems);
        }
      }
    }
    if (completeItem.length > 0) {
      return Promise.resolve({
        type: EAutoCompleteType.COMPLETE,
        item: _.uniqBy(completeItem, 'label')
          .sort(compareCompleteItem)
          .slice(0, MAX_LENGTH),
      });
    }
  }
}

export async function autoCompleteInList(
  formula: string,
  queryListByKey: (key: string) => Promise<IListItem[]>,
  queryEngineType: EQueryEngineType,
  filterFunctionList: IFilterFunctionsList,
): Promise<IComplete | IPrompt | undefined> {
  if (queryEngineType) {
    const suggests = getSuggests(formula);

    if (suggests.suggestFunctionDesc) {
      const { functionName, index } = suggests.suggestFunctionDesc;
      const upperCaseFunctionName = functionName.toUpperCase();
      if (functionConfig[queryEngineType].functions[upperCaseFunctionName]) {
        const functionInfo =
          functionConfig[queryEngineType].functions[upperCaseFunctionName];

        const functionDesc = functionInfo.descZh || {};

        return {
          type: EAutoCompleteType.PROMPT,
          functionName: functionInfo.name,
          functionDesc: functionDesc.desc,
          functionGrammar: functionDesc.formula,
          functionParamsDesc:
            functionDesc.paramArr?.map((param) => ({
              name: param.name,
              desc: param.desc,
            })) || [],
          index,
        };
      }
    }
    let completeItem: IComplete['item'] = [];

    if (suggests.suggestFunction) {
      const { partialText } = suggests.suggestFunction;

      const { functions } = functionConfig[queryEngineType];
      const completeFunction = _.keys(functions)
        .filter((functionName) => {
          return isMatchValue(functionName, partialText);
        })
        .filter((functionName) => {
          const functionInfo = functions[functionName];
          return filterFunctionList(functionInfo);
        })
        .map<ICommonCompleteItem>((functionName) => {
          return {
            label: functions[functionName].name,
            type: ECompleteItemType.FUNCTION,
            complete: `${functions[functionName].name}(`,
            partial: partialText,
            value: partialText,
          };
        });
      completeItem = _.concat(completeItem, completeFunction);
    }
    if (suggests.suggestFunctionParam) {
      const { partialText, functionName, index } =
        suggests.suggestFunctionParam;
      const functionItem =
        functionConfig[queryEngineType].functions[functionName.toUpperCase()];

      if (functionName.toUpperCase() === 'CAST') {
        const completeFunctionParam = [
          'Text',
          'Int',
          'Double',
          'Decimal',
          'Date',
          'DateTime',
          'Json',
          'Bool',
        ]
          .filter((param) => {
            return param
              .toLocaleLowerCase()
              .includes(partialText.replace('"', '').toLocaleLowerCase());
          })
          .map<ICommonCompleteItem>((param) => {
            return {
              label: `"${param}"`,
              type: ECompleteItemType.FUNCTION_PARAM,
              complete: `"${param}"`,
              partial: partialText,
              value: partialText,
            };
          });

        completeItem = _.concat(completeItem, completeFunctionParam);
      }

      if (
        functionItem.signatures[0].args[index] &&
        functionItem.signatures[0].args[index].split(',').length > 1
      ) {
        const completeFunctionParam = functionItem.signatures[0].args[index]
          .split(',')
          .map((param) => param.trim())
          .filter((param) => {
            return param
              .toLocaleLowerCase()
              .includes(partialText.replace('"', '').toLocaleLowerCase());
          })
          .map<ICommonCompleteItem>((param) => {
            return {
              label: `"${param}"`,
              type: ECompleteItemType.FUNCTION_PARAM,
              complete: `"${param}"`,
              partial: partialText,
              value: partialText,
            };
          });

        completeItem = _.concat(completeItem, completeFunctionParam);
      }
    }
    if (suggests.suggestColumn) {
      const { table, value = '', partialText } = suggests.suggestColumn;
      if (!table) {
        const tempList = await queryListByKey(value);
        const completeColumnItems = tempList.map((item) => {
          return {
            label: `${item.displayName}`,
            type: ECompleteItemType.COLUMN,
            complete: `[${processingEscapeCharacter(item.displayName || '')}]`,
            partial: partialText,
            originDataType: item.originDataType,
            dataType: originDataType2DataTypeMap[item.originDataType],
            value,
            columnName: item.displayName,
            idPath: [item.id],
            dependencies: item.dependencies,
          };
        });
        completeItem = _.concat(completeItem, completeColumnItems);
      }
    }
    if (completeItem.length > 0) {
      return {
        type: EAutoCompleteType.COMPLETE,
        item: _.uniqBy(completeItem, 'label')
          .sort(compareCompleteItem)
          .slice(0, MAX_LENGTH),
      };
    }
  }
}
// ============== 对自动提示排序相关 =========

enum EPriorityType {
  EQUAL = 'EQUAL',
  START_WITH = 'START_WITH',
  INCLUDE = 'INCLUDE',
  INCLUDE_WORD = 'INCLUDE_WORD',
}

const priorityMap = {
  [EPriorityType.EQUAL]: 40,
  [EPriorityType.START_WITH]: 30,
  [EPriorityType.INCLUDE]: 20,
  [EPriorityType.INCLUDE_WORD]: 10,
};

// 等于 以..开头 包含... 包含拆开的字符
function getMatchRegMap(value: string) {
  return [
    {
      type: EPriorityType.EQUAL,
      reg: `^${value}$`,
    },
    {
      type: EPriorityType.START_WITH,
      reg: `^${value}.*$`,
    },
    {
      type: EPriorityType.INCLUDE,
      reg: `^.*${value}.*$`,
    },
    {
      type: EPriorityType.INCLUDE_WORD,
      reg: `^.*${value.split('').join('.*')}.*$`,
    },
  ];
}

export function isMatchValue(value: string, tem: string) {
  if (!tem.trim()) return true;
  return getMatchRegMap(tem.trim().toLowerCase()).some((matchRegObj) =>
    new RegExp(matchRegObj.reg).test((value || '').trim().toLowerCase()),
  );
}

function getLabel(completeItem: TAutoCompleteItem) {
  if (completeItem.type === ECompleteItemType.COLUMN)
    return completeItem.columnName;
  return completeItem.label;
}

// 计算匹配度
function getMatchScore(completeItem: TAutoCompleteItem) {
  const { value = '' } = completeItem;
  const label = getLabel(completeItem) || '';
  const matchResult = getMatchRegMap(value.toLowerCase()).find((matchRegObj) =>
    new RegExp(matchRegObj.reg).test(label.toLowerCase()),
  );

  if (matchResult) return priorityMap[matchResult.type];

  return 0;
}
// 获取类型的优先级
function getCompleteTypePriority(completeItem: TAutoCompleteItem) {
  const completeTypePriority = {
    [ECompleteItemType.FUNCTION]: 40,
    [ECompleteItemType.COLUMN]: 30,
    [ECompleteItemType.TABLE]: 20,
    [ECompleteItemType.FUNCTION_PARAM]: 10,
  };
  return completeTypePriority[completeItem.type];
}

// 获取字符串的占比
function getRatioPartial(completeItem: TAutoCompleteItem) {
  const label = getLabel(completeItem);
  return completeItem.value.length / (label?.length || 1);
}

/**
 * 从右往左根据函数优先级列表进行优先级的判断:
 *  如果前一个函数的出的优先级相同则使用下一个函数进行判断，最后一个函数小于等于一起判断。
 * @param a 提示项A
 * @param b 提示项B
 * @param getPartialArr 优先级获取函数列表
 * @returns 1、-1、0
 */
function compareCompleteItemFn(
  a: TAutoCompleteItem,
  b: TAutoCompleteItem,
  getPartialArr: Array<(completeItem: TAutoCompleteItem) => number>,
): number {
  const SMALLER = -1;

  if (getPartialArr.length === 0) return 0;

  const getPartialFn = getPartialArr[0] as (
    completeItem: TAutoCompleteItem,
  ) => number;

  const aScore = getPartialFn(a);
  const bScore = getPartialFn(b);

  if (getPartialArr.length === 1) {
    return aScore > bScore ? SMALLER : 1;
  } else if (getPartialArr.length > 1) {
    if (aScore > bScore) {
      return SMALLER;
    }
    if (aScore < bScore) {
      return 1;
    }

    if (aScore === bScore)
      return compareCompleteItemFn(a, b, getPartialArr.slice(1));
  }

  return 0;
}

export const compareCompleteItem = _.curryRight(compareCompleteItemFn)([
  getMatchScore,
  getRatioPartial,
  getCompleteTypePriority,
]);

export function autoCompleteAdapter(
  formula: string,
  externalInfo: TExternalInfo,
  filterFunctionList: IFilterFunctionsList,
  asyncMode: boolean,
  searchResource?: (key: string) => Promise<TResource[]>,
): Promise<IComplete | IPrompt | undefined> | undefined {
  if (externalInfo.type === ECodeEditorPropsType.DATASET) {
    return autoCompleteInDataset(
      formula,
      externalInfo.dataset,
      externalInfo.one2manyDatasets,
      externalInfo.many2oneDatasets || [],
      filterFunctionList,
    );
  } else if (externalInfo.type === ECodeEditorPropsType.LIST) {
    return autoCompleteInList(
      formula,
      getQueryListByKeyFn(asyncMode, externalInfo.list, searchResource),
      EQueryEngineType.AIR,
      filterFunctionList,
    );
  } else if (externalInfo.type === ECodeEditorPropsType.COMPOSE_METRIC) {
    return autoCompleteInList(
      formula,
      getQueryListByKeyFn(
        asyncMode,
        [...externalInfo.list, ...externalInfo.dimensionList],
        searchResource,
      ),
      EQueryEngineType.AIR,
      filterFunctionList,
    );
  }
}

export const getQueryListByKeyFn = (
  asyncMode: boolean,
  list: IListItem[],
  searchResource?: (key: string) => Promise<TResource[]>,
) => {
  if (asyncMode) {
    return async (key: string) => {
      const tempList = await searchResource?.(key);

      return tempList?.map((item) => transform2ListItem(item)) || [];
    };
  }

  return async (key: string) => {
    return Promise.resolve(
      list.filter((item) => isMatchValue(item.displayName, key)),
    );
  };
};
