import styles from './index.less';
import { TMetric } from '@/typings/metric';
import { EDisplayType, ESelectMode, IDataItem } from '../types';
import { EResourceType } from '@/typings/authority';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import _ from 'lodash';
import { useGetMetricList } from '@/services/metric';
import { ALL_METRIC_LENGTH } from '@/constants';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import MetricIcon from '../../MetricIcon';
import { PickerTooltip } from './Tooltip';
import classNames from 'classnames';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { filterByKeyword } from '@/common/utils';
import { getEnableCode } from '@/common/globalInfo/appConfig';
import { DATATYPE_COLOR } from '../constants';
import TreePicker, { IReqParams, ITreePickerRef } from '../TreePicker';
import { ECategoryType } from '@/typings/category';
import { useGetCategoryList } from '@/services/category';

function MetricTreePicker(
  props: IProps,
  ref: React.Ref<ITreePickerRef<TMetric>>,
) {
  const {
    metrics: propsMetrics,
    size,
    filterCandidate,
    showDataType = true,
    loading: propsLoading,
    renderNode,
    ...otherProps
  } = props;

  const { refetch } = useGetMetricList(
    {
      pager: {
        pageNumber: 1,
        pageSize: ALL_METRIC_LENGTH,
      },
    },
    {
      skip: true,
    },
  );

  const { data: categoryData, loading } = useGetCategoryList({
    categoryType: ECategoryType.CATEGORY_METRIC,
  });

  const queryDataList = useCallback(
    (params: IReqParams) => {
      const { keyword, categoryId } = params;
      return refetch({
        keyword,
        categoryId,
        pager: {
          pageNumber: 1,
          pageSize: ALL_METRIC_LENGTH,
        },
      });
    },
    [refetch],
  );

  const ICON_SIZE_SMALL = 16;
  const ICON_SIZE_MIDDLE = 20;
  const iconSize = size === 'small' ? ICON_SIZE_SMALL : ICON_SIZE_MIDDLE;

  const renderItem = useCallback(
    (item: IDataItem<TMetric>) => {
      const { data: metric, disabled, disabledReason } = item;
      const itemNode = (
        <PickerTooltip metric={metric} reason={disabledReason}>
          <div
            className={classNames(
              styles.metricItem,
              {
                [styles.disabled]: disabled,
                [styles.small]: size === 'small',
              },
              classNames || '',
            )}
            key={metric.code}
          >
            <div className={styles.dataType}>
              {showDataType ? (
                <ColumnDataTypeIcon
                  dataType={originDataType2DataTypeMap[metric.originDataType]}
                  size={iconSize}
                  color={DATATYPE_COLOR}
                />
              ) : (
                <MetricIcon type={metric.type} size={iconSize} />
              )}
            </div>
            <div className={styles.nameWrapper}>
              <div className={styles.name}>
                {metric.name || metric.metricName}
              </div>
            </div>
          </div>
        </PickerTooltip>
      );

      return renderNode ? renderNode(itemNode, metric, disabled) : itemNode;
    },
    [iconSize, showDataType, size, renderNode],
  );

  const enableCode = getEnableCode();

  const filterItem = useCallback(
    (dataItem: TMetric, keyword: string) => {
      // FIXME: 后续删除下面2行判断
      if (dataItem.authority?.canUsage === false) {
        return false;
      }
      if (dataItem.isPublished === false) {
        return false;
      }

      if (typeof filterCandidate === 'function') {
        const res = filterCandidate(dataItem);
        if (res === false) {
          return res;
        }
      }
      const trimKeyword = _.trim(keyword);
      if (trimKeyword) {
        const filteredList = filterByKeyword(
          [dataItem],
          [
            (item) => item.name,
            (item) => item.metricName,
            (item) => (enableCode ? item.metricCode || '' : ''),
          ],
          keyword,
        );
        if (filteredList.length === 0) {
          return false;
        }
      }
      return true;
    },
    [enableCode, filterCandidate],
  );

  const getKey = useCallback((dataItem: TMetric) => {
    return dataItem.code;
  }, []);

  useImperativeHandle(ref, () => ({
    collapseAll: () => {
      treeRef.current?.collapseAll();
    },
    refresh: () => {
      treeRef.current?.refresh();
    },
    activateItem: (dataItem) => {
      treeRef.current?.activateItem(dataItem);
    },
  }));

  const treeRef = useRef<ITreePickerRef<TMetric>>(null);

  return (
    <TreePicker<TMetric>
      {...otherProps}
      ref={treeRef}
      dataItems={propsMetrics}
      renderItem={renderItem}
      filterCandidate={filterItem}
      getKey={getKey}
      queryDataList={queryDataList}
      categories={categoryData || []}
      loading={propsLoading || loading}
      iconSize={iconSize}
      hideLocalStorageKey="IS_HIDE_UNAVAILABLE_METRICS"
      size={size}
    />
  );
}

export interface IProps {
  metrics?: TMetric[];
  value?: TMetric; // 单选选中的指标
  onChange?: (metric: TMetric) => void; // 单选选中的回调
  picked?: TMetric[]; // 选中的指标列表
  onPick?: (metrics: TMetric[]) => void; // 批量勾选
  size?: 'small';
  showDataType?: boolean; // 是否显示数据类型，否则显示指标类型，在集中授权需要渲染成指标类型
  renderNode?: (
    node: React.ReactNode,
    metric: TMetric,
    disabled: boolean,
  ) => React.ReactNode; // 用于自定义支持拖拽的节点
  showSearch?: boolean; // 显示搜索框
  // 查询候选项时的代持资源信息，存在代持的情况下，不要根据当前用户的 canUsage 权限过滤候选列表
  queryListMeta?: {
    resourceType: EResourceType;
    resourceId: string;
  };
  mode?: ESelectMode; // 选择模式
  filterCandidate?: (metrics: TMetric) => boolean; // 过滤候选列表，如：指标详情页排除当前指标自身
  disableCandidate?: (metrics: TMetric) => string | false; // 禁用候选项，返回 string 则展示禁用理由，返回 false 表示不禁用
  showUnAvailableSwitch?: boolean; // 显示不可用开关
  hideEmptyCategory?: boolean; // 是否隐藏空类目
  noPadding?: boolean; // 是否不显示左右内边距
  loading?: boolean; // 是否显示加载中
  displayType?: EDisplayType; // 展示类型
}

export default forwardRef(MetricTreePicker);
