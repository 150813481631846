export const list = {
  title: 'All Datasets',
  create: 'Create Dataset',
  dataset: {
    displayName: 'Display Name',
    name: 'Name',
  },
  sidebar: {
    title: 'Dataset',
  },
  creator: 'Owner',
  total: ({ total }: { total: string | number }) => `${total} Datasets`,
  lastModified: 'Last Modified',
  menu: {
    transfer: 'Transfer',
    view: 'View',
    share: 'Share',
    delete: 'Delete',
    editBaseInfo: 'Edit Basic Info',
    edit: 'Edit',
    deleteConfirm: 'Confirm',
    deleteConfirmOk: 'Confirm',
    deleteConfirmCancel: 'Cancel',
    replaceDatasource: 'Replace Data Source',
  },
  deleteSuccess: 'Deleted Successfully',
  handle: 'Actions',
  category: 'Category',
  batch: {
    transfer: (value: string) => `No transfer permission for dataset ${value}`,
    adjustCategory: (value: string) =>
      `No category adjustment permission for dataset ${value}`,
  },
};

export const type = {
  sql: 'Custom SQL',
  file: 'File Upload',
  table: 'Data Source Table',
};

export const detail = {
  editBaseInfo: 'Edit Basic Info',
  replaceDatasource: 'Replace Data Source',
  editBaseInfoSuccess: 'Updated Successfully',
  accelerate: 'Accelerate',
  edit: 'Edit',
  share: 'Share',
  overview: 'Overview',
  column: {
    default: 'Fields',
    displayName: 'Display Name',
    name: 'Name',
    desc: 'Description',
    dataType: 'Data Type',
    columnCount: ({ count }: { count: string | number }) => `${count} Fields`,
    dimension: 'Dimension',
  },
  relation: 'Relations',
  lineage: 'Lineage',
  authority: {
    default: 'Permission',
    userCount: ({ count }: { count: string | number }) => `${count} Users`,
    select: 'Users/User Groups',
  },
  baseInfo: 'Basic Info',
  datasetName: 'Name',
  datasetDisplayName: 'Display Name',
  creator: 'Owner',
  sourceType: 'Data Source',
  createTime: 'Created',
  updateTime: 'Last Modified',
  category: 'Category',
};

export const relation = {
  from: {
    default: ({ count }: { count: string | number }) =>
      `Referenced By(${count})`,
    dataset: 'Referenced',
  },
  to: {
    default: ({ count }: { count: string | number }) => `References(${count})`,
    dataset: {
      default: 'Referenced',
      required: 'Select referenced dataset',
    },
  },
  radio: {
    lists: 'List',
    graph: 'Graph',
  },
  create: {
    default: 'Add Relation',
    success: 'Relation created successfully',
  },
  current: {
    dataset: {
      default: 'Current',
      suffix: '(Current)',
    },
  },
  type: {
    default: 'Relation Type',
    one2one: '1 : 1',
    many2one: 'N : 1',
  },
  conditions: {
    default: 'Join Fields',
    add: 'Add Join Field',
  },
  creator: 'Owner',
  menu: {
    deleteConfirmContent: 'Are you sure to delete this relation?',
  },
  modal: {
    title: 'Related Data',
    ok: 'Confirm',
    cancel: 'Cancel',
    manual: 'Help Manual',
  },
  condition: {
    delete: 'Delete',
  },
  field: {
    select: 'Select Field',
  },
  error: {
    column: {
      required: 'Select field',
      dataType: 'Field data types do not match',
      missing: 'Field does not exist',
    },
    dataset: {
      missing: 'Referenced dataset does not exist',
    },
  },
  update: {
    success: 'Relation updated successfully',
  },
  delete: {
    success: 'Relation deleted successfully',
  },
  er: {
    currentDataset: 'Current Dataset',
    empty: 'Current dataset is not related to any dataset',
  },
};

export const add = {
  title: 'Select Data Source Table',
  stepOne: {
    datasource: {
      confirm: 'Next',
      cancel: 'Cancel',
    },
  },
  datasource: {
    title: 'Create from Table',
    desc: 'Create dataset using table from data source',
    headerTitle: 'Select Data Source Table',
  },
  SQL: {
    title: 'Create from SQL',
    desc: 'Create dataset using SQL query result',
    headerTitle: 'Create Dataset with SQL',
  },
  operate: {
    cancel: 'Cancel',
    create: 'Create',
    save: 'Save',
  },
  sidebar: {
    column: 'Fields',
    relation: 'Relations',
    createColumn: 'Create Field',
    editColumn: 'Edit Field',
  },
};

export const rightMenu = {
  typeConversion: {
    transformToDate: {
      default: 'Date',
      yyyy: 'yyyy',
      yyyymm: 'yyyy-MM',
      yyyymmdd: 'yyyy-MM-dd',
      ddmmyyyy: 'dd-MM-yyyy',
    },
    transformToDateTime: {
      default: 'DateTime',
      yyyymmddhhmmss: 'yyyy-MM-dd HH:mm:ss',
    },
    transformToText: 'Text',
    transformToBoolean: 'Boolean',
    transformToInt: 'Integer',
    transformToDecimal: 'Decimal',
    transformToDouble: 'Double',
    transformToJSON: 'JSON',
    default: 'Field Type',
  },
  rename: 'Rename',
  editDescription: 'Edit Description',
  edit: 'Edit',
  delete: 'Delete',
};

export const createColumnModal = {
  name: 'Name',
  description: 'Description',
  ast: 'Formula',
  cancel: 'Cancel',
  confirm: 'Confirm',
  save: 'Save',
  category: 'Dataset Category',
  displayName: 'Display Name',
  nameRequired: 'This field is required',
  nameExist: 'Field already exists',
  nameFormat:
    'Field name only supports English, numbers, underscore, max 50 characters',
  datasetNameFormat:
    'Only supports English, numbers and underscore, max 50 characters',
  datasetDisplayNameFormat:
    'Only supports Chinese, English, numbers, punctuation and special characters, max 50 characters',
};

export const createDataset = {
  editSql: {
    modal: {
      title: 'Edit SQL',
    },
  },
  selectType: {
    title: 'Select Creation Method',
  },
  selectDatasource: 'Select Data Source',
};

export const saveDataset = 'Save Dataset';

export const editColumn = {
  title: {
    columnName: 'Field Name',
    columnDisplayName: 'Field Display Name',
    sourceName: 'Source Data Field',
    dataType: 'Data Type',
    desc: 'Description',
    operate: {
      default: 'Actions',
      delete: 'Delete',
      edit: 'Edit',
      view: 'View',
    },
  },
  defaultColumnName: 'Calculated Field',
};

export const addRelation = {
  edit: 'Edit Relation',
  remove: 'Remove',
};

export const sidebar = {
  refreshSetting: 'Update Settings',
  replaceDatasource: 'Replace Data Source',
  synchronization: {
    column: 'Sync Fields',
    sql: 'Edit SQL',
    success: 'Sync Successfully',
    error: 'Sync Failed',
  },
  relations: {
    empty: 'No Related Data',
  },
  column: {
    empty: 'No Data',
  },
  sql: {
    result: 'SQL Query Result',
    tableNum: (num: number) => {
      return `${num} Source Data`;
    },
  },
  datasetCategoryRouter: {
    more: 'More',
    refresh: 'Refresh',
    createMore: 'Create',
    rename: 'Rename',
    delete: 'Delete',
    moveCategory: 'Move Category',
    authManage: 'Permission Management',
    all: (types: string) => `All ${types}`,
    search: 'Dataset Display Name, Name',
    category: 'Category',
    dataset: 'Dataset',
    createDataset: 'Create Dataset',
    createSubCategory: 'Create Category',
  },
};

export const deleteColumn = {
  confirm: 'Confirm',
  cancel: 'Cancel',
  confirmContent: 'Are you sure you want to delete this field?',
};

export const columns = {
  unEdit: 'Non-calculated fields do not support formula editing',
  unDelete: 'Non-calculated fields do not support formula deletion',
};

export const errorColumn = 'The following fields have errors:';

export const sourceColumnNotExist =
  'This column does not exist in the data source';

export const datasource = {
  empty: {
    default: 'No Data',
    desc: 'Select data from the data source on the left',
    sql: 'No Query Results',
  },
  title: 'Data Source',
};

export const sqlCreate = {
  query: {
    btn: 'Query',
    preview: 'Preview',
    rows: 'Rows',
    result: 'Query Result',
  },
};

export const overView = {
  title: 'Data Preview',
};

export const baseInfo = {
  metricNumber: 'Metrics',
  relationsNumber: 'Related Datasets',
  descTitle: 'Description',
  desc: {
    expand: 'Expand',
    putAway: 'Collapse',
  },
};

export const column = {
  emptyDesc: 'No fields match the search criteria, please try searching again',
};

export const partitionMappingModal = {
  partition: 'Partition',
  btn: 'Field Partition Mapping',
  noPartitionText:
    'The data source table has no partition field, partition mapping is not supported.',
  title: 'Partition Mapping Configuration',
  partitionColumnDesc: 'Current Date Partition Field',
  dateGranularity: 'Date Granularity',
  noPartitionColumnDesc:
    'Current partition field is not date type, date field partition mapping is not supported.',
  partitionMappingDesc:
    'Mapping relationship between date type fields and partitions, used for partition filter pushdown during queries.',
  noDateLikeColumn:
    'No date fields other than partition field, field partition mapping is not needed.',
  tableColumn: {
    open: 'Enable Mapping',
    column: 'Fields',
    value: 'Mapping Content',
  },
  success: 'Update Successfully',
  sqlForm: {
    title: 'Date Partition',
    required: 'This field is required',
    partitionColumn: 'Partition Field',
    bindPartitionColumn: 'Bind Partition Field',
    placeholder: {
      dateColumn: 'Select Date Type Field',
      granularity: 'Select Time Granularity',
      sourceTable: 'Select Data Source Table',
      bindPartitionColumn: 'Select Partition Field',
    },
  },
  partitionMappingTableEmptyDesc:
    'Please complete partition field configuration first',
};

export const overview = {
  noPermission:
    'No permission to view, please request permission from administrator',
};

export const dimension = {
  count: ({ count }: { count: number }) => `${count} Dimensions`,
  batchManage: 'Batch Edit',
  delete: 'Delete',
  editProperty: 'Edit Property',
  editCategory: 'Adjust Category',
  batchManageProperty: 'Batch Edit Property',
  onlyShowRequired: 'Only show required properties',
  table: {
    editSuccess: 'Updated Successfully',
    selectAll: 'Select All',
    add: 'Create',
    cancel: 'Cancel',
    save: 'Save',
    addBtn: 'Set dimensions',
    batchAdd: 'Batch Create Dimensions',
    addDesc: 'Select fields to bind with dimensions',
    search: 'Search',
    displayName: 'Display Name',
    name: 'Name',
    dimCode: 'Dimension Code',
    status: 'Status',
    description: 'Description',
    column: 'Bound Field',
    category: 'Dimension Category',
    operate: 'Actions',
    approving: 'Approving',
    delete: 'Delete',
    editCategory: 'Modify Category',
    errorMsg: 'Content is invalid, please modify and try submitting again.',
    success: 'Saved',
    deleteSuccess: 'Delete Successful',
    error: 'Failed',
  },
  calibrate: {
    notEmpty: 'This field is required',
    onlyOne: 'Name cannot be duplicated',
  },
};

export const accelerate = {
  create: 'Create ACC Plan',
  empty: {
    title: 'No Acceleration Plan Configured',
    btn: 'Configure Acceleration',
  },
  list: 'List',
  dag: 'Graph',
};
