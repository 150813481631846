export const filter = {
  tip: {
    default: '当前无筛选条件，选择筛选的内容',
    tooltip: '请选择数据集',
  },
  disabled: {
    reason: '无可用维度',
  },
  rank: {
    direction: {
      desc: '降序前',
      asc: '升序前',
    },
    unit: '名',
  },
  startWith: '以...开头',
  endWith: '以...结尾',
  notStartWith: '不以...开头',
  notEndWith: '不以...结尾',
  equalAttribute: '等于用户属性',
  notEqualAttribute: '不等于用户属性',
  containAttribute: '包含用户属性',
  notContainAttribute: '不包含用户属性',
  equal: '等于',
  notEqual: '排除',
  number: {
    range: '区间',
    to: '值',
    small: '无穷小',
    big: '无穷大',
  },
  date: {
    range: '范围',
    includeSelectedTime: '包含选中日期',
    includeNull: '包含 null 值',
    type: {
      rangeDate: '日期区间',
      specify: '指定日期',
      before: '早于',
      after: '晚于',
    },
    noLimit: '任意时段',
    dynamic: {
      default: '动态时间',
      decrease: '减少',
      add: '增加',
      second: '秒',
      minute: '分钟',
      hour: '小时',
      day: '日',
      week: '周',
      month: '月',
      quarter: '季',
      year: '年',
    },
    static: '静态时间',
    text: {
      minute: ({ length }: { length: string | number }) => `${length}分钟`,
      indefiniteHour: ({ length }: { length: string | number }) =>
        `${length}小时`,
      beforeYesterday: '前天',
      yesterday: '昨日',
      today: '今日',
      tomorrow: '明日',
      afterTomorrow: '后天',
      indefiniteDay: ({ length }: { length: string | number }) => `${length}日`,
      min: '前',
      max: '后',
      lastWeek: '上周',
      week: '本周',
      nextWeek: '下周',
      indefiniteWeek: ({ length }: { length: string | number }) =>
        `${length}周`,
      lastMonth: '上月',
      month: '本月',
      nextMonth: '下月',
      indefiniteMonth: ({ length }: { length: string | number }) =>
        `${length}月`,
      lastQuarter: '上季度',
      quarter: '本季度',
      nextQuarter: '下季度',
      indefiniteQuarter: ({ length }: { length: string | number }) =>
        `${length}季度`,
      lastYear: '去年',
      year: '今年',
      nextYear: '明年',
      indefiniteYear: ({ length }: { length: string | number }) =>
        `${length}年`,
    },
    before: {
      display: ' 早于',
      default: '早于',
    },
    after: {
      display: ' 晚于',
      default: '晚于',
    },
    sidebar: {
      today: '今日',
      yesterday: '昨日',
      recent7days: '近 7 日',
      recent30days: '近 30 日',
      recent90days: '近 90 日',
      recent180days: '近 180 日',
      thisweek: '本周',
      prevweek: '上周',
      thismonth: '本月',
      prevmonth: '上月',
      thisquarter: '本季度',
      prevquarter: '上季度',
      thisyear: '本年',
      prevyear: '去年',
    },
    tip: {
      to: '至',
    },
    week: '当周',
    month: '月',
    year: '年',
  },
  btn: {
    cancel: '取消',
    submit: '确定',
  },
  include: '包含',
  notInclude: '不包含',
  search: '搜索',
  emptyStr: '空',
  selectField: '选择值',
  json: {
    contains: '包含',
    notContains: '不包含',
    placeholder: '输入值',
  },
  input: {
    placeholder: '输入值',
  },
  selectedValue: ({ count }: { count: string | number }) =>
    `仅显示选中值(${count})`,
  showAllValue: '显示全部值',
  selectedOptionMaxLength: (count: number) => `仅显示前${count}个候选值`,
  addValueManually: '未匹配到搜索结果，点击该值手动添加',
  allOptions: '全部',
  datePart: {
    hour: '时',
    minute: '分',
    second: '秒',
  },
  multipleInput: {
    default: '批量输入',
    placeholder: '输入筛选的值',
    tip: '每行一个值，至多 1000 个（系统会自动剔除重复和不合法的值）',
    textRule: '文本: 非空内容',
    numberRule: '数值: 支持整数和小数，如: 123，12.3',
    dateRule: '日期: 使用 - 划分年月日，如: 2021-05-20 10:00:00',
  },
  dynamic: {
    currentTime: '当前时间',
    suffix: {
      operator: {
        add: '后',
        sub: '前',
      },
      default: '至今',
    },
    now: '当前时间',
    today: '今天',
    yesterday: '昨天',
    tomorrow: '明天',
    week: '本周',
    lastWeek: '上周',
    nextWeek: '下周',
    month: '本月',
    lastMonth: '上月',
    nextMonth: '下月',
    quarter: '本季度',
    lastQuarter: '上季度',
    nextQuarter: '下季度',
    year: '本年',
    lastYear: '去年',
    nextYear: '明年',
    prefix: '未来',
    error: {
      min: '开始时间要早于结束时间',
    },
  },
  metricTime: {
    cancel: '取消',
    ok: '确定',
  },
  fixed: {
    default: '任意时间段',
  },
  range: {
    error: '开始时间和结束时间不能为空',
  },
  attribute: {
    placeholder: '请选择用户属性',
  },
  placeholder: '请选择',
};

export const topMenu = {
  filter: {
    btnWord: '添加筛选',
    title: '筛选',
  },
  modal: {
    editDescription: '编辑描述',
    cancel: '取消',
    confirm: '确定',
  },
};

export const sidebar = {
  noDataTip: '无数据',
  colTypeIsText: '文本',
  colTypeIsInt: '整数',
  colTypeIsDecimal: '高精度小数',
  colTypeIsDouble: '小数',
  colTypeIsDate: '日期',
  colTypeIsTimestamp: '日期时间',
  colTypeIsBoolean: '布尔',
  colTypeIsJson: 'JSON',
  label: {
    specifyDate: {
      current: {
        startWithoutDateTag: (unitText: string, granularityText: string) =>
          `本${unitText}的第一${granularityText}`,
        endWithoutDateTag: (unitText: string, granularityText: string) =>
          `本${unitText}的最后一${granularityText}`,
        startWithDateTag: (
          dateTag: string,
          unitText: string,
          granularityText: string,
        ) => `本${unitText}的第一个${granularityText}(${dateTag})`,
        endWithDateTag: (
          dateTag: string,
          unitText: string,
          granularityText: string,
        ) => `本${unitText}的最后一个${granularityText}(${dateTag})`,
      },
      past: {
        startWithoutDateTag: (
          unitText: string,
          granularityText: string,
          value: number,
        ) => `${value}${unitText}前的第一${granularityText}`,
        endWithoutDateTag: (
          unitText: string,
          granularityText: string,
          value: number,
        ) => `${value}${unitText}前的最后一${granularityText}`,
        startWithDateTag: (
          dateTag: string,
          unitText: string,
          value: number,
          granularityText: string,
        ) => `${value}${unitText}前的第一个${granularityText}(${dateTag})`,
        endWithDateTag: (
          dateTag: string,
          unitText: string,
          value: number,
          granularityText: string,
        ) => `${value}${unitText}前的最后一个${granularityText}(${dateTag})`,
      },
    },
    relativeDate: {
      currentWithDateTag: (granularityText: string, dateTag: string) =>
        `当${granularityText}(${dateTag})`,
      currentWithoutDateTag: (granularityText: string) =>
        `当${granularityText}`,
      suffix: {
        future: '后',
        past: '前',
      },
    },
    recentN: {
      prefix: {
        future: '未来',
        past: '过去',
      },
      connective: '至',
      current: '本',
      today: '今',
    },
    mtd: {
      today: '今',
      current: '本',
      connective: '至',
      suffix: {
        past: '前',
      },
    },
  },
  dynamic: {
    current: (granularityText: string) => `当${granularityText}`,
    suffix: {
      future: '后',
      past: '前',
    },
  },
  after: '晚于',
  before: '早于',
  range: '至',
  outside: '范围之外',
  tableColumn: {
    search: {
      placeholder: '搜索',
    },
    select: {
      placeholder: '请选择',
    },
  },
};

export const sort = {
  search: '搜索',
};
