export const filter = {
  tip: {
    default: 'No filter conditions, select content to filter',
    tooltip: 'Select dataset',
  },
  disabled: {
    reason: 'No available dimensions',
  },
  rank: {
    direction: {
      desc: 'Top by descending',
      asc: 'Top by ascending',
    },
    unit: '',
  },
  startWith: 'Starts with',
  endWith: 'Ends with',
  notStartWith: 'Not start with',
  notEndWith: 'Not end with',
  equalAttribute: 'Equals user attribute',
  notEqualAttribute: 'Not equals user attribute',
  containAttribute: 'Contains user attribute',
  notContainAttribute: 'Not contain user attribute',
  equal: 'Equals',
  notEqual: 'Exclude',
  number: {
    range: 'Range',
    to: 'Value',
    small: 'Negative infinity',
    big: 'Positive infinity',
  },
  date: {
    range: 'Range',
    includeSelectedTime: 'Include selected date',
    includeNull: 'Include null values',
    type: {
      rangeDate: 'Date range',
      specify: 'Specify date',
      before: 'Before',
      after: 'After',
    },
    noLimit: 'Any period',
    dynamic: {
      default: 'Dynamic time',
      decrease: 'Decrease',
      add: 'Add',
      second: 'Second',
      minute: 'Minute',
      hour: 'Hour',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      quarter: 'Quarter',
      year: 'Year',
    },
    static: 'Static time',
    text: {
      minute: ({ length }: { length: string | number }) => `${length} minutes`,
      indefiniteHour: ({ length }: { length: string | number }) =>
        `${length} hours`,
      beforeYesterday: 'Day before yesterday',
      yesterday: 'Yesterday',
      today: 'Today',
      tomorrow: 'Tomorrow',
      afterTomorrow: 'Day after tomorrow',
      indefiniteDay: ({ length }: { length: string | number }) =>
        `${length} days`,
      min: 'Before',
      max: 'After',
      lastWeek: 'Last week',
      week: 'This week',
      nextWeek: 'Next week',
      indefiniteWeek: ({ length }: { length: string | number }) =>
        `${length} weeks`,
      lastMonth: 'Last month',
      month: 'This month',
      nextMonth: 'Next month',
      indefiniteMonth: ({ length }: { length: string | number }) =>
        `${length} months`,
      lastQuarter: 'Last quarter',
      quarter: 'This quarter',
      nextQuarter: 'Next quarter',
      indefiniteQuarter: ({ length }: { length: string | number }) =>
        `${length} quarters`,
      lastYear: 'Last year',
      year: 'This year',
      nextYear: 'Next year',
      indefiniteYear: ({ length }: { length: string | number }) =>
        `${length} years`,
    },
    before: {
      display: ' Before',
      default: 'Before',
    },
    after: {
      display: ' After',
      default: 'After',
    },
    sidebar: {
      today: 'Today',
      yesterday: 'Yesterday',
      recent7days: 'Last 7 days',
      recent30days: 'Last 30 days',
      recent90days: 'Last 90 days',
      recent180days: 'Last 180 days',
      thisweek: 'This week',
      prevweek: 'Last week',
      thismonth: 'This month',
      prevmonth: 'Last month',
      thisquarter: 'This quarter',
      prevquarter: 'Last quarter',
      thisyear: 'This year',
      prevyear: 'Last year',
    },
    tip: {
      to: 'to',
    },
    week: 'Current week',
    month: 'Month',
    year: 'Year',
  },
  btn: {
    cancel: 'Cancel',
    submit: 'Confirm',
  },
  include: 'Include',
  notInclude: 'Not include',
  search: 'Search',
  emptyStr: 'Empty',
  selectField: 'Select value',
  json: {
    contains: 'Contains',
    notContains: 'Not contains',
    placeholder: 'Enter value',
  },
  input: {
    placeholder: 'Enter value',
  },
  selectedValue: ({ count }: { count: string | number }) =>
    `Only show selected values(${count})`,
  showAllValue: 'Show all values',
  selectedOptionMaxLength: (count: number) =>
    `Only show first ${count} candidate values`,
  addValueManually: 'No search results found, click to add this value manually',
  allOptions: 'All',
  datePart: {
    hour: 'Hour',
    minute: 'Minute',
    second: 'Second',
  },
  multipleInput: {
    default: 'Batch input',
    placeholder: 'Enter filter values',
    tip: 'One value per line, up to 1000 values (system will automatically remove duplicates and invalid values)',
    textRule: 'Text: Non-empty content',
    numberRule: 'Number: Supports integers and decimals, e.g.: 123, 12.3',
    dateRule:
      'Date: Use - to separate year, month and day, e.g.: 2021-05-20 10:00:00',
  },
  dynamic: {
    currentTime: 'Current time',
    suffix: {
      operator: {
        add: 'After',
        sub: 'Before',
      },
      default: 'Until now',
    },
    now: 'Current time',
    today: 'Today',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    week: 'This week',
    lastWeek: 'Last week',
    nextWeek: 'Next week',
    month: 'This month',
    lastMonth: 'Last month',
    nextMonth: 'Next month',
    quarter: 'This quarter',
    lastQuarter: 'Last quarter',
    nextQuarter: 'Next quarter',
    year: 'This year',
    lastYear: 'Last year',
    nextYear: 'Next year',
    prefix: 'Future',
    error: {
      min: 'Start time must be earlier than end time',
    },
  },
  metricTime: {
    cancel: 'Cancel',
    ok: 'Confirm',
  },
  fixed: {
    default: 'Any time period',
  },
  range: {
    error: 'Start time and end time cannot be empty',
  },
  attribute: {
    placeholder: 'Select user attribute',
  },
  placeholder: 'Select',
};

export const topMenu = {
  filter: {
    btnWord: 'Add Filter',
    title: 'Filter',
  },
  modal: {
    editDescription: 'Edit Description',
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
};

export const sidebar = {
  noDataTip: 'No data',
  colTypeIsText: 'Text',
  colTypeIsInt: 'Integer',
  colTypeIsDecimal: 'High Precision Decimal',
  colTypeIsDouble: 'Decimal',
  colTypeIsDate: 'Date',
  colTypeIsTimestamp: 'DateTime',
  colTypeIsBoolean: 'Boolean',
  colTypeIsJson: 'JSON',
  label: {
    specifyDate: {
      current: {
        startWithoutDateTag: (unitText: string, granularityText: string) =>
          `First ${granularityText} of this ${unitText}`,
        endWithoutDateTag: (unitText: string, granularityText: string) =>
          `Last ${granularityText} of this ${unitText}`,
        startWithDateTag: (
          dateTag: string,
          unitText: string,
          granularityText: string,
        ) => `First ${granularityText} of this ${unitText}(${dateTag})`,
        endWithDateTag: (
          dateTag: string,
          unitText: string,
          granularityText: string,
        ) => `Last ${granularityText} of this ${unitText}(${dateTag})`,
      },
      past: {
        startWithoutDateTag: (
          unitText: string,
          granularityText: string,
          value: number,
        ) => `First ${granularityText} ${value} ${unitText}s ago`,
        endWithoutDateTag: (
          unitText: string,
          granularityText: string,
          value: number,
        ) => `Last ${granularityText} ${value} ${unitText}s ago`,
        startWithDateTag: (
          dateTag: string,
          unitText: string,
          value: number,
          granularityText: string,
        ) => `First ${granularityText} ${value} ${unitText}s ago(${dateTag})`,
        endWithDateTag: (
          dateTag: string,
          unitText: string,
          value: number,
          granularityText: string,
        ) => `Last ${granularityText} ${value} ${unitText}s ago(${dateTag})`,
      },
    },
    relativeDate: {
      currentWithDateTag: (granularityText: string, dateTag: string) =>
        `Current ${granularityText}(${dateTag})`,
      currentWithoutDateTag: (granularityText: string) =>
        `Current ${granularityText}`,
      suffix: {
        future: 'Later',
        past: 'Ago',
      },
    },
    recentN: {
      prefix: {
        future: 'Next',
        past: 'Last',
      },
      connective: 'to',
      current: 'This',
      today: 'Today',
    },
    mtd: {
      today: 'Today',
      current: 'This',
      connective: 'to',
      suffix: {
        past: 'ago',
      },
    },
  },
  dynamic: {
    current: (granularityText: string) => `Current ${granularityText}`,
    suffix: {
      future: 'Later',
      past: 'Ago',
    },
  },
  after: 'After',
  before: 'Before',
  range: 'to',
  outside: 'Outside range',
  tableColumn: {
    search: {
      placeholder: 'Search',
    },
    select: {
      placeholder: 'Select',
    },
  },
};

export const sort = {
  search: 'Search',
};
