import { ResizableBox } from 'react-resizable';
import t from '@/locales';
import { ReactComponent as CloseSvg } from '@/assets/icon/Close-light-line.svg';
import { ReactComponent as CollapseAllSvg } from '@/assets/icon/Collapse-all-light-line.svg';
import styles from './index.less';
import MetricTreePicker, {
  IProps as IMetricTreePickerProps,
} from '../../MetricTreePicker';
import { EDropdownCloseReason, ESelectMode } from '../../types';
import { Button, IconButton } from '@aloudata/aloudata-design';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TMetric } from '@/typings/metric';
import classNames from 'classnames';
import { ITreePickerRef } from '../../TreePicker';

export default function MetricDropdownContent(props: IProps) {
  const {
    mode,
    picked: propsPicked,
    onPick,
    onChange,
    onClose,
    title = t.common.metricPicker.pickMetric,
    ...otherProps
  } = props;
  const [tmpPickedData, setTmpPickedData] = useState<IProps['picked']>([]);

  useEffect(() => {
    if (mode === ESelectMode.MULTIPLE_CHECK) {
      setTmpPickedData(propsPicked);
    }
  }, [mode, propsPicked]);

  const onChangeTmpPicked = useCallback(
    (newPicked: TMetric[]) => {
      setTmpPickedData(newPicked);
      if (mode === ESelectMode.MULTIPLE_CHECK) {
        onPick?.(newPicked);
      }
    },
    [mode, onPick],
  );

  const treePickerRef = useRef<ITreePickerRef<TMetric>>(null);

  const onApplyTmpPicked = useCallback(() => {
    // 只有 ADD 模式下，才会调用这个方法
    onPick?.(tmpPickedData || []);
    onClose(EDropdownCloseReason.SELECT);
    setTmpPickedData([]);
  }, [tmpPickedData, onPick, onClose]);

  const onValueChange = useCallback(
    (val: TMetric) => {
      onClose(EDropdownCloseReason.SELECT);
      onChange?.(val);
    },
    [onChange, onClose],
  );

  const [isResizing, setIsResizing] = useState(false);

  const MIN_WIDTH = 264;
  const MIN_HEIGHT = 400;
  const MAX_WIDTH = 500;
  const MAX_HEIGHT = 600;

  return (
    <ResizableBox
      width={264}
      height={400}
      minConstraints={[MIN_WIDTH, MIN_HEIGHT]}
      maxConstraints={[MAX_WIDTH, MAX_HEIGHT]}
      onResizeStart={() => {
        setIsResizing(true);
      }}
      onResizeStop={() => {
        setIsResizing(false);
      }}
    >
      <div
        className={classNames(styles.wrap, {
          [styles.isResizing]: isResizing,
        })}
      >
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div className={styles.btns}>
            <IconButton
              icon={<CollapseAllSvg size={20} />}
              tooltip={{ title: t.common.metricPicker.collapseAll }}
              onClick={() => {
                // 收起所有类目
                treePickerRef.current?.collapseAll();
              }}
            />
            <IconButton
              icon={<CloseSvg size={20} />}
              tooltip={{ title: t.common.metricPicker.close }}
              onClick={() => {
                onClose(EDropdownCloseReason.CLOSE);
              }}
            />
          </div>
        </div>
        <div className={styles.content}>
          <MetricTreePicker
            {...otherProps}
            mode={mode}
            ref={treePickerRef}
            picked={tmpPickedData}
            onPick={onChangeTmpPicked}
            onChange={onValueChange}
          />
        </div>
        {mode === ESelectMode.MULTIPLE_ADD && (
          <div className={styles.footer}>
            <Button type="primary" onClick={onApplyTmpPicked} size="small">
              {t.common.metricPicker.apply}
            </Button>
          </div>
        )}
      </div>
    </ResizableBox>
  );
}

interface IProps extends IMetricTreePickerProps {
  onClose: (reason: EDropdownCloseReason) => void;
  title?: string;
}
