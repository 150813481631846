import { ReactComponent as FolderSvg } from '@/assets/icon/Folder-light-line.svg';
import { flattenChildren, getCategoryId } from '@/common/utils';
import { ICategoryTreeNode } from '@/pages/Manage/CategoryManage/categoryHelper';
import _ from 'lodash';
import { ICategoryLikeItem, IDataItem, ITreeNode } from '../types';
import { NO_CATEGORY } from '@/constants';
/**
 * 将实体列表放入类目树中，生成一个带类目和指标的树结构
 */
export function fillDataItemsIntoTreeData<TDataItem extends ICategoryLikeItem>(
  hasAllData: boolean, // 是否已经加载到全量数据
  dataItems: IDataItem<TDataItem>[],
  categoryTree: ICategoryTreeNode[],
  iconSize: number,
  getKey: (dataItem: TDataItem) => string,
  disabledKeys: string[] = [],
): ITreeNode<TDataItem>[] {
  // 构建 {[类目 ID]: 指标列表} Map
  const map: {
    [key: string]: IDataItem<TDataItem>[];
  } = {};
  _.forEach(dataItems, (dataItem) => {
    const category = getCategoryId(dataItem.data);
    if (map[category]) {
      map[category].push(dataItem);
    } else {
      map[category] = [dataItem];
    }
  });
  const res = _.map(categoryTree, (category) =>
    makeTreeNode(hasAllData, category, map, iconSize, disabledKeys, getKey),
  );
  // 将没有类目的项，如“指标日期”，添加到树结构的顶部
  const noCategoryNodes = _.filter(dataItems, (dataItem) => {
    return _.isEqual(dataItem.data.category, NO_CATEGORY);
  }).map((dataItem) => makeLeafNode(dataItem, disabledKeys, getKey));

  return [...noCategoryNodes, ...res];
}

export function fillDataItemsIntoListData<TDataItem extends ICategoryLikeItem>(
  dataItems: IDataItem<TDataItem>[],
  getKey: (dataItem: TDataItem) => string,
  disabledKeys: string[] = [],
): ITreeNode<TDataItem>[] {
  return dataItems.map((dataItem) => {
    return makeLeafNode(dataItem, disabledKeys, getKey);
  });
}

function makeTreeNode<TDataItem extends ICategoryLikeItem>(
  hasAllData: boolean,
  categoryItem: ICategoryTreeNode,
  map: {
    [key: string]: IDataItem<TDataItem>[];
  },
  iconSize: number,
  disabledKeys: string[],
  getKey: (dataItem: TDataItem) => string,
): ITreeNode<TDataItem> {
  const subCategoryList = _.map(categoryItem.children, (item) =>
    makeTreeNode(hasAllData, item, map, iconSize, disabledKeys, getKey),
  );
  const dataItemList = map[categoryItem.key] || [];
  const leafNodes = _.map(dataItemList, (dataItem) => {
    return makeLeafNode(dataItem, disabledKeys, getKey);
  });
  const children = [...subCategoryList, ...leafNodes];
  const category: ITreeNode<TDataItem> = {
    key: categoryItem.key,
    title: categoryItem.title,
    // 已有全量数据时，才根据子项判断是否为叶子节点
    isLeaf: hasAllData ? !children.length : false,
    data: categoryItem,
    icon: <FolderSvg size={iconSize} />,
    isCategory: true,
    // 已有全量数据时，类目才显示可勾选，否则是动态加载的子项的原因，类目不能显示可勾选
    checkable: hasAllData,
    children,
  };
  return category;
}

function makeLeafNode<TDataItem extends ICategoryLikeItem>(
  dataItem: IDataItem<TDataItem>,
  disabledKeys: string[],
  getKey: (dataItem: TDataItem) => string,
): ITreeNode<TDataItem> {
  return {
    key: getKey(dataItem.data),
    // 防止树组件节点上 hover 后展示出 title
    title: '',
    data: dataItem,
    isCategory: false,
    isLeaf: true,
    disabled: disabledKeys.includes(getKey(dataItem.data)),
  };
}

export function getAllCategoryKeys<T extends ICategoryLikeItem>(
  tree: ITreeNode<T>[],
) {
  return flattenChildren<ITreeNode<T>>(tree)
    .filter((node) => node.isCategory)
    .map((node) => node.key);
}

/**
 * 排除空的类目
 */
export function excludeEmptyCategory<
  T extends {
    children?: T[];
    isCategory?: boolean;
  },
>(treeData: T[]): T[] {
  return _.map(treeData, (node) => {
    if (!node.isCategory) {
      return node;
    }
    // 是类目的情况
    const children = excludeEmptyCategory(node.children || []);
    // 类目下没有维度
    if (children.length === 0) {
      return null;
    }
    return {
      ...node,
      children,
    };
  }).filter((node): node is T => !!node);
}

export function getAllLeafKeys<T extends ICategoryLikeItem>(
  currNodeData: ITreeNode<T>,
) {
  return flattenChildren<ITreeNode<T>>(currNodeData.children || [])
    .filter((node) => !node.isCategory && !node.disabled)
    .map((node) => node.key);
}

export const findCategoryPath = (
  nodes: ICategoryTreeNode[],
  targetId: string,
  path: string[] = [],
): string[] => {
  for (const node of nodes) {
    if (node.key === targetId) {
      return [...path, node.key];
    }
    if (node.children) {
      const foundPath = findCategoryPath(node.children, targetId, [
        ...path,
        node.key,
      ]);
      if (foundPath.length) {
        return foundPath;
      }
    }
  }
  return [];
};
