import { graphql, rest } from 'msw';
import _, { times } from 'lodash';
import {
  EAcceleratePlanType,
  EManualRefreshTaskStatus,
  EMtColumnType,
  ERefreshType,
  ETaskInstanceStatus,
} from '../../src/typings/accelerate';
import {
  getMockAcceleratePlanBase,
  getMockTaskInstance,
  getMockAcceleratePlanDetail,
  mockDatasets,
  getMaterializedTableMockData,
  getPreviewAcceleratePlans,
  getMtLineage,
  generateMockAccelerateResultPlanDetail,
  generateMockAccelerateResultPlanListItem,
} from './mock';
import {
  dimensionList,
  getMockSimpleMetricInfo,
  getSimpleMetricList,
} from '../utils/getSimpleMetricList';
import { getUniqId } from '../../src/common/utils';
import { EOriginDataType } from '../../src/typings';
import { IQueryMtLineageRequest } from '../../src/services/accelerate/types';
import { mockCategoryList } from '../category/mockData';

export const accelerate = [
  graphql.query('queryAcceleratePlanAvailableMetrics', (req, res, ctx) => {
    const metricList = getSimpleMetricList();
    return res(
      ctx.data({
        queryAcceleratePlanAvailableMetrics: {
          metrics: metricList.slice(0, 15),
          disabledMetrics: metricList.slice(0, 4).map((metricItem) => ({
            code: metricItem.code,
            message: `message_${metricItem.code}`,
          })),
        },
      }),
    );
  }),
  graphql.query('previewAccelerate', (req, res, ctx) => {
    return res(
      ctx.data({
        previewAccelerate: getPreviewAcceleratePlans(),
      }),
    );
  }),
  graphql.mutation('saveAcceleratePlanV2', (req, res, ctx) => {
    return res(
      ctx.data({
        saveAcceleratePlanV2: true,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.query('queryMtLineage', (req, res, ctx) => {
    const { id, inputStep, outputStep } = req.variables
      .request as IQueryMtLineageRequest;
    return res(
      ctx.data({
        queryMtLineage: getMtLineage(id, Number(inputStep), Number(outputStep)),
      }),
    );
  }),

  graphql.query('queryMtLineageByDataset', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMtLineageByDataset: getPreviewAcceleratePlans().noRecommendDag,
      }),
    );
  }),

  graphql.query('queryAcceleratePlanAvailableDimensions', (req, res, ctx) => {
    const dimensions = dimensionList;
    return res(
      ctx.data({
        queryAcceleratePlanAvailableDimensions: dimensions.slice(0, 10),
      }),
    );
  }),
  graphql.query('queryPlanGranularityOfDimensions', (req, res, ctx) => {
    const dimensions = req.variables.request.dimensions;
    return res(
      ctx.data({
        queryPlanGranularityOfDimensions: dimensions
          .slice(0, 3)
          .map((item) => item.name),
      }),
    );
  }),
  graphql.query('queryDimensionConflictAcceleratePlan', (req, res, ctx) => {
    const plans = req.variables.request.acceleratePlanInputs;
    let data: Array<{ idPair: string[] }> = [];
    if (plans.length === 4) {
      data = [
        { idPair: [plans[0].id, plans[1].id] },
        { idPair: [plans[2].id, plans[3].id] },
      ];
    }
    return res(ctx.data({ queryDimensionConflictAcceleratePlan: data }));
  }),
  graphql.query('queryMaterializedTableList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMaterializedTableList: {
          data: Array.from({ length: 10 }).map((item, index) => ({
            id: `guid_${index}`,
            name: `materialized_table_${index}`,
            taskName: `task_name_${index}`,
            // 时间戳
            dataStartTime: Date.now(),
            type: _.sample([EAcceleratePlanType.RAW, EAcceleratePlanType.AGG]),
            dataEndTime: Date.now(),
            size: 1024,
            lastRefreshTime: Date.now(),
            latestInstance: {
              id: `task_instance_id_${index}`,
              status: _.sample([
                ETaskInstanceStatus.SUCCESS,
                ETaskInstanceStatus.FAILED,
                ETaskInstanceStatus.RUNNING,
              ]),
              startTime: Date.now(),
              endTime: Date.now(),
              plan: getMockAcceleratePlanBase(index, EAcceleratePlanType.AGG),
            },
            acceleratePlan: getMockAcceleratePlanBase(
              index,
              EAcceleratePlanType.AGG,
            ),
            dataset: mockDatasets[0],
          })),
          pageSize: 10,
          pageNumber: 1,
          total: 20,
        },
      }),
      ctx.delay(1000),
    );
  }),

  graphql.query('queryMtRefreshRecordList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMtRefreshRecordList: {
          data: Array.from({ length: 20 }).map((item, index) =>
            getMockTaskInstance(),
          ),
          pageSize: 10,
          pageNumber: 1,
          total: 50,
        },
      }),
      ctx.delay(1000),
    );
  }),
  graphql.query('queryAcceleratePlanListV2', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAcceleratePlanListV2: {
          data: _.range(10).map((index) =>
            getMockAcceleratePlanDetail(
              index,
              _.sample([EAcceleratePlanType.RAW, EAcceleratePlanType.AGG]),
            ),
          ),
          hasNext: true,
          total: 20,
          pageNumber: 1,
          pageSize: 10,
        },
      }),
    );
  }),
  graphql.query('queryAggAcceleratePlanFullRefreshMetrics', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAggAcceleratePlanFullRefreshMetrics: [],
      }),
    );
  }),
  graphql.query('queryRawAcceleratePlanIsFullRefresh', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRawAcceleratePlanIsFullRefresh: _.sample([true, false]),
      }),
    );
  }),
  graphql.query('queryManualTaskEffectTables', (req, res, ctx) => {
    return res(
      ctx.data({
        queryManualTaskEffectTables: Array.from({ length: 10 })
          .fill(0)
          .map((item, index) => ({
            id: getUniqId(),
            name: 'table' + index,
            dataStartTIme: Date.now(),
            dataEndTime: Date.now(),
            columns: Array.from({ length: 10 })
              .fill(0)
              .map((item, index) => ({
                name: 'column' + index,
                originDataType: EOriginDataType.CHAR,
                isPartition: false,
                columnType: _.sample([
                  EMtColumnType.DIMENSION,
                  EMtColumnType.RAW,
                  EMtColumnType.METRIC,
                ]),
                aggMapping: '',
                rawMapping: {
                  columnName: '',
                  datasetName: '',
                },
              })),
            type: EAcceleratePlanType.RAW,
            createTime: Date.now(),
            updateTime: Date.now(),
            size: 12312313123,
            lastRefreshTime: Date.now(),
            latestInstance: {},
          })),
      }),
    );
  }),
  graphql.query('queryManualTaskList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryManualTaskList: {
          data: Array.from({ length: 10 })
            .fill(0)
            .map((item, index) => ({
              id: getUniqId(),
              name: 'task' + index,
              plan: getMockAcceleratePlanBase(index),
              startTime: Date.now(),
              endTime: Date.now(),
              metrics: Array.from({ length: 10 })
                .fill(0)
                .map((v, i) => getMockSimpleMetricInfo('code' + i, i)),
              status: _.sample(Object.values(EManualRefreshTaskStatus)),
            })),
          pageSize: 10,
          pageNumber: 1,
          total: 1000,
          hasNext: true,
        },
      }),
    );
  }),

  graphql.query('queryManualTaskInstanceList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryManualTaskInstanceList: Array.from({ length: 10 })
          .fill(0)
          .map((item, index) => ({
            id: getUniqId(),
            table: getMaterializedTableMockData(),
            startPartition: '2022-05-10',
            endPartition: '2022-05-13',
            startTime: Date.now(),
            endTime: Date.now(),
            authority: {
              canEdit: true,
              canDelete: true,
            },
            status: _.sample(Object.values(ETaskInstanceStatus)),
          })),
      }),
    );
  }),

  graphql.mutation('refreshAcceleratePlan', (req, res, ctx) => {
    return res(
      ctx.data({
        refreshAcceleratePlan: true,
      }),
    );
  }),
  graphql.query('queryMtPartitionList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMtPartitionList: {
          data: Array.from({ length: 20 }).map((item, index) => ({
            value: Date.now().toString(),
            rowCount: index,
            size: 1024,
            refreshTime: Date.now(),
          })),
          pageSize: 20,
          pageNumber: 1,
          total: 150,
        },
      }),
      ctx.delay(1000),
    );
  }),
  // 获取实例详情
  graphql.query('queryTaskInstanceDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryTaskInstanceDetail: {
          id: getUniqId(),
          status: _.sample([
            ETaskInstanceStatus.SUCCESS,
            ETaskInstanceStatus.FAILED,
            ETaskInstanceStatus.RUNNING,
          ]),
          startPartition: '2011-01-03',
          endPartition: '2022-02-01',
          startTime: new Date().getTime(),
          endTime: new Date().getTime() + 10000,
          plan: getMockAcceleratePlanDetail(0),
          sql: 'sql content',
          log: 'log content',
          type: ERefreshType.MANUAL,
          materializedTable: getMaterializedTableMockData(),
          waitTime: 30,
          authority: {
            canEdit: true,
            canDelete: true,
          },
        },
      }),
      ctx.delay(1000),
    );
  }),

  // queryReRunTaskInstanceEffectTables
  graphql.query('queryRefreshMtEffectTables', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRefreshMtEffectTables: Array.from({ length: 10 })
          .fill(0)
          .map((item, index) => ({
            id: getUniqId(),
            name: 'table' + index,
            dataStartTIme: Date.now(),
            dataEndTime: Date.now(),
            columns: Array.from({ length: 10 })
              .fill(0)
              .map((item, index) => ({
                name: 'column' + index,
                originDataType: EOriginDataType.CHAR,
                isPartition: false,
                columnType: _.sample([
                  EMtColumnType.DIMENSION,
                  EMtColumnType.RAW,
                  EMtColumnType.METRIC,
                ]),
                aggMapping: '',
                rawMapping: {
                  columnName: '',
                  datasetName: '',
                },
              })),
            type: EAcceleratePlanType.RAW,
            createTime: Date.now(),
            updateTime: Date.now(),
            size: 12312313123,
            lastRefreshTime: Date.now(),
            latestInstance: {},
          })),
      }),
    );
  }),
  graphql.query('queryReRunTaskInstanceEffectTables', (req, res, ctx) => {
    return res(
      ctx.data({
        queryReRunTaskInstanceEffectTables: Array.from({ length: 10 })
          .fill(0)
          .map((item, index) => ({
            id: getUniqId(),
            name: 'table' + index,
            dataStartTIme: Date.now(),
            dataEndTime: Date.now(),
            columns: Array.from({ length: 10 })
              .fill(0)
              .map((item, index) => ({
                name: 'column' + index,
                originDataType: EOriginDataType.CHAR,
                isPartition: false,
                columnType: _.sample([
                  EMtColumnType.DIMENSION,
                  EMtColumnType.RAW,
                  EMtColumnType.METRIC,
                ]),
                aggMapping: '',
                rawMapping: {
                  columnName: '',
                  datasetName: '',
                },
              })),
            type: EAcceleratePlanType.RAW,
            createTime: Date.now(),
            updateTime: Date.now(),
            size: 12312313123,
            lastRefreshTime: Date.now(),
            latestInstance: {},
          })),
      }),
    );
  }),
  graphql.query('queryMaterializedTableDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMaterializedTableDetail: getMaterializedTableMockData(),
      }),
    );
  }),
  graphql.mutation('refreshMt', (req, res, ctx) => {
    return res(
      ctx.data({
        refreshMt: true,
      }),
    );
  }),
  graphql.mutation('setSuccessTaskInstance', (req, res, ctx) => {
    return res(
      ctx.data({
        setSuccessTaskInstance: true,
      }),
    );
  }),
  graphql.mutation('cancelTaskInstance', (req, res, ctx) => {
    return res(
      ctx.data({
        cancelTaskInstance: true,
      }),
    );
  }),
  graphql.mutation('reRunTaskInstance', (req, res, ctx) => {
    return res(
      ctx.data({
        reRunTaskInstance: true,
      }),
    );
  }),
  graphql.mutation('refreshAcceleratePlanV2', (req, res, ctx) => {
    return res(
      ctx.data({
        refreshAcceleratePlanV2: true,
      }),
    );
  }),
  graphql.query('validateAcceleratePlan', (req, res, ctx) => {
    return res(
      ctx.data({
        validateAcceleratePlan: {
          result: _.sample([true, false]),
          errorMsg: 'error message',
        },
      }),
    );
  }),
  // 应用加速相关
  graphql.mutation('saveAccelerateResultPlan', (req, res, ctx) => {
    return res(
      ctx.data({
        saveAccelerateResultPlan: true,
      }),
    );
  }),
  graphql.mutation('deleteAccelerateResultPlan', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteAccelerateResultPlan: true,
      }),
    );
  }),
  graphql.mutation('refreshAccelerateResultPlan', (req, res, ctx) => {
    return res(
      ctx.data({
        refreshAccelerateResultPlan: true,
      }),
    );
  }),
  graphql.query('queryAccelerateResultPlanList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAccelerateResultPlanList: {
          data: mockCategoryList
            .map((item) => {
              return _.times(3, () =>
                generateMockAccelerateResultPlanListItem(item),
              );
            })
            .flat(),
          pageSize: 20,
          pageNumber: 1,
          total: 150,
        },
      }),
    );
  }),
  graphql.query('queryAccelerateResultPlanByViewId', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAccelerateResultPlanByViewId:
          generateMockAccelerateResultPlanListItem(),
      }),
    );
  }),
  graphql.query('queryAccelerateResultPlanDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAccelerateResultPlanDetail:
          generateMockAccelerateResultPlanDetail(),
      }),
    );
  }),
  graphql.query('validateSameResultPlanName', (req, res, ctx) => {
    return res(
      ctx.data({
        validateSameResultPlanName: false,
      }),
    );
  }),
  graphql.mutation('batchSaveAccelerateResultPlan', (req, res, ctx) => {
    return res(
      ctx.data({
        batchSaveAccelerateResultPlan: true,
      }),
    );
  }),
  graphql.mutation('batchRefreshAccelerateResultPlan', (req, res, ctx) => {
    return res(
      ctx.data({
        batchRefreshAccelerateResultPlan: true,
      }),
    );
  }),
];
