export const queryOriginEntity = {
  analysis: (analysisDisplayName: string) => `Analysis/${analysisDisplayName}`,
  workbook: (workbookDisplayName: string, elementDisplayName: string) =>
    `Workbook/${workbookDisplayName}/${elementDisplayName}`,
  metricTree: (metricTreeDisplayName: string, nodeDisplayName: string) =>
    `Metric Tree/${metricTreeDisplayName}/${nodeDisplayName}`,
  favorite: (metricDisplayName: string) => `Favorite/${metricDisplayName}`,
  metricDetail: 'Metric Detail',
  preview: (metricDisplayName: string) =>
    `Metric Detail/Preview/${metricDisplayName}`,
  define: (metricDisplayName: string) =>
    `Metric Detail/Define/${metricDisplayName}`,
};
export const list = {
  costPicker: {
    prefix: 'Cost',
    mid: 'to',
    suffix: 's',
    all: 'All',
    granularity: {
      s: 'Second',
      ms: 'Millisecond',
      m: 'Minute',
    },
  },
  title: 'Query Record',
  total: (total: number) => `${total} query records`,
  filter: {
    hit: 'Hit',
    miss: 'Miss',
    startTime: 'Query Start Time:',
    status: 'Status:',
    type: 'Type:',
    analysisView: 'Analysis View',
    queryContent: 'Query Content',
    hitAcc: 'Acc:',
    user: 'User:',
  },
  columns: {
    totalCost: 'Cost',
    status: 'Status',
    queryId: 'Query ID',
    type: 'Type',
    acc: 'Acceleration',
    user: 'User (Agent User)',
    startTime: 'Start Time',
    endTime: 'End Time',
  },
  queryOriginType: {
    ui: 'Product Interface',
    openApi: 'Query API',
    jdbc: 'JDBC',
  },
  status: {
    success: 'Success',
    failed: 'Failed',
    running: 'Running',
  },
};

export const detail = {
  totalCost: 'Total Cost',
  status: 'Status',
  basic: 'Basic Properties',
  cost: 'Cost',
  empty: 'None',
  acceleration: 'Acceleration',
  copy: 'Copy',
  copySuccess: 'Copy Success',
  origin: 'origin:',
  tab: {
    queryContent: 'Query Content',
    sql: 'SQL',
    exception: 'Exception',
  },
  subTitle: {
    type: 'Type',
    user: 'User (Agent User)',
    startTime: 'Start Time',
    endTime: 'End Time',
    queryContent: 'Query Content',
    sql: 'SQL',
    exception: 'Exception',
  },
};
