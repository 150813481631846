import {
  EMetricCatalogFiltersConfigType,
  IMetricCatalogFiltersConfig,
} from '@/services/metricCatalog/types';

/**
 * 默认的筛选配置
 */
export const defaultFiltersConfig: IMetricCatalogFiltersConfig = {
  propertyNames: [
    {
      name: 'metricCategoryId',
      type: EMetricCatalogFiltersConfigType.SYSTEM,
    },
    { name: 'type', type: EMetricCatalogFiltersConfigType.SYSTEM },
    {
      name: 'owner',
      type: EMetricCatalogFiltersConfigType.SYSTEM,
    },
  ],
};
