import {
  IUseGqlQueryOptions,
  useGqlLazyQuery,
  useGqlMutation,
  useGqlQuery,
} from '@/common/utils/gqlRequest';
import {
  EDatasourceEntityType,
  IDatasource,
  IDatasourceChildrenItem,
  IDatasourceSchemaItem,
  IFileTableData,
  IRefreshConfig,
  IPhysicalTableDetail,
} from '@/typings/dataSource';
import { gql } from '@apollo/client';
import {
  EFileNameType,
  IConfigurationResponse,
  IDatasourceConfigRequest,
  IDatasourceTypeSchema,
  IFileListResponse,
  IUpdateDatasource,
} from './type';
import { engineInfo } from '@/common/globalInfo/appConfig';
import { useCallback, useRef, useState } from 'react';
import { IFileDetailResponse } from '@/services/datasource/type';
import { post } from '@/common/utils/request';
import { IQueryResult } from '../queryColumns/types';
import { useRequest } from 'ahooks';
import { fileDetail } from './gql';
import { useQueryDatasetSourceData } from '@/services/dataset';
import { ESourceType, ITableColumn } from '@/typings/dataset';

export const useQueryDataSourceList = ({
  onCompleted,
}: {
  onCompleted?: (data: { queryDatasourceList: IDatasource[] }) => void;
}) => {
  const schema = gql`
    query queryDatasourceList($engineName: String) {
      queryDatasourceList(engineName: $engineName) {
        id
        name
        owner {
          userId
          nickname
        }
        displayName
        datasourceType
        icon
        guid
        schema
        extension
        configuration
        createTime
        entityType
        isEngine
        authority {
          canEdit
          canDelete
          canAuth
          canUsage
          authRoleList
          canTransfer
        }
      }
    }
  `;

  const { data, loading, run } = useGqlLazyQuery<
    {
      queryDatasourceList: IDatasource[];
    },
    { engineName: string }
  >(schema, {
    onCompleted,
  });

  return {
    data,
    loading,
    queryDatasourceList: run,
  };
};

/**
 * 编辑数据源
 */
export function useUpdateDatasourceInfo() {
  const schema = gql`
    mutation updateInternalDatasourceRequest(
      $updateInternalDatasourceRequest: UpdateInternalDatasourceRequest
    ) {
      updateInternalDatasourceRequest(
        updateInternalDatasourceRequest: $updateInternalDatasourceRequest
      )
    }
  `;
  const { run: updateDatasourceInfo } = useGqlMutation<
    {
      updateInternalDatasourceRequest: boolean;
    },
    {
      updateInternalDatasourceRequest: IUpdateDatasource;
    }
  >(schema);

  return {
    updateDatasourceInfo,
  };
}

export const useDatasourceSearch = () => {
  const ref = useRef<AbortController>();

  const schema = gql`
    query datasourceSearch(
      $guid: String
      $keyword: String
      $typeList: [String]
    ) {
      datasourceSearch(guid: $guid, keyword: $keyword, typeList: $typeList) {
        guid
        name
        entityType
        path {
          guid
          name
          entityType
        }
      }
    }
  `;

  const { data, loading, run } = useGqlLazyQuery<
    {
      datasourceSearch: IDatasourceChildrenItem[];
    },
    {
      guid: string;
      keyword: string;
      typeList: EDatasourceEntityType[];
    }
  >(schema);

  const datasourceSearch = useCallback(
    (params: {
      guid: string;
      keyword: string;
      typeList: EDatasourceEntityType[];
    }) => {
      const abortController = new AbortController();
      const cancelSignal = abortController.signal;

      // 如果已经有一个请求了则取消之前的请求
      if (ref.current) {
        ref.current.abort();
      }

      ref.current = abortController;

      return run(params, {
        fetchOptions: {
          signal: cancelSignal,
        },
      });
    },
    [run],
  );

  return {
    data: data?.datasourceSearch || [],
    loading,
    datasourceSearch,
  };
};

export const useQueryDatasourceChildrenList = ({
  onCompleted,
}: {
  onCompleted?: (data: {
    queryDatasourceChildrenList: IDatasourceChildrenItem[];
  }) => void;
}) => {
  const schema = gql`
    query queryDatasourceChildrenList($guid: String, $entityType: String) {
      queryDatasourceChildrenList(guid: $guid, entityType: $entityType) {
        guid
        name
        description
        entityType
        path {
          guid
          name
          entityType
        }
      }
    }
  `;
  const { loading, run } = useGqlLazyQuery<
    {
      queryDatasourceChildrenList: IDatasourceChildrenItem[];
    },
    {
      guid: string;
      entityType: EDatasourceEntityType;
    }
  >(schema, {
    onCompleted,
  });

  return {
    loading,
    queryDatasourceChildrenList: run,
  };
};

/**
 * 查询文件实体列表
 */
interface IFileTableResult {
  queryFileTableList: IFileTableData[];
}
export const useQueryFileTableList = (
  options?: IUseGqlQueryOptions<IFileTableResult>,
) => {
  const schema = gql`
    query queryFileTableList {
      queryFileTableList {
        fileId
        name
        guid
        description
        displayName
        entityType
        owner {
          account
          nickname
          userId
        }
        createTime
        updateTime
        authority {
          canEdit
          canDelete
          canAuth
          canUsage
          authRoleList
          canTransfer
        }
      }
    }
  `;
  const { data, loading, refetch } = useGqlQuery<IFileTableResult, {}>(
    schema,
    {},
    options,
  );

  return {
    loading,
    data,
    refetch,
  };
};

interface IFileDetailResult {
  queryFileDetail: IFileDetailResponse;
}
// 文件详情
export const useQueryFileDetail = (
  fileId: string,
  options?: IUseGqlQueryOptions<IFileDetailResult>,
) => {
  const schema = gql`
    query queryFileDetail($fileId: String) {
      queryFileDetail(fileId: $fileId) {
       ${fileDetail}
      }
    }
  `;
  const { data, loading, refetch } = useGqlQuery<
    IFileDetailResult,
    { fileId: string }
  >(schema, { fileId }, options);

  return {
    loading,
    data,
    refetch,
  };
};

interface IFileListResult {
  queryFileList: IFileListResponse[];
}
// 单个文件内上传的文件列表
export const useQueryFileList = (
  fileId: string,
  options?: IUseGqlQueryOptions<IFileListResult>,
) => {
  const schema = gql`
    query queryFileList($fileId: String) {
      queryFileList(fileId: $fileId) {
        id
        fileName
        createTime
        updateTime
        owner {
          userId
          nickname
          account
        }
      }
    }
  `;
  const { data, loading, refetch } = useGqlQuery<IFileListResult, {}>(
    schema,
    { fileId },
    options,
  );

  return {
    loading,
    data,
    refetch,
  };
};
interface IDataTypeConfigurationResult {
  queryFileDataTypeConfiguration: IConfigurationResponse;
}
//
export const useGetFileDataTypeConfiguration = (
  datasourceType: string,
  options?: IUseGqlQueryOptions<IDataTypeConfigurationResult>,
) => {
  const schema = gql`
    query queryFileDataTypeConfiguration($datasourceType: String) {
      queryFileDataTypeConfiguration(datasourceType: $datasourceType) {
        dataTypeList {
          dataType
          length
          scale
        }
        dataFormat
        dataTimeFormat
      }
    }
  `;
  const { data, loading, refetch } = useGqlQuery<
    IDataTypeConfigurationResult,
    { datasourceType: string }
  >(schema, { datasourceType }, options);

  return {
    loading,
    data,
    refetch,
  };
};
export const useQueryDatasourceTypeSchemaList = () => {
  const schema = gql`
    query queryDatasourceSchemaList {
      queryDatasourceSchemaList {
        type
        name
        icon
        schema
        extension
      }
    }
  `;

  const { data, loading, run } = useGqlLazyQuery<
    {
      queryDatasourceSchemaList: IDatasourceTypeSchema[];
    },
    {}
  >(schema);

  return {
    loading,
    queryDatasourceTypeSchemaList: run,
    datasourceTypeSchemaList: data?.queryDatasourceSchemaList,
  };
};

export function useQueryDatasourceSchemaItem(reqParams: {
  guid: string;
  entityType: EDatasourceEntityType;
}) {
  const schema = gql`
    query queryDatasourceItemDetail($guid: String, $entityType: String) {
      queryDatasourceItemDetail(guid: $guid, entityType: $entityType) {
        guid
        name
        entityType
        children {
          guid
          name
          description
          entityType
          path {
            guid
            name
            entityType
          }
        }
        path {
          guid
          name
          entityType
        }
      }
    }
  `;

  return useGqlQuery<
    {
      queryDatasourceItemDetail: IDatasourceSchemaItem;
    },
    {
      guid: string;
      entityType: EDatasourceEntityType;
    }
  >(schema, reqParams);
}

export function useCheckFileName() {
  const schema = gql`
    query checkFileName($fileName: String, $type: String) {
      checkFileName(fileName: $fileName, type: $type)
    }
  `;

  return useGqlLazyQuery<
    {
      checkFileName: boolean;
    },
    {
      fileName: string;
      type: EFileNameType;
    }
  >(schema);
}

export function useEditFileInfo() {
  const schema = gql`
    mutation updateFileInfo($fileUpdateRequest: FileUpdateRequest) {
      updateFileInfo(fileUpdateRequest: $fileUpdateRequest)
    }
  `;

  return useGqlMutation<
    {
      updateFileInfo: boolean;
    },
    {
      fileUpdateRequest: {
        fileId: string;
        displayName: string;
        description: string;
      };
    }
  >(schema);
}
// 删除文件数据表
export function useDeleteFileTable() {
  const schema = gql`
    mutation deleteFileTable($fileId: String) {
      deleteFileTable(fileId: $fileId)
    }
  `;

  return useGqlMutation<
    {
      deleteFileTable: boolean;
    },
    {
      fileId: string;
    }
  >(schema);
}
// 删除文件数据表内的子文件
export function useDeleteFile() {
  const schema = gql`
    mutation deleteFile($removeFileRequest: RemoveFileRequest) {
      deleteFile(removeFileRequest: $removeFileRequest)
    }
  `;

  return useGqlMutation<
    {
      deleteFile: boolean;
    },
    {
      removeFileRequest: {
        fileId: string;
        id: string;
      };
    }
  >(schema);
}

export async function queryTableColumnValues({
  tableGuid,
  columnName,
}: {
  tableGuid: string;
  columnName: string;
}) {
  return post<IQueryResult>('/query_column_value', {
    tableGuid,
    columnName,
  });
}

export function useQueryTableColumnValues() {
  const {
    data = null,
    loading,
    runAsync,
  } = useRequest<
    IQueryResult,
    [
      {
        tableGuid: string;
        columnName: string;
      },
    ]
  >(queryTableColumnValues, {
    manual: true,
  });

  return {
    data,
    loading,
    run: runAsync,
  };
}

export function useQueryPhysicalTableDetail(
  onCompleted?: (data: { queryTableDetail: IPhysicalTableDetail }) => void,
) {
  const schema = gql`
    query queryTableDetail($tableGuid: String) {
      queryTableDetail(tableGuid: $tableGuid) {
        name
        displayName
        columns {
          name
          originDataType
          displayName
          isPartition
          description
          isFirstPartition
        }
        description
        content
        type
        datasourceItems {
          id
          name
          queryEngineType
        }
        path {
          name
          guid
          entityType
        }
        datasource {
          name
          displayName
        }
      }
    }
  `;
  const { data, run, loading } = useGqlLazyQuery<
    { queryTableDetail: IPhysicalTableDetail },
    { tableGuid: string }
  >(schema, {
    onCompleted,
  });

  return {
    data,
    queryPhysicalTableDetail: run,
    loading,
  };
}

interface ISetupRefreshConfigRequest extends IRefreshConfig {
  sourceId: string;
  sourceType: ESourceType;
}

// 配置数据源加速设置
export const useSetupRefreshConfig = () => {
  const schema = gql`
    mutation setupRefreshConfig($refreshConfigRequest: RefreshConfigRequest) {
      setupRefreshConfig(refreshConfigRequest: $refreshConfigRequest)
    }
  `;

  const { run: setupRefreshConfig, loading } = useGqlMutation<
    {
      setupRefreshConfig: boolean;
    },
    {
      refreshConfigRequest: ISetupRefreshConfigRequest;
    }
  >(schema);

  return {
    setupRefreshConfig,
    loading,
  };
};

// 获取加速设置配置
export const useQueryRefreshConfig = () => {
  const schema = gql`
    query queryRefreshConfig($sourceType: String, $sourceId: String) {
      queryRefreshConfig(sourceType: $sourceType, sourceId: $sourceId) {
        tableGuid
        partitionColumnName
        dateFormat
        dateGranularity
        refreshPeriod
        cron
        refreshMethod
        incrementRefreshScope {
          sign
          interval
        }
      }
    }
  `;

  const { loading, run } = useGqlLazyQuery<
    {
      queryRefreshConfig: IRefreshConfig | null;
    },
    {
      sourceId: string;
      sourceType: ESourceType;
    }
  >(schema);

  return {
    queryRefreshConfig: run,
    loading,
  };
};

// 更新数据
export const useRefreshTable = () => {
  const schema = gql`
    mutation refreshTable($refreshTableRequest: RefreshTableRequest) {
      refreshTable(refreshTableRequest: $refreshTableRequest)
    }
  `;

  const { run: refreshTable, loading } = useGqlMutation<
    {
      refreshTable: boolean;
    },
    {
      refreshTableRequest: {
        sourceId: string;
        sourceType: ESourceType;
        startDate?: string;
        endDate?: string;
      };
    }
  >(schema);

  return {
    refreshTable,
    loading,
  };
};

export interface IDownstreamMtRefresh {
  tableName: string;
  refreshTime: string;
}

// 查询当前物理表下游的表更新记录
export const useQueryDownstreamMtRefresh = () => {
  const schema = gql`
    query queryDownstreamMtRefresh($sourceId: String, $sourceType: String) {
      queryDownstreamMtRefresh(sourceId: $sourceId, sourceType: $sourceType) {
        tableName
        refreshTime
      }
    }
  `;

  const { loading, run } = useGqlLazyQuery<
    {
      queryDownstreamMtRefresh: IDownstreamMtRefresh[];
    },
    {
      sourceId: string;
      sourceType: ESourceType;
    }
  >(schema);

  return {
    queryDownstreamMtRefresh: run,
    loading,
  };
};

export function useQuerySourceColumnData() {
  const { queryDatasource: queryTableSourceData } = useQueryDatasetSourceData();
  const { refetch: queryFileSourceData } = useQueryFileDetail('', {
    skip: true,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ITableColumn[]>();
  const querySourceData = useCallback(
    (params: {
      type: ESourceType.FILE | ESourceType.TABLE;
      content: string;
    }) => {
      setLoading(true);
      if (params.type === ESourceType.TABLE) {
        return queryTableSourceData({
          ...params,
          engineName: engineInfo.name,
        })
          .then(({ querySourceData: result }) => {
            setData(result.columns);
            return result.columns;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        return queryFileSourceData({
          fileId: params.content,
        })
          .then(({ data: { queryFileDetail } }) => {
            setData(queryFileDetail.columns);
            return queryFileDetail.columns;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [queryTableSourceData, queryFileSourceData],
  );

  return {
    loading,
    querySourceData,
    data,
  };
}

export function useCreateDatasourceCatalog() {
  const schema = gql`
    mutation createDatasourceCatalog(
      $datasourceConfigRequest: UpdateDatasourceConfigRequest
    ) {
      createDatasourceCatalog(datasourceConfigRequest: $datasourceConfigRequest)
    }
  `;
  const { loading, run } = useGqlMutation<
    {
      createDatasourceCatalog: boolean;
    },
    {
      datasourceConfigRequest: IDatasourceConfigRequest;
    }
  >(schema);

  return {
    createDatasourceCatalog: run,
    loading,
  };
}
export function useUpdateDatasourceCatalog() {
  const schema = gql`
    mutation updateDatasourceCatalog(
      $datasourceConfigRequest: UpdateDatasourceConfigRequest
    ) {
      updateDatasourceCatalog(datasourceConfigRequest: $datasourceConfigRequest)
    }
  `;
  const { loading, run } = useGqlMutation<
    {
      updateDatasourceCatalog: boolean;
    },
    {
      datasourceConfigRequest: IDatasourceConfigRequest;
    }
  >(schema);

  return {
    updateDatasourceCatalog: run,
    loading,
  };
}
export function useDeleteDatasourceCatalog() {
  const schema = gql`
    mutation deleteDatasourceCatalog($datasourceId: String) {
      deleteDatasourceCatalog(datasourceId: $datasourceId)
    }
  `;
  const { run: deleteDatasourceCatalog, loading } = useGqlMutation<
    {
      deleteDatasourceCatalog: boolean;
    },
    {
      datasourceId: string;
    }
  >(schema);

  return {
    deleteDatasourceCatalog,
    loading,
  };
}
