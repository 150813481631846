import _ from 'lodash';
import {
  EQueryRecordStatus,
  EQueryOriginType,
  IQueryRecord,
} from '../../src/typings/queryRecord';
export const getMockQueryRecordList = (): IQueryRecord[] => {
  return _.range(10).map((i) => getMockQueryRecordDetail(i));
};

export const getMockQueryRecordDetail = (i: number): IQueryRecord => {
  return {
    queryId: `queryId_${i}`,
    totalCost: 100,
    costDetail: [],
    startTime: new Date().getTime(),
    endTime: new Date().getTime(),
    hitAccList: [
      {
        acc: `acc_${i}`,
        accName: `accName_${i}`,
      },
    ],
    analysisView: `analysisView_${i}`,
    status: _.sample([
      EQueryRecordStatus.SUCCESS,
      EQueryRecordStatus.FAILED,
      EQueryRecordStatus.RUNNING,
    ]),
    userId: `userId_${i}`,
    queryOriginType: _.sample([
      EQueryOriginType.JDBC,
      EQueryOriginType.OPEN_API,
      EQueryOriginType.UI,
    ]),
    queryOriginEntity: `queryOriginEntity_${i}`,
    sql: `sql_${i}`,
    queryContent: `queryContent_${i}`,
  };
};
