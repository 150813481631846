import { EDateGranularityType } from '@/typings';
import { IBaseProps } from '../../types';
import { DatePicker } from '@aloudata/aloudata-design';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekday from 'dayjs/plugin/weekday';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import { useMemo } from 'react';
import { getPicker } from './helper';

dayjs.locale('zh-cn');
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);

/**
 * 精确单个日期选择器
 */
export default function SpecificTimePicker(props: IProps) {
  const { granularity, value, onChange, disabled, className } = props;

  const showTime = getShowTime(granularity);

  const pickerValue = useMemo(() => {
    return value ? dayjs(value) : undefined;
  }, [value]);

  return (
    <DatePicker
      picker={getPicker(granularity)}
      value={pickerValue}
      onChange={(val) => {
        onChange?.(val?.valueOf()!);
      }}
      dropdownAlign={{
        overflow: {
          shiftY: true,
          shiftX: true,
        },
      }}
      showTime={showTime}
      format={showTime ? `YYYY-MM-DD ${showTime.format}` : undefined}
      locale={locale}
      disabled={disabled}
      className={className}
      allowClear={false}
    />
  );
}

interface IProps extends IBaseProps {
  value?: number;
  onChange?: (time: number) => void;
  granularity: string;
}

export function getShowTime(granularity: string) {
  let format = '';
  if (granularity === EDateGranularityType.HOUR) {
    format = 'HH';
  } else if (granularity === EDateGranularityType.MINUTE) {
    format = 'HH:mm';
  } else if (granularity === EDateGranularityType.SECOND) {
    format = 'HH:mm:ss';
  }
  return format
    ? {
        format,
      }
    : undefined;
}
