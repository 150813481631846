import styles from './index.less';
import t from '@/locales';
import { TFilterSelectValues } from '@/common/domain/filter/Filter/filter';
import {
  EDependencyType,
  EExtractType,
  EFilterType,
  ELooseDataType,
  ENumberRangeOperator,
  IMatchMetricValues,
  TFilter,
  TFixedDateFilter,
  TJsonFilter,
  TNumberFilter,
  TStringFilter,
  TStringValues,
} from '@/common/domain/filter/NewFilter/types';
import { looseDataTypeMap } from '@/common/domain/filter/NewFilter/constants';
import { EDateGranularityType, EOriginDataType } from '@/typings';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import SpecificTimeRangePicker from '../../SpecificTimeRangePicker';
import SpecificTimePicker from '../../SpecificTimePicker';
import NumberRangeInput from '../../NumberRangeInput';
import NumberInput from '../../NumberInput';
import { Input, Tooltip } from '@aloudata/aloudata-design';
import _ from 'lodash';
import { useMemo } from 'react';
import MatchMetric from '../../MatchMetric';
import RankPicker from '../../RankPicker';
import UserAttributePicker from '../../UserAttributePicker';
import { isRealGranularity } from '../../../../../granularity/helper';
import SpecificCustomCalendarPicker from '../../SpecificCustomCalendarPicker';
import { TMetric } from '@/typings/metric';
import ExtractOperator from '../../Operator/ExtractOperator';
import { ReactComponent as InfoCircleLine } from '@/assets/icon/Information-Circle-light-line.svg';
/**
 * 值输入组件
 */
export default function ValueNode(props: IProps) {
  const { value, onChange, renderValuesPicker, originDataType, currentCode } =
    props;
  const { granularity } = value;
  const leftColumnDataType = originDataType2DataTypeMap[originDataType];
  const valueComp = useMemo(() => {
    // 公共筛选类型
    if (
      value.type === EFilterType.IS_NULL ||
      value.type === EFilterType.NOT_NULL
    ) {
      return null;
    }
    if (
      value.type === EFilterType.EQUAL ||
      value.type === EFilterType.NOT_EQUAL
    ) {
      // 值选择器
      return renderValuesPicker({
        value: value.values,
        onChange: (newValues) => onChange({ ...value, values: newValues }),
      });
    }
    if (
      value.type === EFilterType.EQUAL_ATTRIBUTE ||
      value.type === EFilterType.NOT_EQUAL_ATTRIBUTE ||
      value.type === EFilterType.CONTAIN_ATTRIBUTE ||
      value.type === EFilterType.NOT_CONTAIN_ATTRIBUTE
    ) {
      return (
        <UserAttributePicker
          value={value.values}
          onChange={(newValues) => onChange({ ...value, values: newValues })}
        />
      );
    }
    const looseDataType = looseDataTypeMap[leftColumnDataType];
    if (
      looseDataType === ELooseDataType.NUMBER ||
      looseDataType === ELooseDataType.TIME ||
      looseDataType === ELooseDataType.TEXT
    ) {
      if (
        value.type === EFilterType.RANK ||
        value.type === EFilterType.RANK_DENSE ||
        value.type === EFilterType.ROW_NUMBER
      ) {
        return (
          <RankPicker
            value={value.values}
            onChange={(newValues) => onChange({ ...value, values: newValues })}
          />
        );
      }
      if (value.type === EFilterType.MATCH_METRIC) {
        const { dependency } = value;
        if (dependency.type !== EDependencyType.DIMENSION) {
          return null;
        }
        const { values } = value;
        const { name } = dependency;
        return (
          <MatchMetric
            dimensionName={name}
            values={values.values}
            currentCode={currentCode}
            metricCode={values.dependency?.code}
            onChange={(newValues: IMatchMetricValues, metric) => {
              const newValue = _.cloneDeep(value);
              onChange(
                {
                  ...newValue,
                  values: newValues,
                },
                metric,
              );
            }}
          />
        );
      }
    }
    // 日期筛选类型
    if (looseDataType === ELooseDataType.TIME) {
      const currValue = value as TFixedDateFilter;
      if (
        currValue.type === EFilterType.DATE_RANGE ||
        currValue.type === EFilterType.DATE_OUTSIDE_RANGE
      ) {
        // 日期范围
        if (isRealGranularity(granularity || EDateGranularityType.DAY)) {
          return (
            <SpecificTimeRangePicker
              granularity={granularity || EDateGranularityType.DAY}
              value={[currValue.values.min.value, currValue.values.max.value]}
              onChange={(newValues) => {
                const newValue = _.cloneDeep(currValue);
                _.set(newValue, 'values.min.value', newValues[0]);
                _.set(newValue, 'values.max.value', newValues[1]);
                onChange(newValue);
              }}
            />
          );
        } else {
          return (
            <div className={styles.customCalendarPickerWrap}>
              <SpecificCustomCalendarPicker
                className={styles.specificCustomCalendarPicker}
                granularity={granularity || EDateGranularityType.DAY}
                value={currValue.values.min.value}
                onChange={(newValues: number) => {
                  const newValue = _.cloneDeep(value);
                  _.set(newValue, 'values.min.value', newValues);
                  _.set(newValue, 'values.min.granularityName', granularity);
                  onChange(newValue);
                }}
              />
              ~
              <SpecificCustomCalendarPicker
                className={styles.specificCustomCalendarPicker}
                granularity={granularity || EDateGranularityType.DAY}
                value={currValue.values.max.value}
                onChange={(newValues: number) => {
                  const newValue = _.cloneDeep(value);
                  _.set(newValue, 'values.max.value', newValues);
                  _.set(newValue, 'values.max.granularityName', granularity);
                  onChange(newValue);
                }}
              />
            </div>
          );
        }
      }
      return (
        <>
          {isRealGranularity(granularity || EDateGranularityType.DAY) ? (
            <SpecificTimePicker
              granularity={granularity || EDateGranularityType.DAY}
              value={currValue.values.value}
              onChange={(newValues: number) => {
                const newValue = _.cloneDeep(value);
                _.set(newValue, 'values.value', newValues);
                onChange(newValue);
              }}
            />
          ) : (
            <SpecificCustomCalendarPicker
              granularity={granularity || EDateGranularityType.DAY}
              value={currValue.values.value}
              onChange={(newValues: number) => {
                const newValue = _.cloneDeep(value);
                _.set(newValue, 'values.value', newValues);
                _.set(newValue, 'values.granularityName', granularity);
                onChange(newValue);
              }}
            />
          )}
        </>
      );
    }
    // 数字筛选类型
    if (looseDataType === ELooseDataType.NUMBER) {
      const currValue = value as TNumberFilter;
      if (
        currValue.type === EFilterType.NUMBER_RANGE ||
        currValue.type === EFilterType.NUMBER_OUTSIDE_RANGE
      ) {
        return (
          <NumberRangeInput
            value={[currValue.values.low.value, currValue.values.high.value]}
            onChange={(newValues) =>
              onChange({
                ...currValue,
                values: {
                  low: {
                    value: newValues[0],
                    operator: ENumberRangeOperator.LESS_OR_EQUAL,
                  },
                  high: {
                    value: newValues[1],
                    operator: ENumberRangeOperator.LESS_OR_EQUAL,
                  },
                },
              })
            }
          />
        );
      }
      if (
        currValue.type === EFilterType.NUMBER_EQUAL ||
        currValue.type === EFilterType.NUMBER_NOT_EQUAL
      ) {
        return renderValuesPicker({
          value: currValue.values,
          onChange: (newValues) =>
            onChange({ ...currValue, values: newValues }),
        });
      }
      return (
        <NumberInput
          value={currValue.values.value}
          onChange={(newValue) =>
            onChange({
              ...currValue,
              values: { value: newValue },
            })
          }
        />
      );
    }
    // 字符串筛选类型
    if (looseDataType === ELooseDataType.TEXT) {
      const currValue = value as TStringFilter;
      return (
        <Input
          value={(currValue.values as TStringValues).value}
          placeholder={t.components.filter.condition.textPlaceholder}
          onChange={(e) => {
            const newValue = _.cloneDeep(currValue);
            _.set(newValue, 'values.value', e.target.value);
            onChange(newValue);
          }}
        />
      );
    }
    // 布尔筛选类型不需要考虑，因为它的操作符是 equal 和 not_equal，在公共筛选类型里已经判断了
    // JSON 筛选类型
    if (looseDataType === ELooseDataType.JSON) {
      const currValue = value as TJsonFilter;
      if (currValue.type === EFilterType.EXTRACT) {
        return (
          <div className={styles.extractWrap}>
            <Input
              className={styles.extractKeyInput}
              placeholder={t.components.filter.operator.json.keyPlaceholder}
              value={currValue.values.key}
              onChange={(e) => {
                const newValue = _.cloneDeep(currValue);
                _.set(newValue, 'values.key', e.target.value);
                onChange(newValue);
              }}
              suffix={
                <Tooltip title={t.components.filter.operator.json.keyInfo}>
                  <InfoCircleLine size={16} fill={'#3271C9'} />
                </Tooltip>
              }
            />
            <ExtractOperator
              value={currValue.values.type}
              onChange={(newType) => {
                const newValue = _.cloneDeep(currValue);
                _.set(newValue, 'values.type', newType);
                if (
                  newType === EExtractType.IS_NULL ||
                  newType === EExtractType.NOT_NULL
                ) {
                  _.set(newValue, 'values.value', undefined);
                }
                onChange(newValue);
              }}
            />
            {currValue.values.type !== EExtractType.IS_NULL &&
              currValue.values.type !== EExtractType.NOT_NULL && (
                <Input
                  className={styles.extractValueInput}
                  placeholder={
                    t.components.filter.operator.json.valuePlaceholder
                  }
                  value={currValue.values.value}
                  onChange={(e) => {
                    const newValue = _.cloneDeep(currValue);
                    _.set(newValue, 'values.value', e.target.value);
                    onChange(newValue);
                  }}
                />
              )}
          </div>
        );
      }
    }

    return null;
  }, [
    value,
    leftColumnDataType,
    renderValuesPicker,
    onChange,
    currentCode,
    granularity,
  ]);

  return <div className={styles.wrap}>{valueComp}</div>;
}

interface IProps {
  value: TFilter;
  onChange: (value: TFilter, entity?: TMetric) => void;
  renderValuesPicker: (
    valuesPickerProps: IValuesPickerProps,
  ) => React.ReactNode;
  originDataType: EOriginDataType;
  currentCode?: string;
}

export interface IValuesPickerProps {
  value: TFilterSelectValues;
  onChange: (value: TFilterSelectValues) => void;
}
