import { fuzzyMatch } from '@/pages/Dataset/helpers';
import {
  ECodeEditorWrapperType,
  EFunctionFilterType,
  TProps,
  TResource,
} from '.';
import { EFunctionType, IFunction } from '../../Formula/type';
import { ECodeEditorPropsType, TExternalInfo } from '../CodeEditor/type';
import { TDimension } from '@/typings/dimension';
import { TMetric } from '@/typings/metric';
import { originDataType2DataTypeMap } from '../../Formula/constant';

// 获取函数过滤函数
export const getFilterFunctionFn = (type?: EFunctionFilterType) => {
  const filterMetricFunction = (functionItem: IFunction<{}>) => {
    return !(
      functionItem.descZh?.type === EFunctionType.WINDOW ||
      functionItem.descZh?.type === EFunctionType.ANALYSES ||
      functionItem.descZh?.type === EFunctionType.AGG
    );
  };

  const filterMetricFilterFunction = (functionItem: IFunction<{}>) => {
    return !(
      functionItem.descZh?.type === EFunctionType.WINDOW ||
      functionItem.descZh?.type === EFunctionType.ANALYSES ||
      functionItem.descZh?.type === EFunctionType.AGG ||
      fuzzyMatch(functionItem.name, 'isfiltered')
    );
  };

  const filterAtomicMetricFunction = (functionItem: IFunction<{}>) => {
    return !(
      (
        functionItem.descZh?.type === EFunctionType.WINDOW ||
        functionItem.descZh?.type === EFunctionType.ANALYSES ||
        fuzzyMatch(functionItem.name, 'isfiltered')
      )
      // functionItem.descZh?.type === EFunctionType.AGG
    );
  };
  const filterDimensionFunction = (functionItem: IFunction<{}>) => {
    return (
      !(functionItem.descZh?.type === EFunctionType.ANALYSES) ||
      (functionItem.descZh?.type === EFunctionType.ANALYSES &&
        [
          'poweradd',
          'powersub',
          'powerfix',
          'earlier',
          'removefilter',
        ].includes(functionItem.name.toLowerCase())) ||
      fuzzyMatch(functionItem.name, 'isfiltered')
    );
  };
  const filterDatasetFunction = (functionItem: IFunction<{}>) => {
    return !fuzzyMatch(functionItem.name, 'isfiltered');
  };

  if (type === EFunctionFilterType.ATOMIC_METRIC) {
    return filterAtomicMetricFunction;
  }
  if (type === EFunctionFilterType.METRIC) return filterMetricFunction;
  if (type === EFunctionFilterType.DIMENSION) return filterDimensionFunction;
  if (type === EFunctionFilterType.DATASET) return filterDatasetFunction;
  if (type === EFunctionFilterType.METRIC_FILTER)
    return filterMetricFilterFunction;

  return () => true;
};

// 获取外部信息
export function getExternalInfo(props: TProps): TExternalInfo {
  if (props.type === ECodeEditorWrapperType.DATASET) {
    return {
      type: ECodeEditorPropsType.DATASET,
      dataset: props.dataset,
      many2oneDatasets: props.many2oneDatasets,
      one2manyDatasets: props.one2manyDatasets,
    };
  }
  if (props.type === ECodeEditorWrapperType.METRIC) {
    return {
      type: ECodeEditorPropsType.LIST,
      list: [
        ...(props.metric || []),
        ...(props.dependencies?.metrics || []),
      ].map((metric) =>
        transform2ListItem({
          type: props.type,
          data: metric,
        }),
      ),
    };
  }
  if (props.type === ECodeEditorWrapperType.COMPOSE_METRIC) {
    return {
      type: ECodeEditorPropsType.COMPOSE_METRIC,
      list: [
        ...(props.metric || []),
        ...(props.dependencies?.metrics || []),
      ].map((metric) =>
        transform2ListItem({
          type: ECodeEditorWrapperType.METRIC,
          data: metric,
        }),
      ),
      dimensionList:
        [
          ...(props.dimensions || []),
          ...(props.dependencies?.dimensions || []),
        ].map((dimensions) =>
          transform2ListItem({
            type: ECodeEditorWrapperType.DIMENSION,
            data: dimensions,
          }),
        ) || [],
    };
  }
  return {
    type: ECodeEditorPropsType.LIST,
    list:
      [
        ...(props.dimensions || []),
        ...(props.dependencies?.dimensions || []),
      ].map((dimensions) =>
        transform2ListItem({
          type: props.type,
          data: dimensions,
        }),
      ) || [],
  };
}

// 将指标和维度转换为编辑器使用的列表项目
export function transform2ListItem({ type, data }: TResource) {
  if (type === ECodeEditorWrapperType.DIMENSION)
    return {
      id: data.name,
      displayName: data.displayName,
      dataType: originDataType2DataTypeMap[data.originDataType],
      originDataType: data.originDataType,
      dependencies: {
        metrics: [] as TMetric[],
        dimensions: [data] as TDimension[],
      },
    };
  return {
    id: data.code,
    displayName: data.name,
    dataType: data.dataType,
    originDataType: data.originDataType,
    dependencies: {
      metrics: [data] as TMetric[],
      dimensions: [] as TDimension[],
    },
  };
}
