import { ECategoryProperty, ICategory } from '@/typings/category';
const NOT_FOUND = -1;
import { DataNode } from '@aloudata/aloudata-design/dist/Tree';
import { IAuthority } from '@/typings/authority';

export interface ICategoryTreeNode extends DataNode {
  title: string;
  key: string;
  type: ECategoryProperty;
  parentId: string | null;
  resourceCount: number;
  frontId: string | null;
  className?: string;
  children: ICategoryTreeNode[];
  authority?: IAuthority | null;
  isCategory: boolean;
}
export function buildCategoryTree(
  categoryList: ICategory[],
  config?: {
    filterKey?: string;
    systemCategoryDisabled?: boolean;
  },
): ICategoryTreeNode[] {
  const res: ICategoryTreeNode[] = [];
  const { systemCategoryDisabled = false } = config || {};
  const map = categoryList.reduce(
    (pre, v) => {
      const key = v.id;
      let disabled = false;
      const authority = v.authority;
      if (authority === null || authority === undefined) {
        // 表示服务端不做任何权限判断
        disabled = false;
      } else {
        disabled = Array.isArray(authority.canCreateResources)
          ? !(authority.canCreateResources.length > 0)
          : false;
      }
      pre[key] = {
        title: v.name,
        key: v.id,
        parentId: v.parentId,
        frontId: v.frontId,
        type: v.type,
        disabled,
        children: [],
        resourceCount: v.resourceCount,
        isCategory: true,
        authority: v.authority,
      };

      return pre;
    },
    {} as {
      [key: string]: ICategoryTreeNode;
    },
  );
  let systemCategory;
  for (const category of categoryList) {
    const newItem = map[category.id];
    if (newItem.type === 'SYSTEM') {
      systemCategory = newItem;
      if (systemCategoryDisabled) {
        systemCategory.disabled = true;
        systemCategory.selectable = false;
      }
      continue;
    }
    if (category.parentId === null) {
      //没有父节点
      insertByFrontId(res, newItem);
    } else if (map[category.parentId]) {
      const parent = map[category.parentId];
      if (!parent.children) {
        parent.children = [];
      }
      insertByFrontId(parent.children, newItem);
    }
  }
  if (systemCategory) {
    res.push(systemCategory);
  }

  return res;
}

function insertByFrontId(list: ICategoryTreeNode[], item: ICategoryTreeNode) {
  //遍历list 找到item的前后项；
  let frontIndex = NOT_FOUND,
    nextIndex = NOT_FOUND;
  for (let i = 0; i < list.length; i++) {
    const current = list[i];
    if (current.frontId === item.key) {
      nextIndex = i; //找到item的后面一项的位置
      continue;
    }
    if (current.key === item.frontId) {
      frontIndex = i; //找到item的前面一项的位置
    }
  }
  if (frontIndex === NOT_FOUND && nextIndex === NOT_FOUND) {
    list.push(item);
  } else if (frontIndex === NOT_FOUND) {
    // 没找到前面一项，仅找到后一项，插在nextIndex的前面
    list.splice(nextIndex, 0, item);
  } else if (nextIndex === NOT_FOUND) {
    //没找到后面一项，仅找到前面一项,插在frontIndex的后面
    list.splice(frontIndex + 1, 0, item);
  } else {
    //从frontIndex往前遍历，查看几个连续
    let frontContinueIndex = frontIndex;
    if (frontIndex > 0) {
      let currentFrontId = list[frontIndex].frontId;
      for (let i = frontIndex - 1; i >= 0; i--) {
        const currentCategory = list[i];
        if (currentCategory.key === currentFrontId) {
          frontContinueIndex = i;
          currentFrontId = currentCategory.frontId;
        } else {
          break;
        }
      }
    }
    //从nextIndex往后遍历，查看几个连续
    let nextContinueIndex = nextIndex;
    if (nextIndex < list.length - 1) {
      let currentCategoryKey = list[nextIndex].key;
      for (let i = nextIndex + 1; i < list.length; i++) {
        const nextCategory = list[i];
        if (nextCategory.frontId === currentCategoryKey) {
          nextContinueIndex = i;
          currentCategoryKey = nextCategory.key;
        } else {
          break;
        }
      }
    }
    if (nextIndex > frontIndex) {
      const nextItems = list.splice(
        nextIndex,
        nextContinueIndex - nextIndex + 1,
      );
      const frontItems = list.splice(
        frontContinueIndex,
        frontIndex - frontContinueIndex + 1,
      );
      list.push(...frontItems, item, ...nextItems);
    } else {
      const frontItems = list.splice(
        frontContinueIndex,
        frontIndex - frontContinueIndex + 1,
      );
      const nextItems = list.splice(
        nextIndex,
        nextContinueIndex - nextIndex + 1,
      );
      list.push(...frontItems, item, ...nextItems);
    }
  }
}

export function loopSearchNode(
  data: ICategoryTreeNode[],
  key: React.Key,
  callback: (
    node: ICategoryTreeNode,
    i: number,
    data: ICategoryTreeNode[],
  ) => void,
) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].key === key) {
      return callback(data[i], i, data);
    }
    if (data[i].children) {
      loopSearchNode(data[i].children!, key, callback);
    }
  }
}

function loopCategoryTreeNodes(
  treeNodes: ICategoryTreeNode[],
  callback: (
    node: ICategoryTreeNode,
    frontId: string | null,
    parentId: string | null,
  ) => void,
  parentId: string | null = null,
) {
  treeNodes.forEach((node, index, arr) => {
    const frontId = arr[index - 1] ? arr[index - 1].key : null;
    callback(node, frontId, parentId);
    if (node.children) {
      loopCategoryTreeNodes(node.children, callback, node.key);
    }
  });
}

export function getListDataFromTree(
  treeData: ICategoryTreeNode[],
): ICategory[] {
  const listData: ICategory[] = [];
  loopCategoryTreeNodes(treeData, (node, frontId, parentId) => {
    const { title, key, type, resourceCount } = node;
    listData.push({
      id: key,
      name: title,
      type,
      parentId,
      frontId,
      resourceCount,
      authority: node.authority,
    });
  });

  return listData;
}
