import { graphql } from 'msw';
import { defaultFiltersConfig } from '../../src/pages/MetricCatalog/common/constants';

export const metricCatalog = [
  graphql.query('queryMetricCatalogConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMetricCatalogConfig: defaultFiltersConfig,
      }),
    );
  }),
  graphql.query('checkMetricCatalogCanConfigurable', (req, res, ctx) => {
    return res(
      ctx.data({
        checkMetricCatalogCanConfigurable: true,
      }),
    );
  }),

  graphql.mutation('saveMetricCatalogConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        saveMetricCatalogConfig: true,
      }),
      ctx.delay(500),
    );
  }),
];
