import { getUniqId } from '../../src/common/utils';
import { getMockUserBindRoleList, mockUserAuthorityList } from './mockData';
import _ from 'lodash';
import { graphql, rest } from 'msw';
import { getRestfulResponse } from '../utils';
import isPossibilityTrue from '../utils/isPossibilityTrue';
import { EResourceType, EUserRole } from '../../src/typings/authority';

export const authority = [
  // 获取用户权限
  rest.post('/preQuery', (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          id: getUniqId(),
          type: 'DATASET',
          hasAuthority: true,
          isExist: true,
        }),
      ),
    );
  }),
  // 分享
  graphql.mutation('grantAuth', (req, res, ctx) => {
    return res(
      ctx.data({
        grantAuth: true,
      }),
    );
  }),
  //权限
  graphql.mutation('grant', (req, res, ctx) => {
    return res(
      ctx.data({
        grant: true,
      }),
    );
  }),
  // 撤权
  graphql.mutation('revoke', (req, res, ctx) => {
    return res(
      ctx.data({
        revoke: true,
      }),
    );
  }),
  // 根据资源获取权限列表
  graphql.query('queryUserAuthorityList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserAuthorityList: mockUserAuthorityList,
      }),
      ctx.delay(1000),
    );
  }),
  // 根据用户获取权限列表
  graphql.query('queryDirectAuthResourcesByResourceType', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDirectAuthResourcesByResourceType: [],
      }),
      ctx.delay(1000),
    );
  }),
  // 更改权限
  graphql.mutation('updateAuthorityRole', (req, res, ctx) => {
    return res(
      ctx.data({
        updateAuthorityRole: true,
      }),
    );
  }),
  // 查询是否有创建权限
  graphql.query('authenticateCreate', (req, res, ctx) => {
    return res(
      ctx.data({
        authenticateCreate: isPossibilityTrue(99),
      }),
    );
  }),
  // 获取资源最大权限
  graphql.query('queryUserMaxAuthorityRole', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserMaxAuthorityRole: {
          authoritySource: 'DIRECT',
          authorityRole: 'OWNER',
          authority: {
            canEdit: true,
            canDelete: true,
            canUsage: true,
            canAuth: true,
            authRoleList: ['ADMIN', 'USAGER'],
          },
        },
      }),
    );
  }),
  // 获取资源类型的角色
  graphql.query('queryGrantRoleList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryGrantRoleList: [
          {
            resourceType: EResourceType.ANALYSIS_VIEW,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          {
            resourceType: EResourceType.DATASET,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          {
            resourceType: EResourceType.METRIC,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          {
            resourceType: EResourceType.DIMENSION,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          {
            resourceType: EResourceType.DATASOURCE,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
        ],
      }),
    );
  }),
  graphql.query('querySpecifyDataTypeProperty', (req, res, ctx) => {
    return res(
      ctx.data({
        querySpecifyDataTypeProperty: getMockUserBindRoleList(
          req.variables.type,
        ),
      }),
    );
  }),
  graphql.mutation('saveUserTypePropertyBindRole', (req, res, ctx) => {
    return res(
      ctx.data({
        saveUserTypePropertyBindRole: true,
      }),
      ctx.delay(500),
    );
  }),
  rest.post('/config/module_switch', (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          disableAcceleration: false,
        }),
      ),
    );
  }),
  graphql.query('queryResourceViewerDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryResourceViewerDetail: {
          viewerList: [
            {
              accountType: 'USER',
              id: '1',
              displayName: 'test',
            },
            {
              accountType: 'USER',
              id: '2',
              displayName: 'test2',
            },
          ],
          allViewer: _.random(0, 1) === 1,
          canEdit: true,
        },
      }),
    );
  }),
  graphql.mutation('grantViewAuthority', (req, res, ctx) => {
    return res(
      ctx.data({
        grantViewAuthority: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.query('queryRoleList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRoleList: [
          {
            resourceType: EResourceType.METRIC,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
        ],
      }),
    );
  }),
  graphql.query('queryRowAuthRuleList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRowAuthRuleList: [
          {
            id: getUniqId(),
            name: 'test',
            description: 'test',
            creator: {
              id: getUniqId(),
              name: 'test',
            },
            metricInfluence: [{ influence: 'ALL' }],
            userInfluence: [{ influence: 'ALL' }],
            ruleConfig: {
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          dimensionName: 'dimensionName',
                          id: '7a1411e2-2875-441a-a231-bb411af0c59d',
                          mappingColumnName: 'column_name',
                          nodeType: 'LEAF',
                          op: 'MATCH',
                        },
                        {
                          dimensionName: 'dimensionName1',
                          id: '5fe78b86-cc29-4536-918f-b1679daa58d9',
                          mappingColumnName: 'column_name1',
                          nodeType: 'LEAF',
                          op: 'UN_MATCH',
                        },
                      ],
                      id: '64b21e38-9274-4aa9-aa86-f292fef5d32a',
                      mappingAccountKey: 'account_column_name',
                      nodeType: 'GROUP',
                      op: 'AND',
                      ruleType: 'TABLE_RULE',
                      table: 'catalog.schema.table',
                    },
                  ],
                  id: '504a3098-e559-4b26-8868-5f82b1113d7c',
                  nodeType: 'GROUP',
                  op: 'AND',
                  ruleType: 'DEFAULT',
                },
                {
                  children: [
                    {
                      children: [
                        {
                          id: '26eba531-4a50-4563-9bd1-6148572be8f2',
                          nodeType: 'LEAF',
                          ruleContent: 'formulaDTO',
                        },
                      ],
                      id: '10799a90-b4bd-47ac-9a7b-04161cd2194b',
                      nodeType: 'GROUP',
                      op: 'OR',
                      ruleType: 'CONDITION_RULE',
                    },
                  ],
                  id: '9708efd4-39c5-4016-a52d-e74d0cc99a59',
                  nodeType: 'GROUP',
                  op: 'AND',
                  ruleType: 'DEFAULT',
                },
              ],
              id: '365fed88-84f2-41fc-a681-2cae176ee44e',
              nodeType: 'GROUP',
              op: 'AND',
              ruleType: 'DEFAULT',
            },
            layout: 'test',
          },
        ],
      }),
    );
  }),
  graphql.mutation('deleteRowAuthRule', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteRowAuthRule: true,
      }),
    );
  }),
  graphql.query('queryUserAuthorityByCondition', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserAuthorityByCondition: [
          {
            resourceId: '1',
            resourceType: 'METRIC',
            resourceName: 'test',
            resourceSource: {
              name: 'test',
              id: '1',
            },
            grantTime: 1736838112491,
            authorityRole: 'ADMIN',
            authorityAccount: {
              accountType: 'USER',
              account: '1',
              id: '1',
              displayName: 'test',
            },
            authorityAccountSource: {
              groupId: '1',
              name: 'test',
            },
            authority: {
              canCreate: true,
              canEdit: true,
              canDelete: true,
              canUsage: true,
              canAuth: true,
              authRoleList: ['ADMIN', 'USAGER'],
            },
            authoritySource: 'DIRECT',
          },
        ],
      }),
    );
  }),
];
