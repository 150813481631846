import {
  getFeVersion,
  tenantInfo,
  thirdEnvInfo,
  language,
  sessionOutRedirectUri,
} from '@/common/globalInfo/appConfig';
import t from '@/locales';
import { IErrorResponse, IRestfulResponse } from '@/typings/index';
import { Modal, notification } from '@aloudata/aloudata-design';
import axios, { AxiosRequestHeaders, AxiosResponse, Method } from 'axios';
import { forIn, size } from 'lodash';
import qs from 'query-string';
import { removeUndefinedDeeplyForObject } from '.';
import TraceInfo from '@/common/ui/TraceInfo';
import { EQueryStatus } from '@/services/queryColumns/types';
import { ELanguage } from '@/typings';

export const acceptLanguageMap = {
  [ELanguage.ZH]: 'zh-CN',
  [ELanguage.EN]: 'en-US',
};

const tenantId = tenantInfo.tenantId;
const defaultHeaders = {
  'Content-Type': 'application/json',
  'tenant-id': tenantId,
  'fe-version': getFeVersion(),
  'accept-language': acceptLanguageMap[language],
};
const baseUrl = process.env.baseUrl || '';
const queryObj: {
  [key: string]: number;
} = {};

function ajax<T>(url: string, opt: IRequestOpt): Promise<T> {
  return new Promise((resolve, reject) => {
    const { headers, silent = false, signal } = opt;
    // 处理文件上传的header
    let newHeader: AxiosRequestHeaders;
    if (headers) {
      newHeader = {
        ...headers,
        'tenant-id': tenantInfo.tenantId,
        'fe-version': getFeVersion(),
      };
    } else {
      newHeader = { ...defaultHeaders };
    }
    if (thirdEnvInfo.token) {
      newHeader.token = thirdEnvInfo.token;
    }
    axios({
      url: `${baseUrl}${url}`,
      headers: newHeader,
      method: opt.method,
      data: removeUndefinedDeeplyForObject(opt.data || {}),
      // data: opt.data || {},
      // 用于取消请求的标记
      signal,
    }).then(
      (res: AxiosResponse<IRestfulResponse<T>>) => {
        const { data: resBody } = res;
        const { success, data } = resBody;
        if (isSessionOut(resBody)) {
          return;
        }
        if (!success) {
          if (!silent) {
            // 静默错误模式下，不显示错误信息
            showErrorNotification(resBody);
            console.error(res);
          }
          reject(resBody);
          return;
        }
        resolve(data);
      },
      (err: IErrorResponse) => {
        if (axios.isCancel(err)) {
          reject(err);
          return;
        }
        if (!silent) {
          notification.error({
            message: 'request error',
          });
        }
        reject(err);
      },
    );
  });
}
// 是否弹出登录超时的弹框，记个全局标志是为了防止弹窗重复弹出
let isTimeoutModalShowed = false;
export function isSessionOut(res: IErrorResponse): boolean {
  const { errorCode } = res;
  // const SESSION_TIME_OUT = 'CIP01001'; // 登录超时的错误码
  const SESSION_TIME_OUT = 'AM_01_0016'; // 登录超时的错误码
  // 处理登录超时
  if (errorCode === SESSION_TIME_OUT) {
    if (!isTimeoutModalShowed) {
      isTimeoutModalShowed = true;
      // 弹出登录超时的弹框
      Modal.info({
        title: t.login.login.sessionOutTitle,
        content: (
          <div>
            <p>{t.login.login.sessionOutDesc}</p>
          </div>
        ),
        okText: t.login.login.reLogin,
        onOk() {
          const { origin, href } = window.location;
          const fullLoginPage =
            sessionOutRedirectUri ||
            `${origin}/login?refer=${encodeURIComponent(href)}`;
          window.location.href = fullLoginPage;
        },
      });
    }
    return true;
  }
  return false;
}
export function showErrorNotification(res: IErrorResponse) {
  const { errorMsg, detailErrorMsg } = res;
  notification.error({
    message: t.common.request.message,
    description: (
      <div>
        <div>{errorMsg || 'system error'}</div>
        <TraceInfo
          resultData={{
            status: EQueryStatus.FAILED,
            detailErrorMsg,
          }}
        />
      </div>
    ),
    style: {
      maxHeight: 500,
      overflow: 'auto',
    },
  });
}
export function post<T>(
  url: string,
  data: TReqData = {},
  opt: Partial<IRequestOpt> = {},
): Promise<T> {
  return ajax(url, {
    ...opt,
    data,
    method: 'POST',
  });
}
export function get<T>(
  url: string,
  data: TReqData = {},
  opt: Partial<IRequestOpt> = {},
): Promise<T> {
  let finalUrl = url;
  if (url.includes('?')) {
    throw new Error('parameters should be in data');
  }
  const { type, queryKey } = opt;
  // 对于所有的代码添加workspaceId
  const newData = data || {};
  if (newData && size(newData)) {
    const reqData = parseReqData(newData);
    finalUrl = `${url}?${qs.stringify(reqData)}`;
  }
  if (!type) {
    return ajax(finalUrl, { ...opt, method: 'GET' });
  }
  if (!queryKey) {
    throw new Error(`queryKey is missing: ${url}`);
  }
  const currentQueryId = new Date().getTime();
  queryObj[queryKey] = currentQueryId;
  return new Promise((resolve, reject) => {
    ajax(finalUrl, { ...opt, method: 'GET' })
      .then((resultData) => {
        if (currentQueryId !== queryObj[queryKey]) {
          return;
        }
        resolve(resultData as T);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
type TReqData = object;
export interface IRequestOpt {
  method: Method;
  data?: object;
  headers?: {
    [key: string]: string;
  };
  type?: TRequestTypes;
  queryKey?: string;
  silent?: boolean; // 接受到错误信息后不展示
  signal?: AbortSignal; // 取消请求时用到的标记
}
type TRequestTypes = 'latest';

/**
 * 为符合前后端请求结构需要，将请求数据中驼峰写法的key转成下划线分隔的key
 * examples:
 * { datasetGuid: 'xxxx' } -> { 'dataset_guid': 'xxxx' }
 */
function parseReqData(reqData: object): object {
  const res: {
    [key: string]: unknown;
  } = {};
  const reqDataWithoutUndefined = removeUndefinedDeeplyForObject(reqData);
  forIn(reqDataWithoutUndefined, (val, key) => {
    const newKey = key.replace(/[A-Z]/g, (char) => {
      return `_${char.toLocaleLowerCase()}`;
    });
    res[newKey] = val;
  });
  return res;
}
