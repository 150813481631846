export const center = {
  myTask: '我的任务',
  myApply: '我的申请',
  waitMeApproval: '待我审批',
  myApproval: '我已处理',
  applying: '申请中',
  finished: '已完结',
  launch: '发起的',
  permission: '权限申请',
  operate: '操作申请',
  launchApply: '发起申请',
  hasPassed: '已通过',
  hasRejected: '已拒绝',
  hasRevoked: '已撤销',
  detail: '详情',
  impact: '查看下游影响',
  pass: '通过',
  reject: '拒绝',
  withdraw: '撤销',
};

export const resource = {
  operate: {
    update: '发布审批中',
    delete: '删除审批中',
    grant: '授权审批中',
    offline: '下线审批中',
    online: '上线审批中',
  },
  applyAuth: {
    title: '申请权限',
    resource: '资源',
    authType: '权限类型',
    applyReason: '申请理由',
    applyReasonPlaceholder: '请输入申请理由',
    cancel: '取消',
    confirm: '提交申请',
  },
};

export const detail = {
  viewApproval: '查看审批进度',
  approvalDetail: '审批详情',
  resource: '资源',
  permissionType: '权限内容',
  approver: '审批人',
  applyReason: '申请理由',
  launchApply: '发起申请',
  hasPassed: '已通过',
  hasRejected: '已拒绝',
  inApproval: '审批中',
  hasWithdraw: '已撤销',
  end: '已完结',
  onePass: '1人通过即可',
  allPass: '需全员通过',
  withdraw: '撤销申请',
  inputRejectReason: '请输入拒绝理由',
  confirmWithdraw: '确认撤销申请吗',
  withdrawSuccess: '撤销成功',
  handleSuccess: '操作成功',
  rejectApply: '拒绝申请',
  // 等 x 人
  manyPeople: (num: number) => `等${num}人`,
  operateContent: '操作内容',
  lengthPeople: (num: number) => ` (${num}人)`,
};
