export const center = {
  myTask: 'My Approval',
  myApply: 'My Request',
  waitMeApproval: 'Approval',
  myApproval: 'Approved',
  applying: 'In Progress',
  finished: 'Completed',
  launch: 'Initiated',
  permission: 'Permission Request',
  operate: 'Operation Request',
  launchApply: 'Request',
  hasPassed: 'Approved',
  hasRejected: 'Rejected',
  hasRevoked: 'Revoked',
  detail: 'Details',
  pass: 'Approve',
  reject: 'Reject',
  impact: 'View Downstream Impact',
  withdraw: 'Revoke',
};

export const resource = {
  operate: {
    update: 'Publishing Approval',
    delete: 'Deletion Approval',
    grant: 'Authorization Approval',
    offline: 'Offline Approval',
    online: 'Online Approval',
  },
  applyAuth: {
    title: 'Request Permission',
    resource: 'Resource',
    authType: 'Permission Type',
    applyReason: 'Application Reason',
    applyReasonPlaceholder: 'Enter application reason',
    cancel: 'Cancel',
    confirm: 'Request',
  },
};

export const detail = {
  viewApproval: 'Check the progress ',
  approvalDetail: 'Approval Details',
  resource: 'Resource',
  permissionType: 'Permission',
  approver: 'Approver',
  applyReason: 'Reason',
  launchApply: 'Request',
  hasPassed: 'Approved',
  hasRejected: 'Rejected',
  inApproval: 'In Review',
  hasWithdraw: 'Revoked',
  end: 'Completed',
  onePass: 'One Approval Required',
  allPass: 'All Approvals Required',
  withdraw: 'Revoke Application',
  inputRejectReason: 'Enter rejection reason',
  confirmWithdraw: 'Confirm to revoke application?',
  withdrawSuccess: 'Revoked successfully',
  handleSuccess: 'Operation successful',
  rejectApply: 'Reject Application',
  manyPeople: (num: number) => `and ${num} others`,
  operateContent: 'Actions',
  lengthPeople: (num: number) => ` (${num} people)`,
};
