export const header = {
  title: '指标目录',
};

export const sidebar = {
  title: '筛选',
  clear: '清空',
  category: '指标类目',
  metricType: '指标类型',
  owner: {
    default: '数据负责人',
    placeholder: '添加负责人',
  },
  createTime: '创建时间',
  modifyTime: '修改时间',
  metricLevel: '指标等级',
  businessOwner: '业务负责人',
  inputPlaceholder: '请输入',
  userPlaceholder: '请选择',
};

export const mainContent = {
  count: ({ count }: { count: string | number }) => `${count}个指标`,
  empty: {
    desc: '没有符合条件的指标，请重新尝试',
  },
};

export const filtersConfig = {
  title: '指标目录筛选设置',
  searchPlaceholder: '搜索',
  opened: '已启用',
  closed: '未启用',
  reset: '还原为默认',
  apply: '应用',
  applySuccess: '保存成功',
};
