import { Decoration, DecorationSet, EditorView } from '@codemirror/view';
import {
  Extension,
  ViewPlugin,
  ViewUpdate,
  WidgetType,
} from '@uiw/react-codemirror';
import { IRender, TExternalInfo } from './type';
import { getTokenListWithIdPath } from './helper/formulaHelper';
import { getColumnHighlight } from './helper';
import { getDoc } from './helper/codemirrorHelper';
import _ from 'lodash';
// 创建装饰器来高亮字段
export const createFieldDecorator = (
  externalInfo: TExternalInfo,
  onDeleteField: (deleteFieldIdPaths: string[][]) => void,
): Extension => {
  return ViewPlugin.fromClass(
    class {
      decorations: DecorationSet;

      highlightRenders: IRender[];

      constructor(view: EditorView) {
        this.highlightRenders = [];
        this.decorations = this.createDecorations(view);
      }

      createDecorations(view: EditorView): DecorationSet {
        const marks = [];

        const highlightRenders = getColumnHighlight(
          getTokenListWithIdPath(getDoc(view), externalInfo),
        ).codemirrorRender;

        const highlightConfigs = highlightRenders.sort(
          (a, b) => a.offset - b.offset,
        );
        this.highlightRenders = highlightConfigs;
        for (const highlightConfig of highlightConfigs) {
          marks.push(
            Decoration.replace({
              widget: new ColoredSpanWidget(highlightConfig),
            }).range(highlightConfig.offset, highlightConfig.end),
          );
        }
        return Decoration.set(marks);
      }

      update(update: ViewUpdate) {
        if (update.docChanged || update.viewportChanged) {
          // 查找被删除的装饰器
          update.changes.iterChanges((fromA, toA) => {
            if (fromA < toA) {
              const deleteItems = this.highlightRenders.filter(
                (item) => item.offset >= fromA && item.end <= toA,
              );

              const restItems = this.highlightRenders.filter(
                (item) =>
                  item.offset < fromA || item.end > toA || item.offset > toA,
              );

              console.log(
                '%c [ deleteItems ]-64',
                'font-size:13px; background:#f29100; color:#ffffff;',
                deleteItems,
                restItems,
              );

              if (deleteItems.length > 0) {
                onDeleteField(
                  deleteItems
                    .filter(
                      (item) =>
                        !restItems.some((restItem) =>
                          _.isEqual(restItem.idPath, item.idPath),
                        ),
                    )
                    .map((item) => item.idPath),
                );
              }
            }
          });

          this.decorations = this.createDecorations(update.view);
        }
      }

      get atomicRanges(): DecorationSet {
        return this.decorations;
      }
    },
    {
      decorations: (v) => v.decorations,
      provide: (plugin) => {
        return EditorView.atomicRanges.of(
          (view) => view.plugin(plugin)?.atomicRanges || Decoration.none,
        );
      },
    },
  );
};

// 使用自定义装饰器，可以绑定dom事件
class ColoredSpanWidget extends WidgetType {
  constructor(private renderInfo: IRender) {
    super();
  }

  toDOM(view: EditorView): HTMLElement {
    const span = document.createElement('span');
    span.style.color = this.renderInfo.color;
    span.textContent = view.state.sliceDoc(
      this.renderInfo.offset,
      this.renderInfo.end,
    );
    return span;
  }

  ignoreEvent(): boolean {
    return false;
  }
}
