import { graphql } from 'msw';
import { getMonitorConfigData, getMonitorResult } from './helper';
import { getUniqId } from '../../src/common/utils';
import {} from '../../src/services/monitor';
import { EMonitorPushChannelType } from '../../src/services/monitor/types';
import { EPushMessageType } from '../../src/typings/softIntegration';

export const monitor = [
  graphql.query('queryMonitorResultPage', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMonitorResultPage: {
          data: Array.from({ length: 10 }, (_, i) => getMonitorResult()),
          pageSize: 10,
          pageNumber: 1,
          total: 100,
          haxNext: false,
        },
      }),
    );
  }),
  graphql.query('queryMonitorRecords', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMonitorRecords: Array.from({ length: 20 }, (_, i) =>
          getMonitorResult(),
        ),
      }),
    );
  }),
  graphql.query('queryMonitorConfigsByMetricCode', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMonitorConfigsByMetricCode: Array.from({ length: 10 }, (_, i) =>
          getMonitorResult(),
        ),
      }),
    );
  }),
  graphql.mutation('deleteMonitorConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteMonitorConfig: true,
      }),
    );
  }),

  graphql.query('queryMonitorConfig', (req, res, ctx) => {
    const id = getUniqId();
    return res(
      ctx.data({
        queryMonitorConfig: getMonitorConfigData(id, `name-${id}`),
      }),
    );
  }),

  graphql.mutation('createMonitorConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        createMonitorConfig: true,
      }),
    );
  }),

  graphql.mutation('updateMonitorConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        updateMonitorConfig: true,
      }),
    );
  }),

  graphql.mutation('testMonitorConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        testMonitorConfig: true,
      }),
    );
  }),

  graphql.query('queryPushChannels', (req, res, ctx) => {
    return res(
      ctx.data({
        queryPushChannels: [
          {
            type: EMonitorPushChannelType.EMAIL,
            config: {
              channelId: 'email',
              channelName: '邮件',
              channelType: 'EMAIL',
            },
          },
          {
            type: EMonitorPushChannelType.WEBHOOK,
            config: {
              channelId: 'enterpriseWechat',
              channelName: '企业微信',
              channelType: EPushMessageType.ENTERPRISE_WECHAT,
            },
          },
          {
            type: EMonitorPushChannelType.WEBHOOK,
            config: {
              channelId: 'pushMessage',
              channelName: '一个平平无奇的消息推送',
              channelType: EPushMessageType.CUSTOM,
            },
          },
          {
            type: EMonitorPushChannelType.WEBHOOK,
            config: {
              channelId: 'feishu',
              channelName: '飞书机器人推送',
              channelType: EPushMessageType.ENTERPRISE_FEISHU,
            },
          },
        ],
      }),
    );
  }),
  graphql.query('queryMonitorLevelDef', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMonitorLevelDef: {
          name: 'monitorLevel',
          displayName: '告警等级',
          dataInput: [
            {
              isDefault: true,
              name: 'l',
              displayName: '低',
            },
            {
              isDefault: false,
              name: 'm',
              displayName: '中',
            },
            {
              isDefault: false,
              name: 'h',
              displayName: '高',
            },
          ],
        },
      }),
    );
  }),
  graphql.mutation('modifyMonitorStatus', (req, res, ctx) => {
    return res(
      ctx.data({
        modifyMonitorStatus: true,
      }),
    );
  }),
];
